import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import SchoolLogo from '../../../styles/images/image-20.png';
import AppSettingsService from '../../../services/app-settings.service';
import UserSearch from '../../header-search/UserSearch';

import SessionContext from '../../../library/Core/SessionContext';
import { SessionStateType } from '../../../library/Core/SmartTypes';
import { axiosClient } from '../../../library/Service/axiosClient';

function HeaderControl() {
    const { sessionState, sessionDispatch } = useContext(SessionContext);
    let {
        studentId,
        userId,
        userTypeCode,
        instituteLogo,
        genderCode,
        instituteId,
        profilePictureFilePath: profileImage,
    } = sessionState as SessionStateType;
    const StudentTestMenu = JSON.parse(sessionStorage.getItem('product-menu-test') || '{}');
    const [logout, setLogout] = useState(false);
    const [loginUrl, setLoginUrl] = useState('');
    const [unreadMsg, setunreadMsg] = useState(0);

    // console.log(userTypeCode);

    useEffect(() => {
        const unreadMsgInvite = async () => {
            try {
                let UID = '';
                if (userTypeCode == 'INSTITUTE_ADMIN') {
                    UID = userId;
                } else {
                    UID = studentId;
                }

                var unReadCnt: number = 0;
                if (userTypeCode !== 'SUPER_ADMIN') {
                    const response = await axiosClient().get(`${process.env.REACT_APP_MESSAGE_SERVER}/conversations/get-unread-msg/${UID}`);
                    if (response.data.unReadCnt != 0) {
                        unReadCnt = response.data.unReadCnt;
                    }
                }
                // console.log('=====Response 12345678=======');
                // console.log(response.data.unReadCnt);
                setunreadMsg(unReadCnt);
            } catch (error) {
                console.error('Error Getting Message Count:', error);
            }
        };

        unreadMsgInvite();
    }, []);

    async function sayHello() {
        const data = { uuid: studentId, instituteid: instituteId };
        const url = `${process.env.REACT_APP_COMMON_API}/users/get-bas-data`;
        const response = await axiosClient().post(`${url}`, data);
        console.log(response)
        if (response.data.parent_dat.p_info == '1') {
            if (response.data.parent_dat.aes_id != '') {
                alert('Confirm to Book A Session');
                console.log(`${process.env.BAS_PHP_PACEFORM_LINK}`)
                
                const externalDomain = `${process.env.BAS_PHP_PACEFORM_LINK}${response.data.parent_dat.aes_id}`;
                window.open(externalDomain, '_blank');
            } else {
                alert(
                    'Thank you for your Interest,  We are not able to process your request at this moment, Our Support Team Will Contact you shortly.'
                );
            }
        } else {
            alert('Thank you for your Interest, Kindly fill your parent details');
            navigate(`/alumni/${studentId}/profile/parent-info`, { replace: false });
        }
    }

    const navigate = useNavigate();

    if (profileImage == '' || profileImage == null || profileImage == 'null') {
        profileImage =
            genderCode == 'MALE'
                ? `${process.env.REACT_APP_IMAGE_BASEPATH}/yellow-student-boy.svg`
                : `${process.env.REACT_APP_IMAGE_BASEPATH}/yellow-student.svg`;
    }
    const userStatusCode = sessionStorage.getItem('user-profile-status-code');

    useEffect(() => {
        if (logout) {
            //const loginUrl = AppSettingsService.getLoginUrl(sessionState) as string;
            window.location.href = `${process.env.REACT_APP_APP_BASE_URL}/` + loginUrl;
            // navigate(loginUrl);
        }
    }, [logout]);

    const handleLogout = () => {
        localStorage.setItem('univariety-user', JSON.stringify({}));
        localStorage.setItem('user', JSON.stringify({}));
        localStorage.setItem('user-logged-out', 'TRUE');
        localStorage.setItem('user-jwt-token', '');
        localStorage.removeItem('user-type-code');
        localStorage.removeItem('selectedInstitute');
        localStorage.removeItem('user');
        localStorage.removeItem('user-jwt-token');
        sessionStorage.clear();
        // const sessionStateClone = {...sessionState};
        // sessionDispatch({ type: 'SET', payload: {} });
        const redirectUrl = AppSettingsService.getLoginUrl(sessionState) as string;

        setLoginUrl(redirectUrl);
        // sessionDispatch({ type: 'SET', payload: {} });

        //window.location.href = redirectUrl;

        setLogout(true);

        // window.location.href = AppSettingsService.getLoginUrlFromStorage() as string;
        // window.location.href = AppSettingsService.getLoginUrl(sessionState) as string;
    };

    const [isActive, setIsActive] = useState(false);
    const [isHidden, setIsHidden] = useState(false);

    const handleClick = () => {
        setIsActive(!isActive);
        setIsHidden(!isHidden);
    };

    const getProfilePath = (userTypeCode: string, studentId: string, userId: string): string => {
        switch (userTypeCode) {
            case 'STUDENT':
                return `/alumni/${studentId}/profile`;
            case 'ALUMNI':
                return `/alumni/${studentId}/profile`;
            case 'SCHOOL_ADMIN':
            case 'INSTITUTE_ADMIN':
                return `/management/${userId}/profile`;
        }
        return '';
    };

    return (
        <>
            <div className="container max-1140">
                <div className="row justify-content-between align-items-center custom-header">
                    <div className="univarsity_logo manager_profile" data-aos="fade-left">
                        <div className="logo">
                            <button
                                className="mobileonly btn-offcanvas"
                                type="button"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasMobile"
                                aria-controls="offcanvasMobile">
                                <span className="navbar-toggler-icon">
                                    <b>#</b>
                                </span>
                            </button>
                            <img src={instituteLogo} width="160" alt="" className="img-fluid" />
                        </div>
                    </div>
                    {userTypeCode?.toLocaleLowerCase() != 'student' && (
                        <div className={`search ${isActive ? 'open' : ''} ${isHidden ? 'hidden' : ''}`}>
                            <div className="form-inline d-flex flex-wrap justify-content-between">
                                <div className="w-100 font-13 font-500">
                                    <UserSearch />
                                </div>

                                <button className="btn btn-search icon icon-search" type="submit"></button>
                            </div>

                            <a href="#" className="btn-search-toggle icon-search" onClick={handleClick}></a>
                        </div>
                    )}
                    <div className="col-5 headerRightSide aos-init aos-animate" data-aos="fade-right">
                        {userTypeCode === 'STUDENT' &&
                            StudentTestMenu.bas && ( //bas is Book a Session test_name
                                <a
                                    onClick={sayHello}
                                    className="btn btn-green me-3 px-3 px-sm-4"
                                    data-bs-toggle="tooltip"
                                    title="Book a Session">
                                    <span className="icon-book me-0 me-sm-1"></span>
                                    <span className="d-none d-sm-inline">Book a Session</span>
                                </a>
                            )}
                        <ul>
                            {userTypeCode?.toLocaleLowerCase() != 'student' && userTypeCode?.toLocaleLowerCase() != 'super_admin' && (
                                <li>
                                    <a className="invite" href="/alumni-invite/peer">
                                        <i className="icon-user-invert me-2"></i> Invite others to the platform
                                    </a>
                                </li>
                            )}
                            <li>
                                {userStatusCode === 'PREFERENCES_INCOMPLETE' && userTypeCode === 'STUDENT' ? (
                                    <Link to={`/student/${studentId}/student-preference`} className="mail icon-mail">
                                        {unreadMsg != 0 && <span className="count">{unreadMsg}</span>}
                                    </Link>
                                ) : (
                                    userTypeCode?.toLocaleLowerCase() != 'super_admin' && (
                                        <Link to={`/messages`} className="mail icon-mail">
                                            {unreadMsg != 0 && <span className="count">{unreadMsg}</span>}
                                        </Link>
                                    )
                                )}
                            </li>
                            {userTypeCode?.toLocaleLowerCase() === 'alumni' && (
                                <li>
                                    <Link to={`/notification`} className="notification icon-notification"></Link>
                                </li>
                            )}

                            <li>
                                <div className="username d-flex align-items-center position-relative">
                                    <i className="icon-down-arrow-invert position-absolute"></i>
                                    <label className="">
                                        <span
                                            className={
                                                profileImage != 'null'
                                                    ? 'userimg position-relative'
                                                    : 'userimg position-relative icon-user-invert'
                                            }>
                                            <img src={profileImage ? profileImage : ''} />
                                        </span>
                                    </label>
                                    <div className="user-dropdown">
                                        <ul>
                                            <li>
                                                {userStatusCode === 'PREFERENCES_INCOMPLETE' && userTypeCode === 'STUDENT' ? (
                                                    <Link to={`/student/${studentId}/student-preference`}>My Profile</Link>
                                                ) : (
                                                    <a href={getProfilePath(userTypeCode as string, studentId as string, userId as string)}>
                                                        My Profile
                                                    </a>
                                                )}
                                            </li>

                                            <li className="d-flex d-md-none">
                                                {userStatusCode === 'PREFERENCES_INCOMPLETE' && userTypeCode === 'STUDENT' ? (
                                                    <Link to={`/student/${studentId}/student-preference`}>
                                                        {unreadMsg != 0 && <span className="count">{unreadMsg} Messages</span>}
                                                    </Link>
                                                ) : (
                                                    userTypeCode?.toLocaleLowerCase() != 'super_admin' && (
                                                        <Link to={`/messages`}>
                                                            {unreadMsg != 0 && <span className="count">{unreadMsg}</span>}Messages
                                                        </Link>
                                                    )
                                                )}
                                            </li>

                                            <li className="d-flex d-md-none">
                                                <a href="/alumni-invite/peer">Invite</a>
                                            </li>
                                            <li>
                                                <a onClick={handleLogout}>Logout</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* <div className="mobile-select">
                <select className="form-select assessment-select fordesktop" name="" id="">
                    <option value="learning-style-test">Learning Style Test</option>
                    <option value="multiple-intelligence-test">Multiple Intelligence Test</option>
                    <option value="personality-assessment-test">Personality Assessment Test</option>
                    <option value="ideal-career-test">Ideal Career Test</option>
                    <option value="stream-selector-test">Stream Selector Test</option>
                    <option value="engineering-branch-assessment-test">engineering-branch Assessment Test</option>
                    <option value="commerce-branch-assessment-test">Commerce Branch Assessment Test</option>
                    <option value="humanities-branch-assessment-test">Humanities Branch Assessment Test</option>
                </select>
            </div> */}
        </>
    );
}

export default HeaderControl;
