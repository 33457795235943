import React, { useState, useEffect } from 'react';
import  HeaderControl  from '../../alumni/_common/HeaderControl';
import MenuBuilder from '../../../routes/MenuBuilder';
import { axiosClient } from '../../../library/Service/axiosClient';
import PageFooterControl from '../../../library/SimpleControls/PageFooterControl';
// import pageImg from '../../../styles/images/learning-style.svg';

const pageImg = `${process.env.REACT_APP_IMAGE_BASEPATH}/learning-style.svg`;

const StreamSelectorTest = () => {
  const [buttonCondition, setbuttonCondition] = useState('1');
  const [viewreportlink, setviewReportLink] = useState();
  const [continueassesment, setContinueAssesment] = useState();
  const [StudentId, setStudentId] = useState();
    const [studentemail, setStudentEmail] = useState();
    const [studentfirst, setStudentFirst] = useState();
    const [studentlast, setStudentLast] = useState();


    useEffect(() => {
        const userDataString = sessionStorage.getItem('user');
        if (userDataString) {
            const userData = JSON.parse(userDataString);
            console.log(userData.registeredEmailId, 'registeredEmailId');
            console.log(userData.studentId, 'studentId');
            setStudentId(userData.studentId);
            console.log(StudentId, 'setstudentId as studentId');
            setStudentEmail(userData.registeredEmailId);
            setStudentFirst(userData.firstName);
            setStudentLast(userData.lastName);
        }
    }, []); // Only run once on mount


    useEffect(() => {
        const fetchData = async () => {
            console.log('Fetching data')
            const userDataString = sessionStorage.getItem('user');
                if (userDataString) {
                    const userData = JSON.parse(userDataString);
                try {
                    console.log(StudentId, 'studentId')
                    const response = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/test/get-test-details-bystudentid-examid`, {
                        'studentId': userData.studentId,
                        'examId': 115
                    });
                    const data = await response.data;
                    if (data.success) {
                        const res = data.data;
                        if (res.length > 0) {
                            const case1 = (res[0].test_status === 'Completed' && res[0].end_date !== undefined) ? '3' : '2';
                            const report_link=(res[0].test_status === 'Completed' && res[0].end_date !== undefined) ? res[0].report_html_path : '';
                            const test_link_url=(res[0].test_status === null && res[0].test_link_url !== null) ? res[0].test_link_url : '';
                            setbuttonCondition(case1);
                            setviewReportLink(report_link);
                            setContinueAssesment(test_link_url);
                        }
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                    alert(error);
                }
            }
        };
            fetchData();
            // Set up an interval to fetch data every 5 minutes (adjust as needed)
            const intervalId = setInterval(() => {
                fetchData();
            }, 1 * 60 * 1000); // 1 minutes
            // Clean up the interval when the component is unmounted
            return () => clearInterval(intervalId);
    }, []);

  const takeTest = async () => {
          try {
              const user_name=studentfirst+" "+studentlast
              const apiUrl = `${process.env.REACT_APP_COMMON_API}/test/usertestinsert`;
              const requestData = {
                  "admin" : {
                      // this will be static if There is only one who wants the webhook
                      "admin_id" : 5,
                      "organization_id" : 3,
                      "superadmin_id": 2,
                      "associate_id": 2
                  },                  
                  "uniquefactor" : {
                    "validation" : true, 
                    "field": "emailid", 
                    "value": studentemail
                  },
                  "exam" : {
                      "exam_id" : 115, //  115 for Stream Selector
                      "set_id" : 92 //  92 for Stream Selector
                  },
                  "oem": {
                      "header" : {
                          "logo" : "", // full https image path  & Univariety Image as Default
                          "name" : "", // Brand Name if any   & Nothing as Default 
                          "color" : { // basic Univariety Theme as Default
                              "primary" : "",
                              "background" : "",
                              "cta" : "",
                              "cta_text_color" : "",
                              "cta_text" : ""
                          }
                      },
                      "footer" : {
                          "copyright_text" : "", // "International Educational Gateway Pvt. Ltd. © 2023. All Rights Reserved. Univariety" as Default
                          "test_name" : "" // "Discover Ideal Career Test"
                          
                      },
                      "links": {
                          "backtodashboard" : "" // Platform Link which you want user to go.
                      }
                  },
                  "user": {
                      // basic user details
                      "username" : user_name, // User name which will reflect in Report
                      "emailid" : studentemail, // User email Addres which will reflect in report
                      "contact_no" : "" // User contact which will reflect in report
                  },
                  // not in use untill you want us to store your logs and unique user identity
                  "client_id" : "INT -> <UINVARIETY USER UNIQUE IDENTITY>",
                  "client_log" : "{} -> <UINVARIETY USER UNIQUE LOG FOR REFERENCE in OBJECT>"
              };
              const response = await axiosClient().post(apiUrl, requestData);
              if (response.data.success) {
                  setbuttonCondition('2');
                  setContinueAssesment(response.data.testurl);
                  window.open(response.data.testurl, '_blank');
              } else {
                  console.error('API request failed:', response.data.message);
              }
          } catch (error) {
              console.error('Error calling API:', error);
          }
    }
     
    return (       
        <div className="d-flex flex-column min-vh-100">
                <header>
                    <HeaderControl />
                    <MenuBuilder />
                </header>
           
            {/* https://stackoverflow.com/questions/56660153/how-to-listen-to-localstorage-value-changes-in-react */}
            <div className='main flex-1 over-hidden'>
                <div className='container max-1140 px-lg-0'>
                    <div className='row'>
                        <div className='col-md-12 mb-4'>
                        <div className="white-block white-block-notopborderradius h-100 p-0">
                  
                  <div className="page_ttl_row border-bottom border-2 custom-border-gray p-4 px-md-5">
                   <div className="row g-3 align-items-center ">
                      <div className="col">
                        <h3 className="font-35 font-600 mb-0 block-title aos-init aos-animate" data-aos="fade-left" data-aos-delay="300">Stream Selection</h3>
                      </div>
                      <div className="col-auto">
                        {buttonCondition === '1' && (
                            <div className="dashboard innerpage smart-scroll scrolled-down">
                                <a href="javascript:;"  className="btn-takeTest" data-aos="fade-right" data-aos-delay="300" onClick={takeTest}><span>Take a Test</span></a>
                            </div>
                        )}

                        {buttonCondition === '2' && (
                            <div className="dashboard innerpage smart-scroll scrolled-down">
                                <a href={continueassesment} target='_blank' className="btn-darkblue" data-aos="fade-right" data-aos-delay="300"><span>Continue Assessment</span></a>
                            </div>
                        )}

                        {buttonCondition === '3' && (
                            <div className="dashboard innerpage smart-scroll scrolled-down">
                                <a href={viewreportlink} target="_blank" className="btn-green" data-aos="fade-right" data-aos-delay="300"><span>View Report</span></a>
                            </div>
                        )}
                      </div>
                   </div>
                </div>
            
                  <div className="p-4 p-md-5">
                    <div className="row country-info">
                      <div className="col-md-12">
                          <div className="country-deatils border-bottom mb-5 pb-4">
                              <img src={pageImg} className="img-fluid float-lg-end ms-lg-5 mx-auto mb-3" width="396"/>
                              <h1 className="font-28 font-900 text-dark-blue  mb-3">About Assessment</h1>
                              <h3 className="font-22 font-400 mb-4">The Stream Selector Test is a psychometric assessment test that considers - interests, aptitude, and skills of the students and accordingly suggests the right streams for them to pursue after class 10. The scientifically designed test analyses the various personal and professional traits of a student by asking common questions and provides a detailed report on their career prospects after class 10. The choice of the stream becomes very easy and therefore helps in picking up the right subjects/streams in class 11.<p></p>
                          </h3></div>
  
                          <div className="tuition-fees border-bottom mb-5 pb-4" id="Universities">
                              <h2 className="font-18 font-600 position-relative title pb-2 mt-4 mb-4">Assessment Output</h2>
                              <p className="font-16">It helps to choose the right stream after class 10.</p>
                              <p className="font-16">A detailed report that elaborates on the interests, aptitude, skills of the student and suggests streams/subjects accordingly.</p>
                              <p className="font-16">Gives an overview and benefits of various streams - Commerce, Humanities, Science.</p>
                          </div>
  
                          <div className="tuition-fees pb-4" id="visa">
                              <h2 className="font-18 font-600 position-relative title pb-2 mb-4">Frequently Asked Questions</h2>
  
                              <div className="basic-requirements">
                              <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                      What is the nature of the Stream Selector Test? 
                                      </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample" >
                                      <div className="accordion-body">
                                          It is a Psychometric test consisting of Multiple Choice Questions
                                      </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                      Is it an academic test that requires knowledge on certain subjects?
                                      </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample" >
                                      <div className="accordion-body">
                                          It is not an academic test. The test comprises of situations in the form of questions where the student has to choose the best option based on own choice and decision out of three. So, don't worry about any special preparation. 
                                      </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingThree">
                                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                      How does the scoring happen for the test? 
                                      </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample" >
                                      <div className="accordion-body">
                                          The tests consist of general questions from everyday activities. So, there are no right or wrong answers. The best answer is the one that the student can relate to.
                                      </div>
                                    </div>
                                </div>
                              </div>
                              </div>
                          </div>
  
                          <div className="visa mb-0 pb-0" id="visa">
                            <div className="row g-2">
                                <div className="col-auto">
                                {buttonCondition === '1' && (
                                      <div className="dashboard innerpage smart-scroll scrolled-down">
                                          <a href="javascript:;" className="btn-takeTest" data-aos="fade-right" data-aos-delay="300" onClick={takeTest}><span>Take a Test</span></a>
                                      </div>
                                  )}

                                  {buttonCondition === '2' && (
                                      <div className="dashboard innerpage smart-scroll scrolled-down">
                                          <a href={continueassesment} target='_blank' className="btn-darkblue" data-aos="fade-right" data-aos-delay="300"><span>Continue Assessment</span></a>
                                      </div>
                                  )}

                                  {buttonCondition === '3' && (
                                      <div className="dashboard innerpage smart-scroll scrolled-down">
                                          <a href={viewreportlink} target="_blank" className="btn-green" data-aos="fade-right" data-aos-delay="300"><span>View Report</span></a>
                                      </div>
                                  )}
                                </div>
                            </div>
                          </div>
                      </div>
                  </div>                           
                </div>
                  </div>
                        </div>
                    </div>
                </div>
            </div>
            <PageFooterControl/>
        </div>  
    );
};

export default StreamSelectorTest;
