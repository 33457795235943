import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { SmartContext } from '../../../../library/Core/SmartContext';
import SessionContext from '../../../../library/Core/SessionContext';
import { getUniversityImage } from '../../../../common/styles/Images';
import { isSchoolAdmin } from '../../../../library/Core/SmartFunctions';

const ProfilePreferences = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { sessionState } = useContext(SessionContext);

    const model = state.internal.model;
    const navigate = useNavigate();

    const isPeerReviewed = sessionState?.isPeerReviewed ?? false;
    const userType = sessionStorage.getItem('user-type-code');
    const isPersonate = sessionStorage.getItem('is-impersonated-session') == 'YES' ? true : false;
    if (isPersonate != true && userType == 'ALUMNI' && isPeerReviewed != true) {
        window.location.href = `/alumni/welcome`;
        return;
    }

    return (
        <div className="skills_detail_wrap">
            {state?.data?.profile?.userTypeCode != 'STUDENT' && (
                <div className="preferences-block">
                    <div className="preferences-block-title">
                        <div className="row g-3 align-items-center">
                            <div className="col">
                                <h2 className="m-0 font-22 font-600">Higher Studies Preferences</h2>
                            </div>
                            <div className="col-auto">
                                <a
                                    role="button"
                                    onClick={() => navigate(`/alumni/${model.id}/pg-education-preference`)}
                                    className="btn-add-section">
                                    <b className="icon-edit me-2"></b>
                                    <span>Edit</span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="preferences-block-detail">
                        <div className="custom_row">
                            <div className="custom_row_title">
                                Preferred <span>Program</span> Type?
                            </div>
                            <div className="custom_row_detail row g-3">
                                <div className="col-sm-12">
                                    <div className="custom_row_block">
                                        <div className="icon icon-hat"></div>
                                        <div className="d-flex flex-column justify-content-center">
                                            <div className="program">
                                                <span>{model.preferredProgramType}</span>
                                            </div>
                                            <div className="program-time-interest">
                                                {model.preferredModeOfProgramCode} | Interested to start from{' '}
                                                {model.preferredProgramStartOn}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {model.locationPreferences.length > 0 && (
                            <div className="custom_row">
                                <div className="custom_row_title">
                                    Preferred <span>Location(s)</span> of Study
                                </div>
                                <div className="custom_row_detail row g-2">
                                    {model.locationPreferences.map((location: any) => (
                                        <div key={location.countryName} className="col-auto">
                                            <div className="radius_block">
                                                <span>{location.countryName}</span>
                                                <i className={`icon-like ${location.preferenceColorCode}`}></i>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        {model.fieldOfStudyPreferences.length > 0 && (
                            <div className="custom_row">
                                <div className="custom_row_title">
                                    Preferred <span>field(s)</span> of Study
                                </div>
                                <div className="custom_row_detail row g-2">
                                    {model.fieldOfStudyPreferences.map((field: any) => (
                                        <div key={field.fieldOfStudyName} className="col-auto">
                                            <div className="radius_block">
                                                <span>{field.fieldOfStudyName}</span>
                                                <i className={`icon-like ${field.preferenceColorCode}`}></i>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        {model.universityPreferences.length > 0 && (
                            <div className="custom_row">
                                <div className="custom_row_title">
                                    Preferred set of <span>Universities</span>
                                </div>
                                <div className="custom_row_detail row g-2">
                                    {model.universityPreferences.map((university: any) => (
                                        <div key={university.universityName} className="col-auto">
                                            <div className="universities_block">
                                                <i className="logo">
                                                    <img src={getUniversityImage(university.logoFileName)} className="" width="75%" />
                                                </i>
                                                <span>{university.universityName}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
            <div className={`preferences-block ${state?.data?.profile?.userTypeCode != 'STUDENT' ? '' : 'student-pref position-relative'}`}>
                {state?.data?.profile?.userTypeCode != 'STUDENT' && (
                    <div className="preferences-block-title">
                        <div className="row g-3 align-items-center">
                            <div className="col">
                                <h2 className="m-0 font-22 font-600">Work Preferences</h2>
                            </div>
                            {model.isEditable && !isSchoolAdmin() && (
                                <div className="col-auto">
                                    <a
                                        role="button"
                                        onClick={() => navigate(`/alumni/${model.id}/job-preferences`)}
                                        className="btn-add-section">
                                        <b className="icon-edit me-2"></b> <span>Edit</span>{' '}
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {state?.data?.profile?.userTypeCode === 'STUDENT' && model.isEditable && !isSchoolAdmin() && (
                    <div className="preferences-block-title">
                        <div className="row g-3 align-items-center">
                            <div className="col">
                                <h2 className="m-0 font-22 font-600">Work Preferences</h2>
                            </div>
                            {model.isEditable && (
                                <div className="col-auto">
                                    <a
                                        role="button"
                                        onClick={() => navigate(`/alumni/${model.id}/job-preferences`)}
                                        className="btn-add-section">
                                        <b className="icon-edit me-2"></b>
                                        <span>Edit</span>
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>
                )}

                <div className="preferences-block-detail">
                    {model.jobIndustryPreferences.length > 0 && (
                        <div className="custom_row">
                            <div className="custom_row_title">
                                Preferred <span>Industry</span>
                            </div>
                            <div className="custom_row_detail row g-2">
                                {model.jobIndustryPreferences.map((industry: any) => (
                                    <div key={industry.industryCode} className="col-auto">
                                        <div className="radius_block">
                                            <span>{industry.industryName}</span>
                                            <i className={`icon-like`}></i>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {model.preferredFunctionalArea && (
                        <div className="custom_row">
                            <div className="custom_row_title">
                                Preferred <span>Functional Area</span>
                            </div>
                            <div className="custom_row_detail row g-2">
                                <div className="col-auto">
                                    <div className="radius_block">
                                        <span>{model.preferredFunctionalArea}</span>
                                        <i className="icon-like green"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {model.jobLocationPreferences.length > 0 && (
                        <div className="custom_row">
                            <div className="custom_row_title">
                                Preferred <span>Job Location(s)</span>
                            </div>
                            <div className="custom_row_detail row g-2">
                                {model.jobLocationPreferences.map(
                                    (location: any) =>
                                        location.cityName != '' && (
                                            <div key={location.cityCode} className="col-auto">
                                                <div className="radius_block">
                                                    <span>{location.cityName}</span>
                                                    <i className={`icon-like ${location.preferenceColorCode}`}></i>
                                                </div>
                                            </div>
                                        )
                                )}
                            </div>
                        </div>
                    )}
                    {model.jobCompanyPreferences.length > 0 && (
                        <div className="custom_row">
                            <div className="custom_row_title">
                                <span>Companies</span> I wish to work for?
                            </div>
                            <div className="custom_row_detail row g-3">
                                <div className="col-sm-12">
                                    <div className="custom_row_block">
                                        <div className="icon icon-hat"></div>
                                        <div className="d-flex flex-column justify-content-center">
                                            <div className="program">
                                                {model.jobCompanyPreferences.map((company: any) => company?.companyName).join('  |  ')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {model.jobIndustryPreferences.length <= 0 &&
                        model.preferredFunctionalArea.length <= 0 &&
                        model.jobLocationPreferences.length <= 0 &&
                        model.jobCompanyPreferences.length <= 0 && <div>No Job Preferences found..!</div>}
                </div>
            </div>
        </div>
    );
};

export default ProfilePreferences;
