import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// import sideImage4 from '../../../styles/images/access_resources_and_research_img.png';
// import sideImage1 from '../../../styles/images/group-25209.svg';
// import sideImage2 from '../../../styles/images/group-25309.svg';
// import sideImage3 from '../../../styles/images/group-705.svg';
import VerifyContactDetailsControl from './VerifyContactDetailsControl';
import VerifyMobileControl from './VerifyMobileControl';
import { getDashboardProgress } from './getDashboardProgress';
import SessionContext from '../../../library/Core/SessionContext';

const sideImage1 = `${process.env.REACT_APP_IMAGE_BASEPATH}/group-25209.svg`;
const sideImage2 = `${process.env.REACT_APP_IMAGE_BASEPATH}/group-25309.svg`;
const sideImage3 = `${process.env.REACT_APP_IMAGE_BASEPATH}/group-705.svg`;
const sideImage4 = `${process.env.REACT_APP_IMAGE_BASEPATH}/access_resources_and_research_img.png`;

const ActivityControl = (props: any) => {
    const navigate = useNavigate();
    const { sessionState }: any = useContext(SessionContext);
    const id = sessionState?.id;
    const activitiesConfig: any = JSON.parse(sessionStorage.getItem('activity-config') as string);
    const activities = activitiesConfig?.find((activityGroup: any) => activityGroup.id === props.selectedGroup)['activities'];
    const activityGroup = activitiesConfig?.find((activityGroup: any) => activityGroup.id === props.selectedGroup);
    const [showingTextForId, setShowingTextForId] = useState(activities[0]['id']);

    const [showVerifyContactDetails, setShowVerifyContactDetails] = useState(false);
    const [showVerifyMobile, setShowVerifyMobile] = useState(false);

    const handleMyContactDetails = () => {
        setShowVerifyContactDetails(true);
    };

    const handleMyProfilePicture = () => {
        navigate('/alumni/' + id + '/profile-picture');
    };

    const handleIntrestNavigate = () => {
        navigate('/alumni/' + id + '/skills');
    };

    // @ @dt 7th.July,2K23 @by RG;
    // const handleMessageLink = () => {
    //     navigate('/alumni/directory');
    // };

    const handleMentorLink = () => {
        navigate('/alumni/juniors-directory');
    };

    const handleBatchmatesLink = () => {
        navigate('/alumni/batch-mates-directory');
    };

    // TO-DO: School Connect needs to be implemented as per the old system;
    const handleSchoolMessageLink = () => {
        navigate('/alumni/school-directory');
    };

    const handlePeerReview = () => {
        // navigate('/alumni/alumni-review/peer');
        navigate('/alumni-review/peer');
    };

    const handleAlbum = () => {
        // navigate('/album/new');
        navigate('/memories/new');
    };

    const handleJobPost = () => {
        // navigate('/jobs/new');
        navigate('/job-posting/new');
    };

    // @170-start @dt 11th.July,2K23 @by RG;
    const handleEventsNavigate = () => {
        navigate('/events/upcoming');
    };
    const handlePreferencesNavigate = () => {
        navigate('/alumni/' + id + '/benefits');
    };
    // @170-end;

    let sideImage: any;

    const handleReadMore = (event: any, id: any) => {
        event.preventDefault();
        setShowingTextForId(id);
        showingTextForId === id ? setShowingTextForId(null) : setShowingTextForId(id);
    };

    switch (props.selectedGroup) {
        case 'INTRODUCTIONS':
            sideImage = sideImage1;
            break;
        case 'COMMUNICATIONS':
            sideImage = sideImage2;
            break;
        case 'CONTRIBUTING':
            sideImage = sideImage3;
            break;
        case 'CAREER':
            sideImage = sideImage4;
            break;
    }

    // TODO: Handle all the activities from here
    const handleActivityClick = (event: any, id: string) => {
        event.preventDefault();
        switch (id) {
            case 'MY_CONTACT_DETAILS':
                handleMyContactDetails();
                break;
            case 'MY_SELFIE':
                handleMyProfilePicture();
                break;
            case 'MY_INTERESTS':
                handleIntrestNavigate();
                break;
            case 'BE_A_MENTOR':
                // handleMessageLink();
                handleMentorLink();
                break;
            case 'CHECK_FRIENDS_BATCHMATES':
                // handleMessageLink();
                handleBatchmatesLink();
                break;
            case 'CONNECT_WITH_SCHOOL':
                handleSchoolMessageLink();
                break;
            case 'PEER_REVIEW':
                handlePeerReview();
                break;
            case 'MEMORIES':
                handleAlbum();
                break;
            case 'OPPORTUNITY_FOR_OTHERS':
                handleJobPost();
                break;
            case 'PREFERENCES':
                handlePreferencesNavigate(); // @170 @dt 11th.July,2K23 @by RG;
                break;
            case 'EVENTS':
                handleEventsNavigate(); // @170 @dt 11th.July,2K23 @by RG;
                break;
            case 'UPSKILL':
                handleIntrestNavigate();
                break;
            case 'RESUME_UPLOAD':
                handleIntrestNavigate();
                break;
        }
    };

    // TO-DO: Need to shift this code inside the template to avoid extra iteration here;
    var progressFlagArr: { [index: string]: any } = {};
    activities.map((activity: any) => {
        progressFlagArr[activity.id] = getDashboardProgress(
            activity.id,
            props.activityProgressMapper?.[activityGroup.id],
            props.dashboardData
        );
    });

    return (
        <>
            <div className="row justify-content-between">
                <div className="flex-1">
                    <div className="profile_setup p-0">
                        {activities.map((activity: any) => (
                            <div
                                key={activity.id}
                                className="icn_details d-flex flex-wrap justify-content-between aos-init aos-animate mb-3"
                                data-aos="fade-up"
                                data-aos-delay="900">
                                <span className="iconsec d-flex justify-content-center align-items-center me-3 me-sm-3">
                                    {/* <b className="icon-multiple-users"></b> */}
                                    <b className={activity.icon}></b>
                                </span>
                                <div className="flex-1">
                                    <p className="iconsec_title">{activity.name}</p>
                                    {showingTextForId === activity.id && <p className="">{activity.text}</p>}
                                    <a href="test" className={`iconsec_link`} onClick={(event) => handleReadMore(event, activity.id)}>
                                        <span>{showingTextForId === activity.id ? 'Read Less' : 'Read More'}</span>
                                    </a>
                                </div>
                                <a
                                    href=""
                                    // className="btn btn-complete-now activity-btn ms-4"
                                    // className={`btn ${getDashboardProgress(activity.id, props.activityProgressMapper[activityGroup.id], props.dashboardData) == 'completed' ? 'btn-green': 'btn-complete-now'} activity-btn ms-4`}
                                    className={`btn ${
                                        progressFlagArr[activity.id] == 'completed' ? 'btn-green' : 'btn-complete-now'
                                    } activity-btn ms-4`}
                                    role="button"
                                    onClick={(event) => handleActivityClick(event, activity.id)}>
                                    {/* <span>{activity.buttonLabel}</span> */}
                                    <span>
                                        {progressFlagArr[activity.id] == 'completed' ? activity.buttonUpdateLabel : activity.buttonLabel}
                                    </span>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="col-lg-auto col-md-4">
                    <div className="profile_setup_help" data-aos="fade-up" data-aos-delay="900">
                        <p className="font-18 font-600 mb-3">{activityGroup['sidebarHeading']}</p>
                        <img className="mb-3 img-fluid float_horizontal_animate" src={sideImage} alt="Profile Setup"></img>
                        <p className="font-16 font-400 m-0">{activityGroup['sidebarLongComment']}</p>
                    </div>
                </div>
            </div>
            {showVerifyContactDetails && (
                <VerifyContactDetailsControl
                    setShowVerifyContactDetails={setShowVerifyContactDetails}
                    setShowVerifyMobile={setShowVerifyMobile}
                />
            )}
            {showVerifyMobile && (
                <VerifyMobileControl setShowVerifyContactDetails={setShowVerifyContactDetails} setShowVerifyMobile={setShowVerifyMobile} />
            )}
        </>
    );
};

export default ActivityControl;
