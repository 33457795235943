import { useContext } from 'react';
import { GlobalContext } from '../../../../library/Core/GlobalContext';
// import badgeImg from '../../../../styles/images/badge.svg';

const AchievementCarouselControl = () => {
    const { globalState } = useContext(GlobalContext);
    // const data = JSON.parse(sessionStorage.getItem('carousel-data') as string)['achievements'];
    const data = globalState.appSettings.carouselData['achievements'];
    const pages = Array.from(new Set(data.map((item: any) => item['pageNum']) as number[]));

    const getPage = (pageNum: number) => {
        const pageItems = data.filter((item: any) => item['pageNum'] === pageNum);
        return pageItems.map((item: any) => (
            <div key={item['name']} className="card_profiles card_profiles_pink card_achievements">
                <div className="card_profiles_univarsity">
                    <div className="card_profiles_name">
                        <div className="student_detail">
                            <h2>
                                <a href="">{item['name']}</a>
                                <span>{item['alumniOf']}</span>
                            </h2>
                        </div>
                        <a href="">
                            <img src={item['candidateImageUrl']} />{' '}
                            <span>
                                <img src='https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/badge.svg' />
                            </span>
                        </a>
                    </div>
                </div>
                <div className="scholarship_amt_detail">
                    <div className="scholarship_amt">{item['achievement']}</div>
                </div>
            </div>
        ));
    };

    return (
        <div id="carouselLogin" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner mb-3">
                {pages.map((item: number) => (
                    <div key={item} className={`carousel-item ${item === 0 ? ' active ' : ''}`}>
                        {getPage(item)}
                    </div>
                ))}
            </div>
            <div className="carousel-indicators position-static">
                {pages.map((item: number) => (
                    <button
                        key={item}
                        type="button"
                        data-bs-target="#carouselLogin"
                        data-bs-slide-to={`${item}`}
                        className={`dot ${item === 0 ? ' active ' : ''}`}
                        aria-current="true"
                        aria-label={`Slide ${item + 1}`}></button>
                ))}
            </div>
        </div>
    );
};

export default AchievementCarouselControl;
