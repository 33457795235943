import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import { SmartContext } from '../../../library/Core/SmartContext';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, DomainElement, State } from '../../../library/Core/SmartTypes';
import DebugBarControl from '../../../library/SimpleControls/DebugBarControl';
import AlumniSchoolSupportService from './AlumniSchoolSupportService';
import Layout from './Layout';
import { getinstituteList } from '../../../library/Service/domainService';

const AlumniSchoolSupport = () => {
    const { globalState } = useContext(GlobalContext);
    const groupMappings = {
        K12_SCHOOL: 'K12_SCHOOL',
        UG: 'UG',
        PG: 'PG'
    };
    const getGroupMappingArray = (groupMappings:any) => {
        const response = Object.entries(groupMappings).map(([key, value]) => ({
            label: key.replace('_', ' '),
            value: value
        }));
        return response;
    };    
    const groupMappingArray = getGroupMappingArray(groupMappings);
    const [levelList, setLevelList] = useState(getinstituteList('PROGRAM_GROUP_LEVEL', groupMappingArray));

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        internal: {
            "schoolName": '',
            "schoolGroupName": '',
            "programLevel":''
        },
        actions: {
            schoolId: AlumniSchoolSupportService.onSchoolChange,
            schoolGroupId: AlumniSchoolSupportService.onSchoolGroupChange,
            programLevel:AlumniSchoolSupportService.onSprogramLevelChange
        },
    } as any);

    const { formConfig, data, internal, routeInfo } = useLoaderData() as State;

    useEffect(() => {
        const domain = new Map([...globalState.domain]);
        domain.set('SCHOOL_GROUP_CODE', internal.schoolGroupDomain);
        domain.set('PROGRAM_GROUP_LEVEL', levelList);
        dispatch({
            type: 'DATA_INIT',
            payload: { formConfig, data, domain, internal, routeInfo },
        });

        const schoolName = (domain?.get('INSTITUTE_DOMAIN')?.find((element) => element.code == data.searchCriteria.schoolId) as DomainElement)?.value;

        dispatch({
            type: 'SET_INTERNAL_STATE',
            payload: { key:"schoolName", value:schoolName },
        });

        const schoolGroupName = (domain?.get('INSTITUTE_DOMAIN')?.find((element) => element.code == data.searchCriteria.schoolGroupId) as DomainElement)?.value;
        
        dispatch({
            type: 'SET_INTERNAL_STATE',
            payload: { key:"schoolGroupName", value:schoolGroupName },
        });

        const programLevelName = (domain?.get('PROGRAM_GROUP_LEVEL')?.find((element) => element.code == data.searchCriteria.programLevel) as DomainElement)?.value;

        dispatch({
            type: 'SET_INTERNAL_STATE',
            payload: { key:"programLevel", value:programLevelName },
        });
    }, []);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <DebugBarControl />
            {!state.flags.isDataLoading && <Layout />}
        </SmartContext.Provider>
    );
};

export default AlumniSchoolSupport;
