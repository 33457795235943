import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import darkLogo from '../../../styles/images/dark-logo.svg';
import TemplatePreviewModal from './TemplatePreviewModal';

const darkLogo = `${process.env.REACT_APP_IMAGE_BASEPATH}/dark-logo.svg`;

declare global {
    interface Window {
        YT: any; // Add this declaration for the YT property
    }
}

interface Template {
    id: number;
    url: string | null;
    image: string;
    html: string | null;
    title: string;
}

interface TemplateListLayoutProps {
    children?: React.ReactNode;
}

const TemplateListLayout: React.FC<TemplateListLayoutProps> = ({ children }) => {
  const [templates, setTemplates] = useState<Template[]>([]);
  const [showMore, setShowMore] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const id = ""; // Example id value

  useEffect(() => {
    axios.post(`${process.env.REACT_APP_BROADCAST_SERVER}/email-template/template-list`, { id })
      .then(response => {
        if (response.data.success) {
          setTemplates(response.data.templates);
        }
      })
      .catch(error => {
        console.error('There was an error fetching the templates!', error);
      });
  }, [id]);

  const displayedTemplates = showMore ? templates : templates.slice(0, 4);

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  const handleChooseTemplate = (id: number) => {
    console.log(id);
    window.location.href = `/broadcast/addcontent?temp_id=`+id;
    //history.push(`/test/${id}`);
  };

  const handlePreviewTemplate = (url: string | null) => {
    setPreviewUrl(url);
  };

  return (
    <>
      <section className="alumni_directory_details container-fluid">
        <div className="max-1140 pt-3 pt-sm-4 px-lg-0 pb-5 overflow-hidden mx-auto">
          <div className="alumni_statistics">
            <div className="d-flex justify-content-between align-items-center mb-3 pb-1">
              <h3 data-aos="fade-left" data-aos-delay="600" className="font-30 font-600 mb-0 block-title aos-init aos-animate">
                Email Templates
              </h3>
            </div>

            <div className="ActiveListings_present">
       
              <div className="email-template-wrap my-4 py-2">
                <div className="row g-4">
                  {displayedTemplates.map((template: Template, index: number) => (
                    <div className="col-md-4 col-sm-6" key={template.id}>
                      <div className="template-block">
                        <div className="template-block-image">
                          <img src={template.image} className="img-fluid" alt={template.url || `Template ${index + 1}`} />
                        </div>
                        <div className="template-block-name">
                          {template.title || `Template ${index + 1}`}
                        </div>
                        <div className="overview-block">
                          <a href="javascript:;" className="btn-choose" onClick={() => handleChooseTemplate(template.id)}><span>Choose</span></a>
                          <a href="javascript:;" className="btn-preview" data-url={template.html || ''} data-bs-toggle="modal" data-bs-target="#template-preview" onClick={() => handlePreviewTemplate(template.url)}><span>Preview</span></a>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {templates.length > 4 && (
                  <div className="text-center mt-4">
                    <button className="btn btn-primary" onClick={handleShowMore}>
                      {showMore ? 'Show Less' : 'Show More'}
                    </button>
                  </div>
                )}
              </div>
              {children}
            </div>
          </div>
        </div>
      </section>
      <TemplatePreviewModal previewUrl={previewUrl} />
    </>
  );
};

export default TemplateListLayout;
