import { useContext, useState, useEffect, useRef } from 'react';
import { Modal } from "bootstrap";
import MessageRightBar from '../../messages-module/MessageRightBar';
import { axiosClient } from '../../../library/Service/axiosClient';
import { getStudentImage } from '../../../common/styles/Images';
import { getDomainValueForCode } from '../../../library/Core/SmartFunctions';
import { SmartContext } from '../../../library/Core/SmartContext';


function GetStartedPromptModal(props:any) {
  const [show, setShow] = useState(false);
  const [response, setResponse] = useState(null);
  const [conversationData, setConversationData] = useState(null);  
  const [conversationId, setConversationId] = useState(null);  
  const [isSingleConversation, setIsSingleConversation] = useState(false);
  const [message, setMessage] = useState('');
  const [newMessage, setNewMessage] = useState('');
  const { state } = useContext(SmartContext);

  // This ref will hold the DOM element of the modal

  const modalRef = useRef(null);
  const modalInstanceRef = useRef(null); // Keep track of the modal instance

  useEffect(() => {
    if (modalRef.current) {
      modalInstanceRef.current = new Modal(modalRef.current); // Initialize once
    }

    return () => {
      if (modalInstanceRef.current) {
        modalInstanceRef.current.dispose(); // Clean up modal instance
      }
    };
  }, []);

  useEffect(() => {
    if (modalInstanceRef.current) {
      if (show) {
        modalInstanceRef.current.show();
      } else {
        modalInstanceRef.current.hide();
      }
    }
  }, [show]);

  const handleShow = () => {
    // console.log(123)
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    if (show) {
      const fetchData = async () => {
        try {
          const res = await axiosClient().get(
            `${process.env.REACT_APP_MESSAGE_SERVER}/conversations/get-single-user-conversation/${props.loginId}/${props.student.uuid}`
          );
          // Check if there's conversation
          const isSingleConv = res.data?.length;
          setIsSingleConversation(isSingleConv);
          setConversationData(res.data); // Store the fetched conversation data
          setConversationId(conversationData[0]?.conversationId); // Store the fetched conversation data
        } catch (error) {
          console.error(error);
        }
      };

      fetchData();
    }
  }, [show, props.loginId, props.student.uuid]);

  // Function to handle message input change
  const handleInputChange = (event) => {
    setMessage(event.target.value); // Update state with input value
   };

    // Function to check if conversationId is empty and start appropriate flow
  const sendMessage = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior

    if (!message.trim()) {
      console.error("Message cannot be empty.");
      return;
    }

    if (!conversationId) {
      await startFreshConversation();
    } else {
      await startOldConversation();
    }
    // Clear the input field after submitting
    setMessage(''); // Reset the message state
  };

    // Send message to an old conversation
    const startOldConversation = async () => {
      try {
        const result = await axiosClient().post(
          `${process.env.REACT_APP_MESSAGE_SERVER}/conversations/${conversationId}/message`,
          { message }
        );
        setShow(false); // Hide the modal or UI after sending the message
        // console.log('Message sent:', result);
      } catch (error) {
        console.error('Error sending message:', error);
      }
    };
  
    // Create a new conversation and send a message
    const startFreshConversation = async () => {
      const memberIds = [];
      memberIds.push(props.student.uuid);
      try {
        const result = await axiosClient().post(
          `${process.env.REACT_APP_MESSAGE_SERVER}/conversations/`,
          {
            memberIds,
            message: message,
            userTypeCode: props.student.userTypeCode
          }
        );
        setShow(false); // Hide the modal or UI after sending the message
        // console.log('New conversation started and message sent:', result);
      } catch (error) {
        console.error('Error starting new conversation:', error);
      }
    };

    const sendDefaultMessage = async (messageTemplateType: string) => {
      let messageLanguage = '';
      let userName = props.name;
  
      let programName = getDomainValueForCode(props.programId, 'ALL_PROGRAM_CODE', state);
  
      switch (messageTemplateType) {
          case 'CAREER':
              messageLanguage = `Hey There, I’m ${userName}, from ${programName}. Your profile inspired me. I'm exploring career paths and would love to hear how your degree has influenced your career growth. Can you please share the various opportunities that opened up for you after graduation?`;
              break;
          case 'JOURNEY':
              messageLanguage = `Hey There, I’m ${userName}, from ${programName} and I'm curious about your professional journey. Can you please share the major milestones and obstacles you faced along the way?`;
              break;
          case 'JOB':
              messageLanguage = `Hey There, I’m ${userName}, from ${programName} and I’m interested in learning about your current job. Could you please tell me more about your role and how your education has influenced your career choices?`;
              break;
          default:
              messageLanguage = `Hey There, I’m ${userName}, a current student in the ${programName} program. I’d love to hear your insights on the career prospects after graduation. How has your degree helped in your career advancement?`;
              break;
      }
  
      setMessage(messageLanguage);
  };

  

  return (
    <>
 
       <a href="javascript:;" className={props.className} onClick={handleShow}>
        <i className="icon-alumni-send"></i>
        <span>Send</span>
      </a>

  
      <div className="modal fade" id='video_pop' ref={modalRef} tabIndex="-1" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
           
        <div className="p-0 border-radius-6 modal-body">
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
            {isSingleConversation && conversationData ? (
        <div className="alumni_chat_block  border-radius-6">
                
            <div className="alumni_chat_conversation_header">
                <i className="icon-left-arrow d-block d-md-none"></i>
                <div className="alumni-user-leftpane">
                <span className="img-alumni">
                <img
                className="img-fluid"
                src={getStudentImage(props.student)}
                alt={props.student.name}
                />
               </span>
               <span className="alumni-name-batch">
               <span className="alumni-name">{props.student.name}</span>
               <span className="alumni-message">
                 {props.student.userTypeCode}
               </span>
               </span>
               </div>
            </div>

      <div className="alumni_chat_conversation_block my-3">
        <div className="d-flex flex-column h-100 align-items-end">
          <div className="mt-auto w-100">
            <div className="row g-3 h-100 align-items-end">
              <div className="col-12">
                {conversationData.map((message: any) => (
                  <div
                    key={message.id} // Add a unique key for each message
                    className={`row ${
                      message.isFromCurrentUser === 1
                        ? 'justify-content-end'
                        : 'justify-content-start'
                    }`}
                  >
                    <div className="col-11 col-sm-8">
                      <div className="d-flex flex-column">
                        <div className="blue-block">{message.message}</div>
                        <div className="col-auto">{message.sentDate}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

        </div>
  ) : (
    <div className="alumni_chat_block border-radius-6">
      <div className="alumni_chat_to">
        <div className="row g-2 align-items-start">
          <div className="col-sm-auto">
            <label className="mt-1">Sent to:</label>
          </div>
          <div className="col-sm">
            <div className="sent_too_wrap row g-2 align-items-center">
              <div className="col-auto">
                <div className="sent_too">
                  <span>{props.student.name}</span>
                  <i className="icon-cross ms-2 font-10"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="get_started flex-1 d-flex flex-column justify-content-center align-items-center p-4">
        <i className="get_started_like"></i>
        <h3>
          Connect and Chat:
          <br className="d-none d-sm-block" /> Engage with the Community
          Today!
        </h3>
      </div>

      {props.userTypeCode=== 'STUDENT' && !isSingleConversation ? (
        <div className="get_started_prompt p-4">
          <div className="row g-3 justify-content-center">
            <div className="col-auto">
              <a href="javascript:;" onClick={() => sendDefaultMessage('CAREER')} >Career Prospects</a>
            </div>
            <div className="col-auto">
              <a href="javascript:;" onClick={() => sendDefaultMessage('JOURNEY')}>Ask about the journey</a>
            </div>
            <div className="col-auto">
              <a href="javascript:;" onClick={() => sendDefaultMessage('JOB')}>Ask about the job</a>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )}

      {(isSingleConversation && conversationData) || (props.userTypeCode!== 'STUDENT') || (message) ? (
		    // props.student.userTypeCode === 'ALUMNI' 
		    <div className="alumni_chat_conversation_send">
		      <div className="row g-2">
		        <div className="col">
		          <input
		            type="text"
		            className="form-control flex-1"
		            id="msgsend"
		            placeholder="Type your message..."
		            value={message} // Bind input to state
		            onChange={handleInputChange} // Capture input changes
		          />
		        </div>
		        <div className="col-auto">
		          <button type="button" className="btn btn-green position-relative px-3" onClick={(event) => sendMessage(event)}>
		            <i className="icon-alumni-send position-relative mx-1"></i>
		          </button>
		        </div>
		      </div>
		    </div>
		  ) : null}


        </div> {/*Model body close */}
          
          </div>
        </div>
      </div>

    </>
  );
}

export default GetStartedPromptModal;
