import { useContext, useEffect, useRef } from 'react';
import { validateFormField } from '../Core/FormFieldValidation';
import { SmartContext } from '../Core/SmartContext';
import { evaluateExpression, getControlValueFromState, handleControlValueChange } from '../Core/SmartFunctions';
import { SimpleFormControlArguments, State } from '../Core/SmartTypes';
import ErrorControl from './ErrorControl';

const CheckBoxYesNoControl = (args: SimpleFormControlArguments) => {
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey, parentDataKey } = { ...args };
    const data = getControlValueFromState(dataKey, state as State);
    const formControlRef = useRef(null); // Note: For providing reference to ErrorControl
    const isHidden = evaluateExpression(control?.hideExpression, state?.data);

    useEffect(() => {
        console.log('Text Control - Use Effect!');
        const errorMessages = validateFormField(control, data, state, control?.props?.label, dataKey);
        dispatch({ type: 'SET_FIELD_VALIDATION_ERRORS', payload: { dataKey, errorMessages } });
    }, []);

    if (isHidden) return <></>;

    return (
        <>
            {/* <div>{`.`} </div> */}
            <div className="form-check">
                {control?.props?.label && (
                    <label htmlFor={control.id} className={`form-check-label w-100 ${control.props.labelCSS}`}>
                        {/* {`${control.props.label} `} */}
                        <div dangerouslySetInnerHTML={{ __html: control.props.label }} />
                    </label>
                )}
                <input
                    id={control.id}
                    data-testid={control.id}
                    type="checkbox"
                    className="form-check-input"
                    hidden={control.props?.isCode || isHidden}
                    checked={data ?? false}
                    required={control.props?.required}
                    onChange={(event) =>
                        handleControlValueChange({ control, value: event.target.checked, dataKey, parentDataKey, state, dispatch })
                    }
                    ref={formControlRef}
                />
                <ErrorControl errorMessages={state?.formValidationErrors[dataKey]} />
            </div>
        </>
    );
};

export default CheckBoxYesNoControl;
