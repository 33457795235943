import { useContext, useRef, useState } from 'react';
import { SmartContext } from '../Core/SmartContext';
import { evaluateExpression, getControlValueFromState, handleControlValueChange, debounce } from '../Core/SmartFunctions';
import { SimpleFormControlArguments, State } from '../Core/SmartTypes';
import logger from '../Core/logger';
import { axiosClient } from '../Service/axiosClient';
import ErrorControl from './ErrorControl';
import { v4 as uuidv4 } from 'uuid';

const FileActionDocs = (args: SimpleFormControlArguments) => {
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey, parentDataKey } = { ...args };
    const data = getControlValueFromState(dataKey, state as State);
    const formControlRef = useRef(null); // Note: For providing reference to ErrorControl
    const isHidden = evaluateExpression(control.hideExpression, state?.data);
    const [fileLink, setFileLink] = useState(data);
    const [doclink, setDocLink] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const validExtensions = ['xls', 'xlsx', 'pdf', 'doc', 'docx'];

    // if (data) {
    //     setFileLink(data);
    // }

    const resetFileInput = debounce((inputElement: any) => {
        inputElement.value = '';
        setIsSuccess(false); // Reset success state after delay
    }, 3000);

    const handleFileControlUpload = async (state: State, e: any) => {
        e.preventDefault();

        const file = e.target.files[0];
        const extension = file.name.split('.').at(-1).toLowerCase();

        if (!validExtensions.includes(extension)) {
            alert('Please upload a valid file [xls, xlsx, pdf, doc, docx]');
            e.target.value = ''; // Clear the file input
            return;
        }

        const apiUrl = `${process.env.REACT_APP_COMMON_API}/upload/get-attached`;

        const formData = new FormData();
        formData.append('pdf_doc', file);

        try {
            const response = await axiosClient().post(apiUrl, formData);
            if (response.status === 400) {
                alert(response.data.message);
                return;
            }
            setIsSuccess(true);
            const link = response.data.link;
            setFileLink(link);

            handleControlValueChange({ control, value: link, dataKey, parentDataKey, state, dispatch });

            resetFileInput(e.target);
        } catch (error) {
            console.error('An error occurred:', error);
            alert('Error uploading file');
            e.target.value = ''; // Clear the file input in case of error
        }
    };

    if (isHidden) return <></>;

    return (
        <div className="w-100">
            <label htmlFor="formFileLg" className="form-label">
                {control.props.label}
            </label>

            <input
                id={control.id}
                data-testid={control.id}
                type="file"
                hidden={control.props?.isCode || isHidden}
                className="form-control form-control-lg pl-0"
                accept=".xls, .xlsx, .pdf, .doc, .docx"
                name={`cover${uuidv4()}`}
                onChange={(event) => handleFileControlUpload(state as State, event)}
            />
            {isSuccess && (
                <span key={uuidv4()} style={{ color: 'green', marginLeft: '10px' }}>
                    ✔ File uploaded successfully!
                </span>
            )}
            <ErrorControl errorMessages={state?.formValidationErrors[dataKey]} />

            {data && (
                <a href={data} target="_blank" rel="noopener noreferrer">
                    View File
                </a>
            )}
        </div>
    );
};

export default FileActionDocs;
