import React, { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
// import plusVisual from '../../styles/images/add_plus.svg';
import GridRowBuilder from '../Builders/GridRowBuilder';
import { SmartContext } from '../Core/SmartContext';
import { getControlValueFromState, getDataStructureFromControls, getUUIDs, isEmpty } from '../Core/SmartFunctions';
import { SimpleFormControlArguments, State } from '../Core/SmartTypes';
import ErrorControl from './ErrorControl';
import { useParams } from 'react-router-dom';

const plusVisual = `${process.env.REACT_APP_IMAGE_BASEPATH}/add_plus.svg`;

const GridWithRows = (args: SimpleFormControlArguments) => {
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey, parentDataKey } = { ...args };
    const data = getControlValueFromState(dataKey as string, state as State);
    const [uuids] = useState(getUUIDs(20));
    const [showControl, setShowControl] = useState(true);
    const [minDisplayRows, setMinDisplayRows] = useState(control?.props?.customProperties?.minDisplayRows ?? 3);
    const [currentCount, setcurrentCount] = useState(data?.length ? data?.length : minDisplayRows);
    const [Limit, setLimit] = useState(control?.props?.customProperties?.limit ?? 100);
    const [minRequiredRows, setMinRequiredRows] = useState(control?.props?.customProperties?.minRequiredRows ?? 1);
    const [otherConfig, setOtherConfig] = useState(control?.props?.customProperties?.otherConfig ?? []);
    const { code } = useParams();

    useEffect(() => {
        if ( minDisplayRows < minRequiredRows ) {
            setMinDisplayRows(minRequiredRows);
        }

        // for (let i = data.length; i <= 2; i++) {
        for (let i = data?.length; i < minDisplayRows; i++) {
            addEmptyRecord();
        }
    });

    const addEmptyRecord = () => {
        const emptyRow = { ...getDataStructureFromControls(control.controlGroup), rowUUID: uuidv4() };
        setcurrentCount(currentCount+1);
        dispatch({ type: 'ADD_NEW_RECORD_IN_ARRAY', payload: { dataKey: dataKey, value: emptyRow } });
    };

    const handleAdd = (event: React.SyntheticEvent) => {
        event.preventDefault();
        addEmptyRecord();
    };

    const handleDelete = (rowKey: string, index: number) => {
        const data = getControlValueFromState(dataKey as string, state as State);
        setcurrentCount(currentCount-1);
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey, value: [...data.slice(0, index), ...data.slice(index + 1)] } });
    };

    // This is customized code...
    const getActionButtons = (dataKey: string, state: State, dispatch: React.Dispatch<any>) => {
        const pageName = state.routeInfo.pageName;
        // this is to check and uncheck the applied to no other colleges
        const handleSkip = (event: any) => {
            // setShowControl(false);
            setShowControl(! event.target.checked);
            dispatch({
                type: 'SET_INTERNAL_STATE',
                payload: { key: `${dataKey}.skip`, value: event.target.checked, errorMessages: [] },
            });

            dispatch({
                type: 'CONTROL_VALUE_CHANGE',
                payload: {
                    dataKey: 'profile.appliedToNoOtherUgColleges',
                    value: true,
                },
            });
        };

        if (!['alumni-undergraduate'].includes(pageName) && (currentCount <= Limit)) {
            return (
                <button type="button" className="btn btn-link btn-add" onClick={handleAdd}>
                    <i>
                        <img src={plusVisual} alt="" />
                    </i>{' '}
                    Add More
                </button>
            );
        }

        if (pageName === 'alumni-undergraduate') {
            return (
                <>
                    {showControl && (
                        <button type="button" className="btn btn-link btn-add" onClick={handleAdd}>
                            <i>
                                <img src={plusVisual} alt="" />
                            </i>{' '}
                            Add More Applied Colleges
                        </button>
                    )}
                    <div className="form-check form-check-inline">
                        <input id="skip" type="checkbox" className="form-check" onClick={handleSkip} />
                        <label className="form-check-label" htmlFor="skip">
                            Applied to no other college
                        </label>
                    </div>
                </>
            );
        }
    };

    return (
        <>
            {showControl && control.props.label && (
                <label htmlFor={control.id} className="form-label w-100 mt-3">
                    {`${control.props.label} ${control.props.required ? '*' : ''}`}
                </label>
            )}

            {showControl &&
                data?.map((row: any, index: number) => (
                    <div key={`${control.id}-${row.id === undefined ? row?.rowUUID : row.id}`} className="row g-3 mb-3 position-relative">
                        <GridRowBuilder controls={control.controlGroup} parentDataKey={(dataKey + '.' + index) as string} padding=" ps-2" />
                        <span className="pt-2 ps-2 d-none">
                            <i
                                className="bi bi-trash fs-5 text-danger"
                                onClick={() => handleDelete((dataKey + '.' + index) as string, index)}></i>
                        </span>
                        {/* {index != 0 && ( */}
                        {index >= minRequiredRows && (
                            <a
                                className="btn-delete"
                                role="button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleDelete((dataKey + '.' + index) as string, index);
                                }}></a>
                        )}
                    </div>
                ))}
                
                {/**
                  * This section conditionally renders a row containing a `GridRowBuilder` component if `otherConfig` is not empty.
                  * 
                  * The `otherConfig` array provides configuration data that can differ from the config used in the rows above.
                  * This allows for a flexible layout where the last row can be customized differently based on the `otherConfig` data.
                  * 
                  * The `otherConfig` is an array of objects consisting of field config whose structure is same as the config we pass for GridRowBuilder
                  */}
                {showControl && ! isEmpty(otherConfig) && (
                    <div className='row g-2 align-items-center'>
                        <GridRowBuilder controls={otherConfig} parentDataKey={"widgetData"} padding=" ps-2" />
                    </div>
                )}
                
            <ErrorControl errorMessages={state?.formValidationErrors[dataKey]} />
            <div className="cta_add my-3">
                {/* <button type="button" className="btn btn-link btn-add" onClick={handleAdd}>
                    <i>
                        <img src={plusVisual} alt="" />
                    </i>{' '}
                    Add More
                </button> */}
                {getActionButtons(dataKey, state, dispatch)}
            </div>
        </>
    );
};

export default GridWithRows;
