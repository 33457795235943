import React, { useState, useEffect,useContext } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import axios from 'axios';
import { axiosClient } from '../../library/Service/axiosClient';
import { Link } from 'react-router-dom';
import useManagementDashboard from '../management/dashboard/useManagementDashboard';
import { getinstituteList } from '../../library/Service/domainService'
import { GlobalContext } from '../../library/Core/GlobalContext';
import SessionContext from '../../library/Core/SessionContext';


import { useNavigate } from 'react-router';

// import ImgAddPlus from '../../styles/images/add_plus.svg';
import Imgfacebook from '../../styles/images/facebook.svg';
import ImgTwitterX from '../../styles/images/x-twtter.svg';

const ImgAddPlus = `${process.env.REACT_APP_IMAGE_BASEPATH}/add_plus.svg`;
 

const SocialMedia: React.FC = () => {
//variable initialization

    
    const [imageLink, setImageLink] = useState('');
    const navigate = useNavigate();

    const [selectedInstitute, setSelectedInstitute] = useState<string[]>([]);
    const [instituteError, setInstituteError] = useState<string>('');
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [gender, setGender] = useState<string>('');
    const [registrationBy, setRegistrationBy] = useState<string>('');
    const [registrationByError, setRegistrationByError] = useState<string>('');
    const [lastNameError, setLastNameError] = useState<string>('');
    const [firstNameError, setFirstNameError] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');
    const [phoneNumberError, setPhoneNumberError] = useState<string>('');
    const [genderError, setGenderError] = useState<string>('');
    const [countryCode, setCountryCode] = useState<string>(''); 
    const [countryCodeError, setCountryCodeError] = useState<string>('');
    const [emailNamesError, setEmailNamesError] = useState<string[]>([]);
    
    const [inames, setInames] = useState<string[]>([]);
    const [iids, setIids] = useState<number[]>([]);
    const currentTime = new Date();
    let sesinstituteId = useState<string>(''); 
    let userId = useState<string>('');
    const { globalState } = useContext(GlobalContext);
    
    const { getCampuses } = useManagementDashboard();
    const campuses = getCampuses();
    const [instituteList, setinstituteList] = useState(getinstituteList('INSTITUTE_GROUP_CODE', campuses));
    //alert(instituteList);
   // console.log(instituteList);

    const { sessionState } = useContext(SessionContext);
   
    let [institutes, setInstitutes] = useState<string[]>([]);
 

    useEffect(() => {
      if (sessionState && sessionState.institutes) {
        setInstitutes(sessionState.institutes);
      }
    }, [sessionState]);
 

    const queryParams = new URLSearchParams(window.location.search);
    
    const eventId = queryParams.get('id');
    console.log(eventId)
    
    let school_settings:any = JSON.parse(localStorage.getItem('school_settings') ?? '{}');      
    //console.log(school_settings)  
       
    const userDataString = sessionStorage.getItem('user');
    
   //console.log(userDataString,'test kjhjk');
    if (userDataString) {
        // Parse JSON string to object
        const userData = JSON.parse(userDataString);
        sesinstituteId = userData.institutes; 
        userId = userData.userId;       
    }
  //  console.log('userid',userId);
    // Event handler to handle changes in the inputs
    const handleCountryCodeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedCountryCode = e.target.value;
        setCountryCode(selectedCountryCode);
        setCountryCodeError('');
    }
    const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(e.target.value);
        setFirstNameError('');
    };

    const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLastName(e.target.value);
        setLastNameError('');
    };
  
    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
        setEmailError('');
    };

    const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPhoneNumber(e.target.value);
        setPhoneNumberError('');
    };

    const handleGenderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setGender(e.target.value);
        setGenderError('');
    };

    const handleRegisterationByChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRegistrationBy(e.target.value);
        setRegistrationByError('');
    };

    const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files != null) {
            const apiUrl = `${process.env.REACT_APP_COMMON_API}/upload/get-attached`;
            const formData = new FormData();
            //alert(formData);
            formData.append('pdf_doc', e.target.files[0]);

            const response = await axiosClient().post(apiUrl, formData);
            const link = response.data.link;
            const prefix = 'https://s3.ap-south-1.amazonaws.com/user.image1/';

            //https://ap-south-1.console.aws.amazon.com/s3/buckets/ags-event
            const modifiedUrl = link.slice(prefix.length);
            setImageLink(link);

            // Check if the element with ID 'imagelink' exists before setting its text content
            const imageLinkElement = document.getElementById('imagelink');
            if (imageLinkElement) {
                imageLinkElement.textContent = link; // Display the link in the label
            }
        }
    };

    const validateFields = () => {//validation
       // const filteredInstitutes = selectedInstitutes.filter(institute => institute !== '' && selectedInstitutes.length > 0);
    //    const instituteToFilter = selectedInstitute.slice(1);
    //    alert(instituteToFilter);
        const filteredInstitute = selectedInstitute.filter(institute => institute !== '' && selectedInstitute.length > 0);
      //  alert(filteredInstitute)
        let isValid = true;
 
        if (filteredInstitute.length === 0) {
            setInstituteError('Please select at least one Institute.');
            isValid = false;
        } else {
            setInstituteError('');
        }
        if (typeof firstName !== 'string' || !/^[a-zA-Z]+$/.test(firstName.trim()) || firstName.length > 15) {
            setFirstNameError('First name must be a non-empty string containing only letters with a length between 1 and 15 characters');
            isValid = false;
        }
        if (typeof lastName !== 'string' || !/^[a-zA-Z]+$/.test(lastName.trim()) || lastName.length > 15) {
            setLastNameError('Last name must be a non-empty string containing only letters with a length between 1 and 15 characters');
            isValid = false;
        }
        if (!email.trim()) {
            setEmailError('Email is required');
            isValid = false;
        } else if (!isValidEmail(email)) {
            setEmailError('Please enter a valid email address');
            isValid = false;
        }

        if (!phoneNumber.trim()) {
            setPhoneNumberError('Phone number is required');
            isValid = false;
        } else if (!isValidPhoneNumber(phoneNumber)) {
            setPhoneNumberError('Please enter a valid phone number');
            isValid = false;
        }
        
        if (!countryCode) {
            setCountryCodeError('Country Code is required');
            isValid = false;
        } else if (countryCode.includes('India (+91)')) {
            // Validate for India specific rules
            const phoneNumberDigits = phoneNumber.replace(/\D/g, ''); // Remove non-numeric characters
            if (phoneNumberDigits.length !== 10 || !/^[6-9]/.test(phoneNumberDigits)) {
                setPhoneNumberError('Please enter a valid phone number for India (+91)');
                isValid = false;
            }
        } else {
            // Validate for other country codes
            const phoneNumberDigits = phoneNumber.replace(/\D/g, ''); // Remove non-numeric characters
            if (phoneNumberDigits.length < 6 || phoneNumberDigits.length > 15) {
                setPhoneNumberError('Please enter a valid phone number');
                isValid = false;
            }
        }
        
        if (!gender) {
            setGenderError('Gender is required');
            isValid = false;
        }
        if (!registrationBy) {
            setRegistrationByError('Registration By is required');
            isValid = false;
        }
        return isValid;
    };
    
    const isValidEmail = (email: string) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const isValidPhoneNumber = (phoneNumber: string) => {
        return /^\d+$/.test(phoneNumber);
    };

    const filteredInstitute = selectedInstitute.filter(institute => institute !== '' && selectedInstitute.length > 0);
    const handleSubmit = () => {//Submit action

        if (validateFields()) {
            const saveLeadInfo = async () => {
                try {

                    const emailResponse = await axios.post(`${process.env.REACT_APP_LEAD_SERVER}/addlead/getinstitutebyemail`, { email: email, instituteId: filteredInstitute });
                    const instNames = emailResponse.data.data.map((emailResult: any) => emailResult.name);
            
                    // Check if any names are returned
                    if (instNames.length > 0) {
                        setEmailNamesError(instNames);
                        // Handle error or provide feedback to the user
                    }else{

                    const response = await axios.post<{ success: boolean, message: string, insertedIds: number }>(`${process.env.REACT_APP_LEAD_SERVER}/addlead/saveleadpersonalinfo`, {
                        first_name: firstName,
                        last_name:lastName, // You can add last name logic if needed
                        email,
                        mobile: phoneNumber,
                        gender,
                        registrationBy:registrationBy,
                        institute_ref_id:filteredInstitute, // Assuming institute reference ID
                        added_on:currentTime, // Assuming added date
                        countryCode:countryCode,
                        added_by:userId
                    });

                    if (response.data.success) {
                        const insertedId = response.data.insertedIds;
                        console.log('Inserted ID:', insertedId);
                        // Redirect to the next page
                       window.location.href = `/lead/add-target-program?insert_id=`+insertedId; 
                      //  <Link to="/lead/add-target-program">Go to Target Program</Link>
                    } else {
                        console.error('Failed to save lead personal info:', response.data.message);
                    }
                }
                } catch (error) {
                    console.error('Error while saving lead personal info:', error);
                }
            };

            saveLeadInfo();
        }
    };
    useEffect(() => {
         fetchInstitute();
       
    }, []);
    // Function to fetch programs from API

    const fetchInstitute = async () => {
        try {
        
        
     let requestBody = { instituteId:sesinstituteId };
     console.log('pass id',requestBody);
      // const requestBody = { institutes }; // Construct the request body
      
        const response = await axios.post(`${process.env.REACT_APP_LEAD_SERVER}/addlead/getinstitute`, requestBody);
        const data = response.data;
            if (data.success) {
                // Update component state with fetched programs
                setInames(data.names);
                setIids(data.id);
               // alert(data.names); 
            } else {
                console.error('Failed to fetch institute:', data.message);
            }
        } catch (error) {
            console.error('An error occurred while fetching institute:', error);
        }
    };
 
    // Function to generate options for program dropdown
    const handleInstituteCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
        const { checked } = e.target;
        setEmailNamesError([]);
        if (checked) {
            setSelectedInstitute([...selectedInstitute, id]);
        } else {
            setSelectedInstitute(selectedInstitute.filter((selectedId) => selectedId !== id));
        }
    };

    const generateInstituteCheckboxes = (): JSX.Element[] => {
        return iids.map((iid, index) => (
            <div key={iid} className="col-auto">
                <div className='form-check'>
                <input
    className="form-check-input"
    type="checkbox"
    name="institute"
    id={`institute-${iid}`}
    value={String(iid)} // Convert id to string
    checked={selectedInstitute.includes(String(iid))} // Convert id to string
    onChange={(e) => handleInstituteCheckboxChange(e, String(iid))}
/>

                    <label className="form-check-label" htmlFor={`program-${iid}`}>
                        {inames[index]}
                    </label>
                </div>
            </div>
        ));
    };




    return (
        <>
        
        <div className="main flex-1 bg-white">
            <div className='max-1140 pt-3 pt-sm-4 px-lg-0 pb-5 overflow-hidden mx-auto'>
            <div className="d-flex justify-content-between align-items-center mb-3 ">
                <h3 data-aos="fade-left" data-aos-delay="600" className="font-35 font-600 mb-0 block-title aos-init aos-animate">
                    Social Media
                </h3>    
                <div className="dashboard innerpage smart-scroll">
                    <a href="/socialmedia/socialpost" data-aos="fade-right" data-aos-delay="600" className="btn btn-green height-40 d-inline-flex text-white font-14 px-3 m-0 text-decoration-none"><span className="d-flex align-items-center"><img src={ImgAddPlus} className="me-2" alt=""/>Create New Post</span></a>        
                </div>                
            </div>

            <div className="job_internship_tab polartabsec radius-6 mx-0">
                <div className="xscroll pb-1 pb-md-0">
                    <ul className="nav nav-tabs" id="JobInternshipTab" role="tablist">
                    
                        <li className="nav-item" role="presentation">
                        <button className="nav-link px-5" id="sent-tab" data-bs-toggle="tab" data-bs-target="#sent" type="button" role="tab" aria-controls="sent" aria-selected="true">Sent</button>
                        </li>
                        {/* <li className="nav-item" role="presentation">
                        <button className="nav-link px-5" id="draft-tab" data-bs-toggle="tab" data-bs-target="#draft" type="button" role="tab" aria-controls="draft" aria-selected="false">Draft (5)</button>
                        </li>
                        <li className="nav-item" role="presentation">
                        <button className="nav-link px-5" id="archive-tab" data-bs-toggle="tab" data-bs-target="#archive" type="button" role="tab" aria-controls="archive" aria-selected="false">Archive</button>
                        </li> */}
                
                    </ul>
                </div>
          </div>

          <div className="social_wrap">
                  {/* <ul>
                      <li>
                        <div className="post-view">
                          <div className="post-name">
                            <div className="social-logo">
                              <img src={Imgfacebook} width="50" className="img-fluid" alt=""/>
                            </div>
                            <div className="post-title">
                              Celebrating Podar Alumni who contributed in Chandrayan 3
                            </div>
                          </div>
                          <a href="javascript:;" className="btn btn-border-gray"><span>View Post</span></a>
                        </div>
                        <div className="post-detail">
                          <div className="post-detail-date">
                            20-Mar-2023, 10:38 am <span className="d-block">(Posted On)</span>
                          </div>
                          <a href="javascript:;" className="btn btn-dark-blue w-auto height-40 px-3 text-white"><span>Re-Use</span></a>
                        </div>
                      </li>
                      <li>
                        <div className="post-view">
                          <div className="post-name">
                            <div className="social-logo">
                              <img src={ImgTwitterX} width="50" className="img-fluid" alt=""/>
                            </div>
                            <div className="post-title">
                              Manoj Prabhakar IPS - Alumni Spolight
                            </div>
                          </div>
                          <a href="javascript:;" className="btn btn-border-gray"><span>View Post</span></a>
                        </div>
                        <div className="post-detail">
                          <div className="post-detail-date">
                            28-Mar-2023, 11:18 am <span className="d-block">(Posted On)</span>
                          </div>
                          <a href="javascript:;" className="btn btn-dark-blue w-auto height-40 px-3 text-white"><span>Re-Use</span></a>
                        </div>
                      </li>
                  </ul> */}
                </div>
            </div>
        </div>
        
        </>
    );
};
export default SocialMedia;