import { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { SmartContext } from '../../library/Core/SmartContext';
import { formatFullName, getDomainValueForCode, isSchoolAdmin, isSuperAdmin } from '../../library/Core/SmartFunctions';
import JobListingService from '../jobs/listing/JobListingService';
import moment from 'moment';
import { Modal, Tooltip } from 'bootstrap';
import SessionContext from '../../library/Core/SessionContext';

const JobCardControl = (job: any) => {
    // const { job, handleViewJobDescription } = props;

    const { state, dispatch } = useContext(SmartContext);
    const { sessionState } = useContext(SessionContext);

    // const [popupVisible, setPopupVisible] = useState(false);
    // const [jobDescription, setJobDescription] = useState('');

    useEffect(() => {
        var tooltipTriggerList = [].slice.call(document.querySelectorAll(".defaultTooltip"));
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl);
        });
    }, []);

    const handleSavePreference = (params: any) => {
        JobListingService.savePreference(params);
        setTimeout(() => refresh(), 0);
    };

    // const handleViewJobDescription = (description: string) => {
    //     console.log(description, " description ");
    //     setJobDescription(description);
    //     setPopupVisible(true);
    //     // const modalElement = document.getElementById('jobDescriptionModal');
    //     // if (modalElement) {
    //     //     const modal = new Modal(modalElement);
    //     //     modal.show();
    //     // }
    //     // const modal = new Modal(document.getElementById('jobDescriptionModal'));
    //     // modal.show();
    // };


    // useEffect(() => {
    //     if (popupVisible) {
    //         const modalElement = document.getElementById('jobDescriptionModal');
    //         if (modalElement) {
    //             const modal = new Modal(modalElement);
    //             modal.show();

    //             modalElement.addEventListener('hidden.bs.modal', () => {
    //                 setPopupVisible(false);
    //             });
    //         }
    //     }
    // }, [popupVisible]);

    const handleSendMessage = (event: any, id: string) => {
        event.preventDefault();
        state.actions['ROUTER_NAVIGATE'](`/messages/send-message/${id}`);
    };

    const refresh = () => dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'refreshJobs', value: uuidv4() } });

    return (
        <>
            <div key={job.id} className="act_lst mb-3 position-relative">
                <span className="list_badge font-14 font-500">{job.jobTypeCode?.replace("_"," ")}</span>
                <div className="row m-0 d-flex flex-wrap justify-content-between align-items-end postedrow">
                    <div className="flex-1 ps-0">
                        <h4 className="font-20 font-500 mb-2">
                            {job.title} @ {job.companyName}
                        </h4>
                        <div className="d-flex flex-wrap exp_loc">
                            <a href="javascript:void(0)" className="btn btnicon btn-icon-bg">
                                <span className="icon-brife-case font-12 text-dark-blue icon"></span>
                                <span>
                                    {job.minExperience} - {job.maxExperience} Year Experience
                                </span>
                            </a>
                            <a href="javascript:void(0)" className="btn btnicon btn-icon-bg">
                                <span className="icon-location-pin font-14 text-dark-blue icon"></span>
                                <span>{getDomainValueForCode(job.cityCode, 'CITY_CODE', state)}</span>
                            </a>
                            {/* <a
                                role="button"
                                className="btn btnicon btn-icon-bg"
                                onClick={() => handleViewJobDescription(job.description)}
                                style={{display: 'flex'}}
                                >
                                <img src={`${process.env.REACT_APP_IMAGE_BASEPATH}/file_icon.png`}  style={{width:'10px'}} />
                                <span>View Job Description</span>
                            </a> */}
                             <a
                                role="button"
                                className="btn btnicon btn-icon-bg defaultTooltip"
                                title={job.description}
                                style={{display: 'flex'}}
                                >
                                <img src={`${process.env.REACT_APP_IMAGE_BASEPATH}/file_icon.png`}  style={{width:'10px'}} />
                                <span>View Job Description</span>
                            </a>
                        </div>
                    </div>
                    <div className="px-0 text-end">
                        <p className="font-14 font-500 text-dark-blue mb-0 mb-sm-3 postedtxt">
                        Posted{' '}
                        {moment().diff(job.createdAt, 'days') > 0
                            ? moment().diff(job.createdAt, 'days') + 'days ago'
                            : 'today'}
                        </p>
                    </div>
                </div>
                <div className="row m-0 d-flex flex-wrap justify-content-between align-items-end">
                    <div className="flex-1 ps-0">
                        <a href="" className="d-flex align-items-start person_detls text-decoration-none">
                            {/* <img
                        src="https://s3.ap-south-1.amazonaws.com/media.univariety.com/uploads/alumni/profile/20230215_004656_.png?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&amp;X-Amz-Algorithm=AWS4-HMAC-SHA256&amp;X-Amz-Credential=AKIAQ4A7CWXKQ2FVEBPU%2F20230415%2Fap-south-1%2Fs3%2Faws4_request&amp;X-Amz-Date=20230415T063831Z&amp;X-Amz-SignedHeaders=host&amp;X-Amz-Expires=600&amp;X-Amz-Signature=b1553bca620f24f25cdd26b90967e7cb745ec3dce7a46a1d6cfd7e6527375499"
                        className="rounded-circle me-2"
                        width="40"
                        height="40"
                    /> */}
                            <div className="lstperdetails text-black">
                                <p className="m-0 font-14 font-500 line-height-18">
                                    <span className="font-16">{formatFullName(job.firstName, job.lastName)}</span>,{' '}
                                    {/* <em>Class of {job.passOutYear}</em> */}
                                    <em>{job.programName}</em>
                                </p>
                                <p className="m-0 font-14 font-500 line-height-18">
                                    <em>{job.description}</em>
                                </p>
                            </div>
                        </a>
                    </div>
                    <div className="pe-0 text-end">
                        <div className="d-flex flex-wrap justify-content-end">
                            {job.studentId != sessionState?.id && ! isSchoolAdmin() && sessionState?.userTypeCode!=='SUPER_ADMIN' && (
                            <a
                                className={`btn btnicon listblk_icon me5 circle-effect ${job.isSaved ? 'btn-dark-blue' : ''}`}
                                id="MakeSave_42"
                                role="button"
                                onClick={() => handleSavePreference({ jobId: job.id, isSaved: ! job.isSaved })}>
                                <span className={`icon-ribbon-invert font-18 icon ${job.isSaved ? 'text-white' : ''}`} id="Icon_42"></span>
                            </a>
                            )}

                            {!job.isInterested && job.studentId != sessionState?.id && ! isSchoolAdmin() && sessionState?.userTypeCode!=='SUPER_ADMIN' && (
                                <a
                                    className={`btn ${
                                        job.isInterested ? 'btn-dark-blue' : 'btn-green'
                                    } height-40 d-inline-flex text-white font-500 font-16 text-decoration-none justify-content-center`}
                                    id="MakeInterest_42"
                                    role="button"
                                    onClick={() => handleSavePreference({ jobId: job.id, isInterested: 1 })}>
                                    <span id="Interest_Icon_42">Send Interest</span>
                                </a>
                            )}

                            {job.isInterested && job.studentId != sessionState?.id && ! isSchoolAdmin() && sessionState?.userTypeCode!=='SUPER_ADMIN' && (
                                <a
                                    className={`btn btn-dark-blue height-40 d-inline-flex text-white font-500 font-16 text-decoration-none justify-content-center`}
                                    id="MakeInterest_42"
                                    role="button"
                                    onClick={(event) => handleSendMessage(event, job.studentId)}>
                                    <span id="Interest_Icon_42">Follow - Up</span>
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {/* <div
                className="modal fade"
                id="jobDescriptionModal"
                tabIndex={-1}
                aria-labelledby="jobDescriptionModalLabel"
                aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="jobDescriptionModalLabel">
                                Job Description
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => setPopupVisible(false)}></button>
                        </div>
                        <div className="modal-body">
                            <p>{jobDescription}</p>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                                onClick={() => setPopupVisible(false)}>
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
};

export default JobCardControl;
