import { isEmpty } from '../../../library/Core/SmartFunctions';
import { axiosClient } from '../../../library/Service/axiosClient';
import PageConfigService from '../../../services/page-config.service';
import { REPORT_CONFIG } from './report-config';
import PageConfigAllSchoolSummary from './PageConfigAllSchoolSummary.json';
import PageConfigBenefitsReports from './PageConfigBenefitsReports.json';
import PageConfigFreeTextCollege from './PageConfigFreeTextCollege.json';
import PageConfigFreeTextExams  from './PageConfigFreeTextExams.json';
import PageConfigAllAlumniReport  from './PageConfigAllAlumniReport.json';

class InternalAdminReportService {
    static pageLoader(params: any): Promise<any> {
        const state: any = {};
        const searchCriteria = this.getDefaultSearchCriteria(params.pageName);
        const queryParams = new URLSearchParams(window.location.search);
        const profile_completion = queryParams.get('profile_completion');
        if (params?.schoolId) searchCriteria.schoolId.push(params.schoolId);
        if (profile_completion === "UpToDate") searchCriteria.profileCompletionStatusCode = this.translateProfileCompletionStatusCode['upToDate'];
        if (profile_completion === "L1Due") searchCriteria.profileCompletionStatusCode = this.translateProfileCompletionStatusCode['l1Due'];
        if (params?.profileCompletionStatusCode)
        {
            searchCriteria.profileCompletionStatusCode = this.translateProfileCompletionStatusCode[params.profileCompletionStatusCode];
            if (params?.profileCompletionStatusCode === 'pendingValidation') searchCriteria.accountStatusCode = 'NEED_VALIDATION';
        }

        if(params.pageName === 'internal-admin-user-entered-college') searchCriteria.isNonStandardCollege = 1;
        if(params.pageName === 'internal-admin-user-entered-exam') searchCriteria.isNonStandardExam = 1;

        return Promise.all([this.getPageConfig(params.pageName), this.search(searchCriteria, params.pageName)]).then((values) => {
            let formconfig = values[0];
            if(params.pageName === 'internal-admin-all-school-summary') formconfig = {...values[0],title:''}
            state.formConfig = formconfig;
            state.data = {
                alumniList: values[1].data ?? [],
                searchCriteria,
            };
            state.routeInfo = params;
            state.internal = { gridState: {} };
            return Promise.resolve(state);
        });
    }

    static getPageConfig(pageName:string) {
        if (pageName === 'internal-admin-all-school-summary') {
            return Promise.resolve(PageConfigAllSchoolSummary);
        } else if(pageName==='internal-admin-benefits-report'){
            return Promise.resolve(PageConfigBenefitsReports);
         }else if(pageName==='internal-admin-user-entered-college'){
            return Promise.resolve(PageConfigFreeTextCollege);
        }else if(pageName==='internal-admin-user-entered-exam'){
            return Promise.resolve(PageConfigFreeTextExams);
        }else if (pageName === 'internal-admin-all-alumni-report') {
            return Promise.resolve(PageConfigAllAlumniReport);
        }else{
            const res = PageConfigService.get(pageName);
            return res.then(data => {
                return { data };
            });
        }
    }

    static getDefaultSearchCriteria(pageName: string = ''): any {
        return {
            passOutYear: [],
            schoolId: [],
            schoolGroupId: null,
            accountStatusCode: null,
            lastCallStatusCode: null,
            profileCompletionStatusCode: null,
            // educationOrEmploymentCurrentStatusCode: [],
            createdFrom: null,
            createdTo: null,
            isPrestigiousCollege: null,
            isPrestigiousUniversity: null,
            residingCountryCode: [],
            residingCityCode: [],
            isValidCollegeCode: null,
            collegeTypeCode: null,
            schoolClientStatusCode: null,
            serviceTypeCode: null,
            testProfileStatusCode: null,
            isValidCourseCode: null,
            pairingWithSimilarAlumni: null,
            networkForSocialCausesVolunteer: null,
            networkForBusinessIdeasAndPartnerships: null,
            filtersCourse: [],
            filtersCountry: [],
            filtersIndustry: [],
            filtersFunctionalArea: [],
            networkSamePgCourseAlumni: null,
            jobAndInternships: null,
            eventsInterestedInUpskilling: null,
            eventsInterestedInEntrepreneurship: null,
            benefitsReport: null,
            userStatusCode:[],
            workingStatusCode:[],
            isNonStandardCollege: null,
            isNonStandardExam: null,
            collegeTypeCodeJson: null,
            examTypeCodeJson: null,
            programId: [],
            ispeerReviewed:[],
            isNotable : null,
            isRaw : "NO"
        };
    }

    static search(searchCriteria: any, pageName: string) {
        const config = REPORT_CONFIG[pageName];
        const criteria = this.sanitizeCriteria(searchCriteria, pageName);
        return axiosClient().post(`${process.env.REACT_APP_INTERNAL_ADMIN_SERVER}/search/${config.SEARCH_API}`, criteria);
    }
    
    static searchCount(searchCriteria: any, pageName: string) {
        const config = REPORT_CONFIG[pageName];
        const criteriaCopy = { ...searchCriteria };
        if (pageName === 'internal-admin-user-entered-college') criteriaCopy.isNonStandardCollege = 1;
        if (pageName === 'internal-admin-user-entered-exam') criteriaCopy.isNonStandardExam = 1;
    
        const criteria = this.sanitizeCriteria(criteriaCopy, pageName);
        //const criteria = this.sanitizeCriteria(searchCriteria, pageName);
        return axiosClient().post(`${process.env.REACT_APP_INTERNAL_ADMIN_SERVER}/search/all-alumni-count`, criteria);
    }

    static sanitizeCriteria(searchCriteria: any, pageName: any) {
        const criteria = { ...searchCriteria };
        for (const [key, value] of Object.entries(searchCriteria)) {
            criteria[key] = Array.isArray(value) && typeof value[0] === 'object' ? value.map((obj) => Object.values(obj)[0]) : value;
        }

        if (['YES', 'NO'].includes(criteria['isValidCollegeCode'])) {
            criteria['hasNonStandardCollege'] = criteria['isValidCollegeCode'] === 'YES' ? 0 : 1;
            delete criteria.isValidCollegeCode;
        }

        if (['YES', 'NO'].includes(criteria['isValidCourseCode'])) {
            criteria['hasNonStandardCourse'] = criteria['isValidCourseCode'] === 'YES' ? 0 : 1;
            delete criteria.isValidCourseCode;
        }

        if (['YES', 'NO'].includes(criteria['networkForSocialCausesVolunteer'])) {
            criteria['networkForSocialCausesVolunteer'] = criteria['networkForSocialCausesVolunteer'] === 'YES' ? 1 : 0;
        }

        if (['YES', 'NO'].includes(criteria['networkForBusinessIdeasAndPartnerships'])) {
            criteria['networkForBusinessIdeasAndPartnerships'] = criteria['networkForBusinessIdeasAndPartnerships'] === 'YES' ? 1 : 0;
        }
        
        if (['YES', 'NO'].includes(criteria['networkSamePgCourseAlumni'])) {
            criteria['networkSamePgCourseAlumni'] = criteria['networkSamePgCourseAlumni'] === 'YES' ? 1 : 0;
        }

        if (['UG', 'PG'].includes(criteria['collegeTypeCodeJson'])) {
            criteria['collegeTypeCodeJson'] = criteria['collegeTypeCodeJson'] === 'UG' ? `{"nonStandardType": "COLLEGE","type": "UG"}` : `{"nonStandardType": "COLLEGE","type": "PG"}`;
        }

        if (['UG', 'PG'].includes(criteria['examTypeCodeJson'])) {
            criteria['examTypeCodeJson'] = criteria['examTypeCodeJson'] === 'UG' ? `{"nonStandardType": "EXAM","type": "UG"}` : `{"nonStandardType": "EXAM","type": "PG"}`;
        }

        if (['YES', 'NO'].includes(criteria['jobAndInternships'])) {
            criteria['jobAndInternships'] = criteria['jobAndInternships'] === 'YES' ? 1 : 0;
            criteria['eventsInterestedInEntrepreneurship'] = criteria['jobAndInternships'] == 0 ? criteria['eventsInterestedInEntrepreneurship'] : "";
            criteria['eventsInterestedInRemoteWorking'] = criteria['eventsInterestedInEntrepreneurship'] == 'NO' ? criteria['eventsInterestedInRemoteWorking'] : "";
        }

        if (['YES', 'NO'].includes(criteria['eventsInterestedInEntrepreneurship'])) {
            criteria['eventsInterestedInEntrepreneurship'] = criteria['eventsInterestedInEntrepreneurship'] === 'YES' ? 1 : 0;
            criteria['eventsInterestedInRemoteWorking'] = criteria['eventsInterestedInEntrepreneurship'] == 0 ? criteria['eventsInterestedInRemoteWorking'] : "";
        }

        if (['YES', 'NO'].includes(criteria['eventsInterestedInRemoteWorking'])) {
            criteria['eventsInterestedInRemoteWorking'] = criteria['eventsInterestedInRemoteWorking'] === 'YES' ? 1 : 0;
        }

        if (['YES', 'NO'].includes(criteria['eventsInterestedInUpskilling'])) {
            criteria['eventsInterestedInUpskilling'] = criteria['eventsInterestedInUpskilling'] === 'YES' ? 1 : 0;
        }
        
        if (['YES', 'NO'].includes(criteria['pairingWithSimilarAlumni'])) {
            criteria['pairingWithSimilarAlumni'] = criteria['pairingWithSimilarAlumni'] === 'YES' ? 1 : 0;
        }

        if (['YES', 'NO'].includes(criteria['ispeerReviewed'])) {
            criteria['ispeerReviewed'] = criteria['ispeerReviewed'] === 'YES' ? 1 : 0;
        }

        if (['YES', 'NO'].includes(criteria['isNotable'])) {
            criteria['isNotable'] = criteria['isNotable'] === 'YES' ? 1 : 0;
        }

        if (['YES', 'NO'].includes(criteria['isRaw'])) {
            criteria['isRaw'] = criteria['isRaw'] === 'YES' ? 1 : 0;
        }
        
        if (['YES', 'NO'].includes(criteria['hasHigherStudies'])) {
            criteria['hasHigherStudies'] = criteria['hasHigherStudies'] === 'YES' ? 1 : 0;
            criteria['needHelpForHigherStudies'] = criteria['hasHigherStudies'] == 1 ? criteria['needHelpForHigherStudies'] : "";
        }
        
        if (['YES', 'NO'].includes(criteria['needHelpForHigherStudies'])) {
            criteria['needHelpForHigherStudies'] = criteria['needHelpForHigherStudies'] === 'YES' ? 1 : 0;
        }
        
        if (!isEmpty(criteria['collegeTypeCode'])) criteria['collegeTypeCode'] = `${criteria['collegeTypeCode']} :`;
        
        if ( criteria.isValidCollegeCode == 'NA' ) {
            criteria.isValidCollegeCode = 0;
        }

        if ( criteria.isValidCourseCode == 'NA' ) {
            criteria.isValidCourseCode = 0;
        }

        if ( pageName == 'internal-admin-benefits-report' ) {
            criteria.benefitsReport = 1;
        }

        return criteria;
    }

    static getExportToExcelColumns = (pageName: string) => {
        const reportConfig: any = {
            'internal-admin-benefits-report': [
                'oldSystemUserId',
                'firstName',
                'lastName',
                'passOutYear',
                'genderCode',
                'schoolName',
                'workingStatusCode',
                'networkSimilarIndustryAlumni',
                'networkForSocialCausesVolunteer',
                'networkForBusinessIdeasAndPartnerships',
                'eventsNotifyJobReferrals',
                'eventsNotifyInternships',
                'eventsPursueUgDegree',
                'eventsPursuePgDegree',
                'eventsNeedHelpForUgApplication',
                'eventsNeedHelpForPgApplication',
                'eventsInterestedInUpskilling',
                'eventsInterestedInEntrepreneurship',
                'eventsInterestedInRemoteWorking',
            ],
        };

        return reportConfig[pageName];
    };

    static translateProfileCompletionStatusCode: any = {
        l1Due: 1,
        l2Due: 2,
        l3Due: 3,
        l4Due: 4,
        l5Due: 5,
        upToDate: 6,
    };
}

export default InternalAdminReportService;
