import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../Core/GlobalContext';
import { SmartContext } from '../Core/SmartContext';
import { SimpleFormControlArguments } from '../Core/SmartTypes';
import { getValueFromPath } from '../Core/SmartFunctions';

const ButtonControl = (args: SimpleFormControlArguments) => {
    const { state, dispatch } = useContext(SmartContext);
    const { globalDispatch } = useContext(GlobalContext);
    const { control, dataKey, parentDataKey } = { ...args };
    const dataValueKey = control?.props?.customProperties?.dataValueKey
    const dataValuePath = `${parentDataKey}.${dataValueKey}`;
    const [dataValue, setDataValue] = useState('');
    const rawValue = getValueFromPath(state.data, dataValuePath);
    
    useEffect(() => {
        if (rawValue !== undefined) {
            setDataValue(rawValue);
        }
    }, [rawValue]);

    return (
        // <div className="form-control">
        //     <button
        //         key={control.id}
        //         className={`btn btn-primary m-2 ${control.className}`}
        //         onClick={(event) => state.actions[control.id.toUpperCase()](event, state, dispatch, globalDispatch)}>
        //         {control.props.label}
        //     </button>
        // </div>

        // <div className="form-control">
            <button
                key={control.id}
                data-val={dataValue}
                className={`btn btn-primary m-2 ${control.props?.className}`}
                onClick={(event) => state.actions[control.id.toUpperCase()](event, state, dispatch, globalDispatch)}>
                {control.props.label}
            </button>
        // </div>
    );
};

export default ButtonControl;
