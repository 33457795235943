import React, { useContext, useEffect, useState } from 'react';
// import plusVisual from '../../styles/images/add_plus.svg';
import FormBuilderChild from '../Builders/FormBuilderChild';
import { SmartContext } from '../Core/SmartContext';
import { getControlValueFromState, getDataStructureFromControls, getUUIDs, isEmpty } from '../Core/SmartFunctions';
import { SimpleFormControlArguments, State } from '../Core/SmartTypes';
import ErrorControl from './ErrorControl';

const plusVisual = `${process.env.REACT_APP_IMAGE_BASEPATH}/add_plus.svg`;

interface ErrorObject {
    [key: string]: string[];
}

const FreeFormGrid = (args: SimpleFormControlArguments) => {
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey, parentDataKey } = { ...args };
    const data = getControlValueFromState(dataKey as string, state as State);
    const [uuids] = useState(getUUIDs(20));
    const customProperties = isEmpty(control.props.customProperties) ? { canAdd: true, canDelete: true } : control.props.customProperties;

    useEffect(() => {
        if (data.length === 0) addEmptyRecord();
    }, []);

    const addEmptyRecord = () => {
        const emptyRow = getDataStructureFromControls(control.controlGroup);
        dispatch({ type: 'ADD_NEW_RECORD_IN_ARRAY', payload: { dataKey: dataKey, value: emptyRow } });
    };

    const handleAdd = (event: React.SyntheticEvent) => {
        event.preventDefault();
        addEmptyRecord();
    };

    const handleDelete = (dataKey: string, index: number) => {
        // dispatch({ type: 'DELETE_RECORD_IN_ARRAY', payload: { dataKey: dataKey, index } });
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey, value: [...data.slice(0, index), ...data.slice(index + 1)] } });
        const newErrors = reIndexErrors(state?.formValidationErrors, dataKey, index);
        dispatch({ type: 'SET_ALL_FIELD_VALIDATION_ERRORS', payload: newErrors });
    };

    // const deleteErrorsForRow = (index: number) => {
    //     let prefix = `${dataKey}.${index}.`;
    //     const errorDetails = state?.formValidationErrors;

    //     for (let key in errorDetails) {
    //         if (key.startsWith(prefix)) {
    //             delete errorDetails[key];
    //         }
    //     }

    //     dispatch({ type: 'SET_ALL_FIELD_VALIDATION_ERRORS', payload: errorDetails });
    // };

    function reIndexErrors(errors: ErrorObject, baseDataKey: string, indexToRemove: number): ErrorObject {
        let newErrors: ErrorObject = {};

        for (let key in errors) {
            const [currentBaseDataKey, currentIndexStr, attribute] = key.split('.');
            const currentIndex = parseInt(currentIndexStr);

            if (currentBaseDataKey === baseDataKey) {
                if (currentIndex < indexToRemove) {
                    newErrors[key] = errors[key];
                } else if (currentIndex > indexToRemove) {
                    const newKey = `${baseDataKey}.${currentIndex - 1}.${attribute}`;
                    newErrors[newKey] = errors[key];
                }
                // keys equal to indexToRemove are omitted
            } else {
                newErrors[key] = errors[key]; // Copy over all other keys as-is
            }
        }

        return newErrors;
    }

    return (
        <>
            {control.props.label && (
                <label htmlFor={control.id} className="form-label w-100">
                    {`${control.props.label} ${control.props.required ? '*' : ''}`}
                </label>
            )}
            {data.map((row: any, index: number) => (
                <div key={`${control.id}-${row.id === undefined ? uuids[index] : row.id}`} className="section">
                    {customProperties.canDelete && data.length > 1 && (
                        <div className="delete-sticky">
                            <a
                                className="btn-delete"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleDelete(dataKey as string, index);
                                }}></a>
                        </div>
                    )}
                    <div className="row g-4">
                        <FormBuilderChild controls={control.controlGroup} parentDataKey={(dataKey + '.' + index) as string} padding=" " />
                    </div>
                    <ErrorControl errorMessages={state?.formValidationErrors[`${dataKey}.${index}`]} />
                </div>
            ))}
            <ErrorControl errorMessages={state?.formValidationErrors[dataKey]} />
            {customProperties.canAdd && (
                <div className="cta_add my-3">
                    <button type="button" className="btn btn-link btn-add" onClick={handleAdd}>
                        <i>
                            <img src={plusVisual} alt="" />
                        </i>{' '}
                        Add More
                    </button>
                </div>
            )}
        </>
    );
};

export default FreeFormGrid;
