import axios from 'axios';
import { Buffer } from 'buffer';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { axiosClient } from '../../library/Service/axiosClient';
import { AlumniProfileEditScreens } from '../../services/screen-navigation.service';
//import { getProductsTestMenuStudent } from '../../services/products-test.service';
import SessionContext from '../../library/Core/SessionContext';
import { useImmerReducer } from 'use-immer';
import smartReducer from '../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../library/Core/SmartTypes';
import { getMenuType, isEmpty } from '../../library/Core/SmartFunctions';

const AutoLogin = () => {
    const { hash, redirect_url, redirect_user_id, instituteId } = useParams();
    const [redirectUrl, setRedirectUrl] = useState('');

    useEffect(() => {
        if (redirectUrl !== '') {
            navigate(redirectUrl);
        }
    }, [redirectUrl]);

    const navigate = useNavigate();

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {
            ROUTER_NAVIGATE: navigate,
            REDIRECT: setRedirectUrl,
        },
    } as any);

    const [verificationMessage, setMessage] = useState('Please wait ..');

    const { sessionState, sessionDispatch } = useContext(SessionContext);

    // Define an array of route patterns and their corresponding actions
    const routeMappings = [
        { pattern: 'qna-dashboard', action: '/qna-dashboard' },
        { pattern: 'guidance', action: '/guidance' },
        { pattern: 'jobs', action: '/jobs' },
        { pattern: 'jobs/new', action: '/jobs/new' },
        { pattern: 'events/upcoming', action: '/events/upcoming' },
        { pattern: 'events/past', action: '/events/past' },
        { pattern: 'alumni/batch-mates-directory', action: '/alumni/batch-mates-directory' },
        { pattern: 'alumni/juniors-directory', action: '/alumni/juniors-directory' },
        { pattern: 'alumni/seniors-directory', action: '/alumni/seniors-directory' },
        { pattern: 'alumni/directory', action: '/alumni/directory' },
        { pattern: 'alumni/school-directory', action: '/alumni/school-directory' },
        { pattern: 'memories', action: '/memories' },
        { pattern: 'memories/new', action: '/memories/new' },
        { pattern: 'alumni/welcome', action: '/alumni/welcome' },
        { pattern: 'messages', action: '/messages' },
        { pattern: 'alumni/onboarding', action: '/alumni/onboarding' },
        { pattern: 'management/dashboard', action: '/management/dashboard' },
        { pattern: 'alumni/dashboard', action: '/alumni/dashboard' },
        { pattern: 'alumni/notification', action: '/alumni/notification' },
        { pattern: 'alumni-review/peer', action: '/alumni-review/peer' },
        { pattern: 'alumni-invite/peer', action: '/alumni-invite/peer' },
        { pattern: 'management/track/student-profiles', action: '/management/track/student-profiles' },
        { pattern: 'student-messages', action: '/student-messages' },
        { pattern: 'alumni-messages', action: '/alumni-messages' },
        { pattern: 'management/track/student-career-preferences', action: '/management/track/student-career-preferences' },
        { pattern: 'management/track/alumni-profiles', action: '/management/track/alumni-profiles' },
        { pattern: 'management/track/alumni-education-preferences', action: '/management/track/alumni-education-preferences' },
        { pattern: 'management/track/alumni-engagement-with-students', action: '/management/track/alumni-engagement-with-students' },
        { pattern: 'psychometric_test/careerGuide/Idealcareertest', action: '/psychometric_test/careerGuide/Idealcareertest' },
        { pattern: 'psychometric_test/careerGuide/PersonalityTest', action: '/psychometric_test/careerGuide/PersonalityTest' },
        {
            pattern: 'psychometric_test/careerGuide/MultipleIntelligenceTest',
            action: '/psychometric_test/careerGuide/MultipleIntelligenceTest',
        },
        { pattern: 'psychometric_test/careerGuide/StreamSelectorTest', action: '/psychometric_test/careerGuide/StreamSelectorTest' },
        { pattern: 'psychometric_test/careerGuide/Learningstyletest', action: '/psychometric_test/careerGuide/Learningstyletest' },
        // Add more route patterns and actions as needed
    ];

    function getRoute(routes: any) {
        for (const mapping of routeMappings) {
            const patternRegex = new RegExp(`^${mapping.pattern}$`);
            if (patternRegex.test(routes)) {
                return mapping.action;
            }
        }
    }

    useEffect(() => {
        const decodedUrl = !isEmpty(redirect_url) ? Buffer.from(redirect_url as string, 'base64').toString() : '';

        const fetchData = async () => {
            try {
                const responselogin = await axios.post(`${process.env.REACT_APP_STUDENT_SERVER}/v1/auth/user-auto-login`, {
                    userHash: hash,
                });
                console.log(responselogin, ' response 65 ');
                const data = await responselogin.data; // Extract the data from the response

                if (!data.token) {
                    alert('Not a valid link, Please contact adminstarator');
                    return;
                }
                if (data.token) {
                    const user = data as any;
                    //Todo: Given user Login set using Hash
                    sessionStorage.setItem('user', JSON.stringify(responselogin.data));
                    sessionStorage.setItem('user-jwt-token', responselogin.data.token);
                    localStorage.setItem('user-jwt-token', responselogin.data.token);
                    console.log(responselogin.data.selectedInstitute, '');
                    if (responselogin.data.userTypeCode === 'INSTITUTE_ADMIN') {
                        sessionStorage.setItem('school-image', responselogin.data.logo);
                        localStorage.setItem('school-image', responselogin.data.logo);
                    }

                    sessionDispatch({
                        type: 'SET',
                        payload: {
                            ...responselogin.data,
                            instituteLogo: localStorage.getItem('school-image'),
                            id: responselogin?.data?.studentId,
                        },
                    });

                    //sessionDispatch({ type: 'SET', payload: responselogin?.data?.selectedInstitute });

                    if (responselogin.data.userTypeCode === 'INSTITUTE_ADMIN') {
                        instituteAdminRedirect(user, decodedUrl, redirect_user_id);
                        return;
                    }
                    if (responselogin?.data?.selectedInstitute?.userTypeCode == 'STUDENT') {
                        console.log(responselogin?.data?.selectedInstitute?.programLevel, 'program level');
                        if (responselogin?.data?.selectedInstitute?.programLevel <= 12) {
                            getProductsTestMenuStudent(
                                responselogin?.data?.selectedInstitute?.instituteId,
                                responselogin?.data?.selectedInstitute?.programLevel
                            );
                        }
                    }
                    // sessionDispatch({
                    //     type: 'SET',
                    //     payload: {
                    //         ...responselogin?.data?.selectedInstitute,
                    //         id: responselogin?.data?.studentId,
                    //     },
                    // });

                    // set school info in local storage
                    const schoolinfoResponse = await axios.get(
                        `${process.env.REACT_APP_STUDENT_SERVER}/v1/auth/school-info-by-id/${responselogin.data.selectedInstitute.instituteId}`
                    );
                    localStorage.setItem('school_settings', JSON.stringify(schoolinfoResponse.data?.institute));
                    localStorage.setItem('school-image', schoolinfoResponse.data.institute.logo);
                    sessionStorage.setItem('school-image', schoolinfoResponse.data.institute.logo);

                    // const institute_logo = responselogin?.data?.selectedInstitute?.instituteLogo;
                    //sessionStorage.setItem('school-image', institute_logo);

                    if (!isEmpty(redirect_user_id)) {
                        let menuType = getMenuType(responselogin?.data?.selectedInstitute);
                        console.log(menuType);
                        sessionDispatch({
                            type: 'SET',
                            payload: {
                                ...responselogin.data,
                                // instituteLogo: localStorage.getItem('school-image'),
                                id: responselogin?.data?.selectedInstitute.studentId,
                                menuType: menuType,
                                tabType: menuType,
                                ...responselogin.data.selectedInstitute,
                            },
                        });
                        setSessionStorageAlumni(responselogin?.data?.selectedInstitute);
                    }

                    if (redirect_user_id != null) {
                        const redirectRoute =
                            decodedUrl !== '' ? `/alumni/${redirect_user_id}/${decodedUrl}` : `/alumni/${redirect_user_id}/profile`;
                        //  navigate(redirectRoute);
                        state.actions['REDIRECT'](`${redirectRoute}`);
                    } else {
                        userRedirect(user?.selectedInstitute, decodedUrl);
                    }
                }
                setMessage(data.message);
            } catch (error) {
                console.log(error);
                setMessage('Facing issue while validating your email address, Please try again');
            }
        };

        fetchData();
    }, []);

    const setSessionStorageAlumni = (selectedInstitute: any) => {
        const user = JSON.parse(sessionStorage.getItem('user') as string);
        sessionStorage.setItem('user', JSON.stringify({ ...user, ...selectedInstitute, id: selectedInstitute?.studentId }));
        localStorage.setItem('user', JSON.stringify({ ...user, ...selectedInstitute, id: selectedInstitute?.studentId }));
    };

    const instituteAdminRedirect = (user: any, redirectRoute: string, redirect_user_id: any) => {
        console.log(user);
        const userTypeCode = user?.userTypeCode;
        const studentId = user?.userId;
        const userStatusCode = user?.userStatusCode;
        const profileStatusCode = user?.profileStatusCode;

        sessionStorage.setItem('user', JSON.stringify(user));
        sessionStorage.setItem('user-jwt-token', user.token);

        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('user-jwt-token', user.token);

        let redirectToRoute = '/management/dashboard';

        if (!isEmpty(redirect_user_id)) {
            redirectToRoute = redirectRoute !== '' ? `/alumni/${redirect_user_id}/${redirectRoute}` : `/alumni/${redirect_user_id}/profile`;
            // navigate(redirectToRoute);
            // alert(redirectToRoute);
            state.actions['REDIRECT'](`${redirectToRoute}`);
        } else if (redirectRoute) {
            const action = getRoute(redirectRoute);
            console.log(action);
            if (action) {
                redirectToRoute = action;
            } else {
                redirectToRoute = `/management/${studentId}/${redirectRoute}`;
            }
        }
        state.actions['REDIRECT'](`${redirectToRoute}`);
    };

    const userRedirect = (user: any, redirectRoute: string) => {
        const userTypeCode = user?.userTypeCode;
        const studentId = user?.studentId;
        const userStatusCode = user?.userStatusCode;
        const profileStatusCode = user?.profileStatusCode;
        let routeToUrl;
        let userType = '';

        let menuType = getMenuType(user);

        // user.menuType = menuType;
        // user.tabType = menuType;
        //user.id = user?.studentId;
        sessionDispatch({ type: 'SET', payload: { ...user, menuType: menuType, tabType: menuType, id: studentId } });
        setSessionStorageAlumni(user);

        const NAVIGATION_MAP: any = {
            'ALUMNI-START': `/alumni/${studentId}/info`,
            'ALUMNI-STEP_ONE_COMPLETE': `/alumni/${studentId}/info`,
            'ALUMNI-STEP_TWO_COMPLETE': `/alumni/${studentId}/info`,
            'ALUMNI-COMPLETE': `/alumni/${studentId}/info`,
            'STUDENT-PREFERENCES_INCOMPLETE': `/student/${studentId}/info`,
            'STUDENT-PREFERENCES_COMPLETE': `/student/${studentId}/info`,
            SCHOOL_ADMIN: `/management/dashboard`,
            SUPPORT: ``,
        };

        if (userTypeCode === 'INSTITUTE_ADMIN') {
            userType = 'management';
        } else if (userTypeCode === 'ALUMNI') {
            userType = 'alumni';
        } else {
            userType = 'alumni';
        }

        if (redirectRoute != '') {
            const action = getRoute(redirectRoute);

            if (action) {
                redirectRoute = action;
            } else {
                redirectRoute = `/${userType}/${studentId}/${redirectRoute}`;
            }
        } else {
            if (userTypeCode === 'INSTITUTE_ADMIN') {
                redirectRoute = NAVIGATION_MAP[`SCHOOL_ADMIN`];
            } else if (userTypeCode === 'ALUMNI' && userStatusCode === 'ACTIVE') {
                redirectRoute = `/alumni/onboarding`; // or whatever your home route is
            } else if (
                userTypeCode === 'ALUMNI' &&
                (userStatusCode === 'PROFILE_IN_PROGRESS' || userStatusCode === 'PENDING_EMAIL_VERIFICATION') &&
                Object.values(AlumniProfileEditScreens).includes(profileStatusCode)
            ) {
                redirectRoute = `/alumni/${studentId}/${profileStatusCode}`; // or whatever your home route is
            } else if (
                userTypeCode === 'ALUMNI' &&
                (userStatusCode === 'PROFILE_IN_PROGRESS' || userStatusCode === 'PENDING_EMAIL_VERIFICATION') &&
                !Object.values(AlumniProfileEditScreens).includes(profileStatusCode)
            ) {
                redirectRoute = `/alumni/${studentId}/info`; // or whatever your home route is
            } else if (userTypeCode === 'STUDENT' && profileStatusCode === 'PREFERENCES_INCOMPLETE') {
                redirectRoute = `/alumni/${studentId}/student-preference`; // or whatever your home route is
            } else if (userTypeCode === 'STUDENT' && profileStatusCode === 'COMPLETE') {
                redirectRoute = `/alumni/${studentId}/profile`; // or whatever your home route is
            } else {
                redirectRoute = NAVIGATION_MAP[`${userTypeCode}-${profileStatusCode}`];
            }
        }

        state.actions['REDIRECT'](`${redirectRoute}`);
    };

    const storeLoginInfoInSessionStorage = async (user: any, schoolDomain: string) => {
        // sessionStorage.setItem('user-id', user.id);
        // sessionStorage.setItem('user-login-id', user.loginId ?? 448220);
        // sessionStorage.setItem('user-jwt-token', user.token);
        // sessionStorage.setItem('user-first-name', user.firstName);
        // sessionStorage.setItem('user-last-name', user.lastName);
        // sessionStorage.setItem('user-name', user.firstName + '' + user.lastName);
        // sessionStorage.setItem('user-email-id', user.registeredEmailId);
        // sessionStorage.setItem('user-school-id', user.schoolId);
        // sessionStorage.setItem('user-profile-status-code', user.profileStatusCode);
        // sessionStorage.setItem('user-status-code', user.userStatusCode);
        // sessionStorage.setItem('user-school-group-id', user.schoolGroupId);
        // sessionStorage.setItem('user-type-code', user.userTypeCode);
        // sessionStorage.setItem('pass-out-year', user.passOutYear ?? 2010);
        // sessionStorage.setItem('school-founding-year', user.schoolFoundingYear ?? 2000);
        // sessionStorage.setItem('is-peer-reviewed', user.isPeerReviewed ?? false);
        // sessionStorage.setItem('user-logged-out', 'FALSE');
        // sessionStorage.setItem('user-profile-image', user.profileImage);

        localStorage.setItem('user-logged-out', 'FALSE');
        localStorage.setItem('user-type-code', user.userTypeCode);
        localStorage.setItem('univariety-user', JSON.stringify(user));
        localStorage.setItem('user-school-domain', schoolDomain);
        localStorage.setItem('school-image', user?.logo);
        //  sessionStorage.setItem('school-image', user?.logo);
    };
    const getProductsTestMenuStudent = async (school_id: any, class_code: any) => {
        console.log(school_id, 'school_id');
        console.log(class_code, 'class_code');
        try {
            const response = await axios.post(`${process.env.REACT_APP_COMMON_API}/test/get-test-menu-by-schoolid_campuscode`, {
                SchoolId: school_id,
                ClassCode: class_code,
            });
            console.log(response, 'Test response');
            if (response.data.success) {
                sessionStorage.setItem('product-menu-test', JSON.stringify(response.data.data));
                console.log(response.data.data, 'Test Data');
            } else {
                console.error('Error:', response.data.message);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    return (
        <div className="main flex-1">
            <section className="notificationSection">
                <div className="container max-1140 px-lg-0">
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <div className="white-block white-block-notopborderradius h-100">
                                <div className="d-flex flex-wrap justify-content-between align-items-center mb-4 border-bottom custom-border-gray pb-3">
                                    <h3
                                        className="font-35 font-600 mb-0 block-title aos-init aos-animate"
                                        data-aos="fade-left"
                                        data-aos-delay="600">
                                        {verificationMessage}
                                    </h3>
                                </div>
                                <div className="max-940 mx-auto"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default AutoLogin;
