import React, { useContext } from 'react';
import moment from 'moment';
import SessionContext from '../../library/Core/SessionContext';
import { SmartContext } from '../../library/Core/SmartContext';
import useMessages from './useMessages';
import { axiosClient } from '../../library/Service/axiosClient';
interface Profile {
    id: string;
    userTypeCode: string;
    profilePictureFilePath?: string;
    firstName: string;
    lastName: string;
    salutationCode?: string;
    designation?: string;
    programName?: string;
    passOutYear?: string;
    schoolName?: string;
    companyName?: string;
}

interface Conversation {
    id: string;
    topicName?: string;
    typeCode?: string;
    memberInfoJson: string[];
    message: string;
    sentUserId: string;
    sentDate: string;
    isSeen: number;
    isStarred: number;
    isDeleted: number;
    userTypeCode: string;
}

const MessageLeftBar = (props: any) => {
    const { dispatch, state } = useContext(SmartContext);
    const { sessionState } = useContext(SessionContext);

    const memberProfiles: Profile[] | undefined = state.data?.memberProfiles;
    const conversations: Conversation[] | undefined = state.data?.conversations;
    const unreadMsgCount = state.data?.unreadMsgCount;
    const { getById } = useMessages();

    const handleSeenUnseen = async (conversationId: any, userId: any) => {
        // alert(conversationId);
        try {
            const response = axiosClient().get(
                `${process.env.REACT_APP_MESSAGE_SERVER}/conversations/mark-unread-msg/${conversationId}/${userId}`
            );
        } catch (error) {
            console.log(error);
        }
    };

    const handleConversationSelect = async (
        conversationId: string,
        name: string,
        imagePath: string | undefined,
        userType: string | undefined,
        isStarred: number | undefined,
        programName: string | undefined,
        schoolName: string | undefined,
        companyName: string | undefined,
        designation: string | undefined,
        uuid: string,
    ) => {
        // alert(conversationId);

        const result = await getById(conversationId);
        let countryName ='';
        let cityName ='';

        try {
            const response = (
               await axiosClient().get(`${process.env.REACT_APP_MESSAGE_SERVER}/conversations/get-country-city/${uuid}`)
            )
           if (response?.status === 200) {
               countryName   = response?.data[0][0].country || "Unknown";
               cityName = response?.data[0][0].city || "Unknown";
           }
       } catch (error) {
           console.error('Error Getting Country and State :', error);
       }

        // Clear memberIds array
        dispatch({
            type: 'CONTROL_VALUE_CHANGE',
            payload: { dataKey: 'memberIds', value: [] },
        });

        dispatch({
            type: 'CONTROL_VALUE_CHANGE',
            payload: { dataKey: 'selectedUserDetails', value: { userName: name, userImage: imagePath, usertype: userType, programName:programName,schoolName:schoolName, companyName:companyName, designation:designation, countryName:countryName, cityName:cityName } },
        });

        dispatch({
            type: 'CONTROL_VALUE_CHANGE',
            payload: { dataKey: 'conversationWithUser', value: result?.data },
        });

        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'messageMode', value: 'SHOW_CONVERSATION_WITH_USER' } });
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'starred_marked', value: isStarred } });
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'conversationIdPass', value: conversationId } });
    };

    // console.log('left' , props.activeTab);
    const formatFullName = (firstName: string, lastName: string) => {
        return `${firstName || ''} ${lastName || ''}`.trim();
    };

    // Filter conversations based on active tab
    let filteredConversations = conversations;
    if (props.activeTab === 'starred') {
        filteredConversations = conversations?.filter((conversation: Conversation) => {
            return conversation.isStarred === 1;
        });
    }

    const userTypeClassMap: { [key: string]: string } = {
        ALUMNI: 'text-alumni',
        STUDENT: 'text-student',
        INSTITUTE_ADMIN: 'text-school',
        INSTITUTE_SUPPORT_MANAGER: 'text-school',
        INSTITUTE_MARKETING_MANAGER: 'text-school',
        SCHOOL_ADMIN: 'text-school',
    };

    return (
        <div className={`col-md-auto ${(props.isActive || state?.data?.memberIds?.length > 0) ? 'd-none' : 'd-block'} d-md-block`}>
            <div className="alumni_chat_profile_list">
                <div className="heading">
                    <i className="icon_message d-block d-md-none"></i>
                    <span>Chat with your Network</span>
                </div>
                <ul>
                    {filteredConversations?.map((conversation: Conversation) => {
                        const conversationId = conversation.id;
                        let unreadMsgTotalCount = 0;
                        let liClassName = '';
                        for (const unreadMsg of unreadMsgCount || []) {
                            if (unreadMsg.conversationId === conversationId) {
                                unreadMsgTotalCount = unreadMsg.cnt;
                                liClassName = 'unread';
                                break;
                            }
                        }

                        const messageMembers = conversation?.memberInfoJson ?? [];
                        const userId = sessionState?.studentId ?? sessionState?.userId;
                        const toUserId = messageMembers?.find((member: any) => member !== userId);
                        const toUserProfile = memberProfiles?.find((member: any) => member?.id === toUserId);
                        const schoolAdminRoles = ['INSTITUTE_ADMIN', 'INSTITUTE_SUPPORT_MANAGER', 'INSTITUTE_MARKETING_MANAGER'];

                        if (!toUserProfile) return null;


                        //const isUnread = conversation.isSeen === 0;
                        // const liClassName = isUnread ? 'unread' : '';
                        // const liClassName = '';

                        return (
                            <li key={conversation.id} className={liClassName}>
                                <a
                                    href="javascript:;"
                                    onClick={() => {
                                        props?.toggleClass();
                                        handleConversationSelect(
                                            conversation.id,
                                            `${toUserProfile.firstName} ${toUserProfile.lastName}`,
                                            toUserProfile?.profilePictureFilePath,
                                            toUserProfile.userTypeCode,
                                            conversation.isStarred,
                                            toUserProfile.programName,
                                            toUserProfile.schoolName,
                                            toUserProfile.companyName,
                                            toUserProfile.designation,
                                            toUserProfile.id
                                        );
                                    }}>
                                    <span className="img-alumni">
                                        <img
                                            src={toUserProfile.profilePictureFilePath || 'default_image_path.png'}
                                            width="60"
                                            alt={`${toUserProfile.firstName} ${toUserProfile.lastName}`}
                                        />
                                    </span>
                                    <span className="alumni-name-batch">
                                        <span className="flex-1 text-dark-blue" onClick={() => handleSeenUnseen(conversation.id, userId)}>
                                            {schoolAdminRoles.includes(toUserProfile?.userTypeCode) &&
                                                (toUserProfile?.salutationCode ? toUserProfile?.salutationCode + ' ' : '')}
                                            {formatFullName(toUserProfile?.firstName, toUserProfile?.lastName)}
                                            {/* {conversation.conversationMemberId} */}
                                            {!schoolAdminRoles.includes(toUserProfile?.userTypeCode) && toUserProfile?.passOutYear && ', '}
                                            {!schoolAdminRoles.includes(toUserProfile?.userTypeCode) && (
                                                <>
                                                    {toUserProfile?.programName && (
                                                        <span className="font-14 d-block fst-italic">
                                                            {toUserProfile?.programName}
                                                            {toUserProfile?.passOutYear && `, ${toUserProfile?.passOutYear}`}
                                                        </span>
                                                    )}
                                                </>
                                            )}
                                            {schoolAdminRoles.includes(toUserProfile?.userTypeCode) && (
                                                <span className="font-14 d-block fst-italic">
                                                    {toUserProfile?.designation ? toUserProfile?.designation : ''}
                                                </span>
                                            )}
                                        </span>

                                        <span className={`font-14 ${userTypeClassMap[toUserProfile?.userTypeCode]}`}>
                                            {toUserProfile?.userTypeCode === 'ALUMNI'
                                                ? 'Alumni'
                                                : toUserProfile?.userTypeCode === 'STUDENT'
                                                ? 'Student'
                                                : 'School'}
                                        </span>
                                    </span>
                                </a>

                                {unreadMsgTotalCount > 0 && <div className="count-unread">{unreadMsgTotalCount}</div>}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default MessageLeftBar;
