import { useContext, useEffect, useState } from 'react';
// import plusVisual from '../../styles/images/add_plus.svg';
import { SmartContext } from '../Core/SmartContext';
import { getControlValueFromState, getUUIDs } from '../Core/SmartFunctions';
import { FormControl, SimpleFormControlArguments, State } from '../Core/SmartTypes';
import ErrorControl from './ErrorControl';
import SelectControl from './SelectControl';
import SelectEditable from './SelectEditable';
import TextControl from './TextControl';

const plusVisual = `${process.env.REACT_APP_IMAGE_BASEPATH}/add_plus.svg`;

const EntranceTestAndResultControl = (args: SimpleFormControlArguments) => {
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey, parentDataKey } = { ...args };
    const data = getControlValueFromState(parentDataKey as string, state as State);
    const [uuids] = useState(getUUIDs(20));

    useEffect(() => {
        // if (!data.length) addEmptyRecord();
        for (let i = data.length; i <= 2; i++) {
            addEmptyRecord();
        }
    }, []);

    const addEmptyRecord = () => {
        // const emptyRow = getDataStructureFromControls(control.controlGroup);
        dispatch({ type: 'ADD_NEW_RECORD_IN_ARRAY', payload: { dataKey: parentDataKey, value: {} } });
    };

    const handleAdd = (event: React.SyntheticEvent) => {
        event.preventDefault();
        addEmptyRecord();
    };

    const handleDelete = (index: number) => {
        dispatch({ type: 'DELETE_RECORD_IN_ARRAY', payload: { dataKey: dataKey, index } });
    };

    const entranceTestCodeControl = control.controlGroup.find((ctrl) => ctrl.id === 'entranceTestCode') as FormControl;
    const yearControl = control.controlGroup.find((ctrl) => ctrl.id === 'year') as FormControl;
    const scoreTypeCodeControl = control.controlGroup.find((ctrl) => ctrl.id === 'scoreTypeCode') as FormControl;
    const scoreControl = control.controlGroup.find((ctrl) => ctrl.id === 'score') as FormControl;

    return (
        <div className="row g-3">
            {data.map((row: any, index: number) => (
                <div key={`${control.id}-${row.id === undefined ? uuids[index] : row.id}`}>
                    <div className="col-12">
                        <div className="row g-3 position-relative">
                            <div className="col-lg-6 col-sm-6">
                                <SelectEditable
                                    control={entranceTestCodeControl}
                                    dataKey={parentDataKey + '.' + index + '.' + entranceTestCodeControl.id}
                                    parentDataKey={`${parentDataKey}.${index}`}
                                />
                            </div>
                            <div className="col-lg-6 col-sm-6">
                                <div className="input-group">
                                    <SelectControl
                                        control={yearControl}
                                        dataKey={parentDataKey + '.' + index + '.' + yearControl.id}
                                        parentDataKey={`${parentDataKey}.${index}`}
                                    />
                                    <SelectControl
                                        control={scoreTypeCodeControl}
                                        dataKey={parentDataKey + '.' + index + '.' + scoreTypeCodeControl.id}
                                        parentDataKey={`${parentDataKey}.${index}`}
                                    />
                                    <TextControl
                                        control={scoreControl}
                                        dataKey={parentDataKey + '.' + index + '.' + scoreControl.id}
                                        parentDataKey={`${parentDataKey}.${index}`}
                                    />
                                </div>
                            </div>
                            <div className="col-auto d-none align-items-center">
                                <span className="">
                                    <i className="bi bi-trash fs-6 text-danger" onClick={() => handleDelete(index)}></i>
                                </span>
                            </div>
                            {index > 0 && (
                                <a
                                    className="btn-delete"
                                    role="button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleDelete(index);
                                    }}></a>
                            )}
                        </div>
                    </div>
                    <ErrorControl errorMessages={state?.formValidationErrors[`${dataKey}.${index}`]} />
                </div>
            ))}

            <ErrorControl errorMessages={state?.formValidationErrors[`${dataKey}`]} />

            <div className="cta_add my-3">
                <button type="button" className="btn btn-link btn-add" onClick={handleAdd}>
                    <i>
                        <img src={plusVisual} alt="" />
                    </i>{' '}
                    Add More
                </button>
            </div>
        </div>
    );
};

export default EntranceTestAndResultControl;
