import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/css_pre_singup/style.css';
import { useParams, useLocation } from 'react-router-dom';
import { GlobalContext } from '../../library/Core/GlobalContext';
import SessionContext from '../../library/Core/SessionContext';

const ParentChildInfo: React.FC = () => {
    const { globalState } = useContext(GlobalContext);
    const [instName, setInstName] = useState<string>('');
    const { sessionState, sessionDispatch } = useContext(SessionContext);
    const { schoolDomain } = useParams<{ schoolDomain: string }>();
    const location = useLocation();

    const [schoolImage, setSchoolImage] = useState<string | null>(null);
    const [email, setEmail] = useState<string>('');
    const [errors, setErrors] = useState<{ email?: string }>({});

    // Extract id from the query string
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    
    const currentPathname = location.pathname;
    const pathParts = currentPathname.split('/');
    pathParts.pop();

    if (pathParts[1] === 'screening') {
        pathParts[1] = 'user';
    }
    const modifiedPathname = pathParts.join('/');
    const modifiedUrl = `${window.location.origin}${modifiedPathname}`;
    
    useEffect(() => {
        const fetchSchoolInfo = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_STUDENT_SERVER}/v1/auth/school-info/${schoolDomain}`);
                const logoUrl = response.data.institute.logo;
                setSchoolImage(logoUrl);
                localStorage.setItem('school-image', logoUrl);
                sessionStorage.setItem('school-image', logoUrl);
                const instName = response.data.institute.name;
                setInstName(instName);
            } catch (error) {
                console.error('Error fetching school info:', error);
            }
        };

        fetchSchoolInfo();
    }, [schoolDomain]);

    const handleSubmit = async (e?: React.FormEvent) => {
        if (e) e.preventDefault();
        
        const newErrors: { email?: string } = {};

        if (!email) {
            newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            newErrors.email = 'Email is invalid';
        }

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BROADCAST_SERVER}/pre-signup/save-data`, {
                    id: id,
                    email: email,
                    user_type: 1,
                    inst_name: instName,
                    logo: schoolImage,
                    signupUrl: modifiedUrl
                });
                const insertId = response.data.result[0].insertId;
                console.log('updated:', insertId);

                 window.location.href = 'thank-you';
            } catch (error) {
                console.error('Error submitting form:', error);
            }
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setEmail(value);
    };


    return (
        <main className="wrapper">
            <section className="dark-section student-img d-flex">
                <div className="container d-flex flex-column">
                    <div className="logo">
                        <a href="javascript:;" className="h-100"><img src={schoolImage || 'images/logo.svg'} className="img-fluid" alt="logo" /></a>
                    </div>
                    <div className="flex-1 py-5 d-flex flex-column justify-content-center align-items-start align-items-md-center text-white py-5 px-4">
                        <div className="w-1030">
                            <div className="form-title">Kindly share your child’s email address to streamline<br className="d-none d-md-block" /> communication and minimize phone calls.</div>
                            <div className="sep blue mx-0 mx-sm-auto"></div>
                            <div className="pb-5">
                                <form className="min-height-250" onSubmit={handleSubmit}>
                                    <div className="row flex-column g-4 align-items-center justify-content-center">
                                        <div className="col-12 col-md-6">
                                            <label htmlFor="email" className="form-label">Email*</label>
                                            <input
                                                type="email"
                                                className="form-control height-46 border-0"
                                                id="email"
                                                placeholder="To send some cool updates & offers for growth!"
                                                value={email}
                                                onChange={handleInputChange}
                                            />
                                            {errors.email && <div className="text-danger">{errors.email}</div>}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-center text-white pt-5 pb-3 d-none d-md-flex">
                        <div className="col-auto">Powered by</div>
                        <div className="col-auto"><img src="https://s3.ap-south-1.amazonaws.com/media.univariety.dev/images/univariety-white-logo.svg" width="167" alt="Univariety" /></div>
                    </div>
                </div>
            </section>
            <section className="white-section d-block d-md-none">
                <div className="container py-5">
                    <div className="text-center">Powered by</div>
                    <div className="text-center mb-2"><img src="https://s3.ap-south-1.amazonaws.com/media.univariety.dev/images/univariety-dark-logo.svg" width="167" alt="Univariety" /></div>
                </div>
            </section>
            <div className="navigation_footer_wrap">
                <div className="navigation_footer d-flex justify-content-end">
                    <a href="javascript:;" className="right-link btn-next" onClick={handleSubmit}><span>Next</span><i></i></a>
                </div>
            </div>

            {/* Modal */}
            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered w-860 mx-auto px-3">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="d-flex justify-content-end"><button type="button" className="btn-close position-absol" data-bs-dismiss="modal" aria-label="Close"></button></div>
                            <img src="images/letter.png" width="860" className="img-fluid" alt="letter" />
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default ParentChildInfo;
