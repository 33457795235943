import { isEmpty } from '../../../library/Core/SmartFunctions';
import { State } from '../../../library/Core/SmartTypes';
import { axiosClient } from '../../../library/Service/axiosClient';

export const handleInstituteSelection = async (
    institute: any,
    id: string
) => {
    const response = await axiosClient().post(`${process.env.REACT_APP_STUDENT_SERVER}/v1/auth/admin-token/${institute.instituteId}/${id}`, {});
    const tokenForSelectedInstitute = response?.data?.token;

    const navigateToUrl = navigateToLandingPage(response?.data?.selectedInstitute, id);

    // state.actions['ROUTER_NAVIGATE'](navigateToUrl);
    window.location.href = navigateToUrl;
    return;
};

const navigateToLandingPage = (institute: any, studentId: string) => {
    const { userStatusCode, userTypeCode, instituteTypeCode, profileStatusCode, genderCode, isPeerReviewed, presentEducationStatusCode, pursuingEducationLevelCode, highestCompletedEducationLevelCode } = institute;
    if ( ! isEmpty(genderCode) && ! isEmpty(presentEducationStatusCode) && ( ! isEmpty(pursuingEducationLevelCode) || ! isEmpty(highestCompletedEducationLevelCode) )) {
        localStorage.setItem('selectedInstitute', JSON.stringify(institute));
        return `/alumni/${studentId}/outcome`;
    } else {
        alert('Please fill the basic info');
        return `/alumni/${studentId}/info`;
    }
};