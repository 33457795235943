import React, { useState } from 'react';

interface TemplatePreviewModalProps {
  previewUrl: string | null;
}




const TemplatePreviewModal: React.FC<TemplatePreviewModalProps> = ({ previewUrl }) => {

  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };

  return (
    <div className="modal fade" id="template-preview" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog modal-fullscreen modal-dialog-scrollable">
        <div className="modal-content bg-transparent">
          <div className="modal-header bg-white">
            <h5 className="modal-title h4" id="exampleModalFullscreenLabel">Template Preview</h5>
            <div className="togglelinks">
              <a className={`btn-desktop ${isActive ? '' : 'active'}`}   onClick={toggleClass} href="javascript:;"><i></i>Desktop</a>
              <a className={`btn-mobile ${isActive ? 'active' : null}`} onClick={toggleClass} href="javascript:;"><i></i>Mobile</a>
            </div>
            <button type="button" className="btn-close ms-0" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body d-flex justify-content-center p-0">
            <iframe className={`iframe-preview ${isActive ? 'iframe-mobile' : ''}`} width="100%" frameBorder="0" scrolling="no" src={previewUrl || ''}></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplatePreviewModal;
