import { useContext, useEffect, useRef, useState, FormEvent } from 'react';
import { useLoaderData, useLocation, useNavigate, useParams } from 'react-router-dom';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
// import whiteLogo from '../../../styles/images/univariety-white-logo.svg';
import axios from 'axios';
import './styles.css';
import SessionContext from '../../../library/Core/SessionContext';
import { useImmerReducer } from 'use-immer';
import smartReducer from '../../../library/Core/SmartReducer';

const whiteLogo = `${process.env.REACT_APP_IMAGE_BASEPATH}/univariety-white-logo.svg`;

// Assuming you have a login function defined elsewhere
const login = async (username: string, password: string) => {
    const data = { schoolId: -1, provideUpdatesOnWhatsapp: true, registeredEmailId: username, password: password };
    const response = await axios.post(`${process.env.REACT_APP_STUDENT_SERVER}/v1/auth/admin-login`, data);

    const user = response.data as any;
    if (!user.token) {
        return user;
    } else {
       storeLoginInfoInSessionStorage(user, user?.schoolDomain);
        return user;
    }
};

export const storeLoginInfoInSessionStorage = (user: any, schoolDomain: string) => {
    sessionStorage.setItem('user-id', user.id);
    sessionStorage.setItem('user-login-id', user.loginId ?? 448220);
    sessionStorage.setItem('user-jwt-token', user.token);
    sessionStorage.setItem('user-first-name', user.firstName);
    sessionStorage.setItem('user-last-name', user.lastName);
    sessionStorage.setItem('user-name', user.firstName + '' + user.lastName);
    sessionStorage.setItem('user-email-id', user.registeredEmailId);
    sessionStorage.setItem('user-school-id', user.schoolId);
    sessionStorage.setItem('user-profile-status-code', user.profileStatusCode);
    sessionStorage.setItem('user-status-code', user.userStatusCode);
    sessionStorage.setItem('user-school-group-id', user.schoolGroupId);
    sessionStorage.setItem('user-type-code', user.userTypeCode);
    sessionStorage.setItem('pass-out-year', user.passOutYear ?? 2010);
    sessionStorage.setItem('school-founding-year', user.schoolFoundingYear ?? 2000);
    sessionStorage.setItem('is-peer-reviewed', user.isPeerReviewed ?? false);
    sessionStorage.setItem('user-logged-out', 'FALSE');
    sessionStorage.setItem('user-profile-image', user.profileImage);
    sessionStorage.setItem('user-hash', user.userHash);

    localStorage.setItem('user-logged-out', 'FALSE');
    localStorage.setItem('user-type-code', user.userTypeCode);
    localStorage.setItem('univariety-user', JSON.stringify(user));
    localStorage.setItem('user-school-domain', schoolDomain);
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('user-jwt-token', user.token);

    if (user.userTypeCode == 'STUDENT') {
        sessionStorage.setItem('user-class', user.classCode);
        sessionStorage.setItem('user-board-ref-id', user.curriculumCode);
    }
};

const SignupLayout = (props: any) => {
    const navigate = useNavigate();
    const [redirectUrl, setRedirectUrl] = useState('');
    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: { ROUTER_NAVIGATE: navigate,REDIRECT: setRedirectUrl },
    } as any);

    const [formData, setFormData] = useState({ username: '', password: '' });
  
    const { sessionState, sessionDispatch } = useContext(SessionContext);

    useEffect(() => {
        if (redirectUrl !== '') {
            navigate(redirectUrl);
        }
    }, [redirectUrl]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault(); // Prevent the default form submission

        try {
            let loginstatus = await login(formData.username, formData.password);
            // If login is successful, you can navigate or update the user state
            //console.log(loginstatus);

            if (!loginstatus.token) {
                alert(loginstatus.message);
            } else {
                if (loginstatus.userTypeCode == 'SUPER_ADMIN') {
                    sessionStorage.setItem('user', JSON.stringify(loginstatus));
                    sessionStorage.setItem('user-jwt-token', loginstatus?.token);
                    sessionDispatch({ type: 'SET', payload: loginstatus });
                    // navigate('/admin/dashboard');
                    state.actions['REDIRECT']('/admin/dashboard');
                } else {
                    alert('Not a admin user');
                }
            }
        } catch (error) {
            // Handle login failure, display an error message, etc.
            console.error('Login failed:', error);
        }
    };

    return (
        <div className="register_wrap bg-darkblue">
            <div className="register_sec max-464 d-flex flex-1 align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="login_wrap">
                            <div className="login_form">
                                <div className="mb-4 d-flex justify-content-center">
                                    <a href="javascript:;" className="logo_4_full_page_login">
                                        <img src={whiteLogo} style={{ width: '180px' }} alt="" />
                                    </a>
                                </div>
                                <div className="login_wrapper full_page_login">
                                    <h2 className="font-20 mb-3">Existing User</h2>
                                    <div className="tab-content d-inline-block w-100" id="pills-tabContent">
                                        <div
                                            className="tab-pane fade show active"
                                            id="pills-existing-user"
                                            role="tabpanel"
                                            aria-labelledby="pills-existing-user-tab">
                                            <form
                                                data-aos="fade-up"
                                                data-aos-delay="900"
                                                className="needs-validation"
                                                onSubmit={handleSubmit}>
                                                <div className="row g-4">
                                                    <div className="col-12">
                                                        <input
                                                            type="text"
                                                            id="username"
                                                            name="username"
                                                            value={formData.username}
                                                            onChange={handleInputChange}
                                                            className="form-control email"
                                                            placeholder="Email"
                                                            aria-describedby="emailHelp"
                                                        />
                                                        <div className="invalid-feedback">Enter valid email address</div>
                                                    </div>
                                                    <div className="col-12 ">
                                                        <div className="view_pw">
                                                            <input
                                                                type="password"
                                                                id="password"
                                                                name="password"
                                                                value={formData.password}
                                                                onChange={handleInputChange}
                                                                className="form-control password"
                                                                placeholder="Password"
                                                                aria-describedby="passwordHelp"
                                                            />
                                                            {/* <a className="btn">
                                                        <img className="fview_strike_icn" src={hidepassword} alt="" />
                                                        <img className="fview_icn" src={showpassword} alt="" />
                                                    </a> */}
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-md-12 mt-1">
                                                    <div className="form-group text-start">
                                                        <a href="javascript:;" className="text-black font-14 font-400 cursor-pointer text-decoration-none" >Forgot Your Password?</a>
                                                    </div>
                                                </div> */}
                                                    <div className="col-12  form_submissions">
                                                        <button className="btn btn-green px-5" type="submit">
                                                            <span>Log In</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="fpw_wrapper d-none">
                                    <h2 className="text-back font-18 font-500 mb-3">Forgot Your Password?</h2>
                                    <form
                                        action="forgot-password-otp-varify.html"
                                        data-aos="fade-up"
                                        data-aos-delay="900"
                                        className="needs-validation">
                                        <div className="form-group mb-3">
                                            <input
                                                type="email"
                                                className="form-control email"
                                                id="InputEmail3"
                                                placeholder="Email"
                                                aria-describedby="emailHelp"
                                            />
                                            <div className="invalid-feedback">Enter valid email address</div>
                                        </div>
                                        <div className="form_submissions">
                                            <button className="btn btn-green px-5" type="submit">
                                                Submit
                                            </button>
                                            {/* <!-- <a href="login-otp.html">Login with OTP</a> --> */}
                                        </div>
                                    </form>
                                    <div className="login_social_media pb-4" data-aos="fade-up" data-aos-delay="1300">
                                        <p className="mt-3 text-center">
                                            <a href="javascript:;" className="font-14 font-600 text-black-50 login_link">
                                                Return to Login
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-100 d-flex justify-content-center border-top-7b px-4 px-xl-0 ">
                <div className="login_footer flex-column flex-sm-row max-1140 mx-auto text-white line-height-16 position-static pt-3">
                    <div className="text-center text-sm-start">
                        <h6>International Educational Gateway Pvt. Ltd. Â© 2023</h6>
                        <p>All Rights Reserved . Univariety</p>
                    </div>
                    <div className="powerby">
                        <span>Powered By </span>
                        <img src={whiteLogo} alt="Univariety" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignupLayout;
