import { useContext, useEffect, useState } from 'react';
import { SmartContext } from '../../../library/Core/SmartContext';
import useManageAlumni from '../../broadcast/student/useManageAlumni';
import ManageEventService from './ManageEventService';
import SearchCriteria from './SearchCriteria';
import { useNavigate } from 'react-router';
// import ImgAddIcon from '../../../styles/images/add.svg';

const ImgAddIcon = `${process.env.REACT_APP_IMAGE_BASEPATH}/add.svg`;


const Layout = (props: any) => {
    const { state, dispatch } = useContext(SmartContext);
    const { setStateValue } = useManageAlumni();
    const [fuzzySearchText, setFuzzySearchText] = useState('');
    const [fuzzySearchStatus, setFuzzySearchStatus] = useState('');
    const [showSearchCriteria, setShowSearchCriteria] = useState(true);
    const navigate = useNavigate();
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    
    const handleFuzzySearch = () => {
        if (!isInitialLoad) {
            const searchCriteria = {
                ...state.data.searchCriteria,
                name: fuzzySearchText,
                status: fuzzySearchStatus
            };
            ManageEventService.search(searchCriteria, state.routeInfo.pageName).then((response: any) =>
                setStateValue('eventList', response.data)
                // setVisibleEvent(response.data)
            );
        }
    };

    const handleOnChange = (event: any) => {
        //alert(event.target.value)
        setFuzzySearchText(event.target.value);
    };

    const handleOnSlect = (event: any) => {        
        setFuzzySearchStatus(event.target.value);
    };

    useEffect(() => {
        setIsInitialLoad(false); 
        handleFuzzySearch();
    }, []);

    useEffect(() => {
        handleFuzzySearch(); 
    }, [fuzzySearchText]);

    useEffect(() => {
        handleFuzzySearch(); 
    }, [fuzzySearchStatus]);

    
    useEffect(() => {
        // setFuzzySearchText('');
    }, [state.data.searchCriteria]);

     // Function to handle the clear/reset button
    const handleClear = () => {
        setFuzzySearchText(''); // Clear the text input
        setFuzzySearchStatus(''); // Reset the select field to default
    };


    return (
        <div className="main flex-1">
            <section className="updateadditionalinformation">
                <div className="container max-1140 px-lg-0 overflow-hidden">
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <div className="white-block white-block-notopborderradius p-0 h-100">
                                <div className="p-4 p-md-5">
                                    <div className="alumni_statistics">
                                    <h2 className="font-26 font-600 mb-0 pb-3">{state.formConfig?.title}</h2>
                                    <div className='row g-2 mb-3'>
                                        <div className='col-md'>
                                            <div className='row g-2 align-items-center'>
                                                <div className='col-md-auto col-sm-6'>
                                                    <div className="search alumniSearch m-0 open position-static flex-1 me-2">
                                                        <div className="form-inline d-flex flex-wrap justify-content-between d-block">
                                                            <input
                                                                value={fuzzySearchText}
                                                                placeholder="Search by Event Title"
                                                                aria-label="Search"
                                                                className="form-control flex-1 font-13 font-500 text-truncate"
                                                                onChange={handleOnChange}
                                                            />
                                                            <button
                                                                className="btn btn-search icon icon-search"
                                                                onClick={handleFuzzySearch}></button>
                                                        </div>
                                                        {/* <a href="" className="btn-search-toggle icon-search"></a> */}
                                                    </div>
                                                </div>
                                                <div className='col-md-auto col-sm-auto'>
                                                    <div className='form-inline d-flex flex-wrap justify-content-between d-block'>
                                                        <select 
                                                        id="registrationsActive" 
                                                        value={fuzzySearchStatus}
                                                        className="form-select radius-25"
                                                        onChange={handleOnSlect}
                                                        >
                                                            <option value="">Status</option>
                                                            <option value="1">Active</option>
                                                            <option value="0">Inactive</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='col-md-auto col-sm-auto'>
                                                    {/* <a href=""><i className=''></i> Clear</a> */}
                                                    <button type="button" className="btn-clearBtn" onClick={handleClear}>
                                                        Clear
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-auto'>                                            
                                            <div className="dashboard innerpage smart-scroll scrolled-down">                                                
                                                <a href="javascript:;" onClick={() => {navigate('/event/addnew')}} className="btn btn-green height-40 d-inline-flex text-white font-14 px-3 m-0 text-decoration-none"><span className="d-flex align-items-center"><img src={ImgAddIcon} className="me-2" alt=""/>Add Event</span></a>
                                            </div>
                                        </div>
                                    </div>
                                     
                                        
                                        {showSearchCriteria && <SearchCriteria />}
                                        {props.children}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Layout;
