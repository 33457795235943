import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/css_pre_singup/style.css';
import { GlobalContext } from '../../library/Core/GlobalContext';
import SessionContext from '../../library/Core/SessionContext';

const ParentForm: React.FC = () => {
    const { globalState } = useContext(GlobalContext);
    const { sessionState, sessionDispatch } = useContext(SessionContext);
    const { schoolDomain } = useParams<{ schoolDomain: string }>();

    const [schoolImage, setSchoolImage] = useState<string | null>(null);
    const [fileUrl, setFileUrl] = useState<string | null>(null); // State to store file URL

    useEffect(() => {
        const fetchSchoolInfo = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_STUDENT_SERVER}/v1/auth/school-info/${schoolDomain}`);
                const logoUrl = response.data.institute.logo;
                setSchoolImage(logoUrl);
                localStorage.setItem('school-image', logoUrl);
                sessionStorage.setItem('school-image', logoUrl);
            } catch (error) {
                console.error('Error fetching school info:', error);
            }
        };

        const fetchFileUrl = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BROADCAST_SERVER}/pre-signup/get-file`, {
                    domain: schoolDomain,
                });
                if (response.data.success) {
                    setFileUrl(response.data.result);
                }
            } catch (error) {
                console.error('Error fetching file URL:', error);
            }
        };

        fetchSchoolInfo();
        fetchFileUrl();
    }, [schoolDomain]);

    return (
        <main className="wrapper d-flex">
            <section className="container-fluid p-0 flex-1 d-flex">
                <div className="row g-0 flex-1">
                    <div className="col-lg-8 bg-grd-blue dark-section">
                        <div className="container d-flex flex-column h-100">
                            <div className="logo">
                                <a href="#" className="h-100"><img src={schoolImage || 'images/logo.svg'} className="img-fluid" alt="logo" /></a>
                            </div>
                            <div className="flex-1 py-5 d-flex flex-column justify-content-center align-items-center text-white py-5 px-4">
                                <div className="w-800 p-sm-5">
                                    <div className="row g-3 g-sm-5 justify-content-center">
                                        <div className="col-auto">
                                            <div className="d-flex flex-column align-items-center justify-content-center">
                                                <div className="header-icon px-lg-5">
                                                    <img src="https://s3.ap-south-1.amazonaws.com/media.univariety.dev/images/layer-user.svg" className="position-relative img-fluid" width="90" alt="user icon" />
                                                </div>
                                                <div className="font-20 text-center font-600">One<br className="d-md-none" /> Community</div>
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <div className="d-flex flex-column align-items-center justify-content-center">
                                                <div className="header-icon px-lg-5">
                                                    <img src="https://s3.ap-south-1.amazonaws.com/media.univariety.dev/images/gift.svg" className="position-relative img-fluid" width="90" alt="gift icon" />
                                                </div>
                                                <div className="font-20 text-center font-600">Multiple<br className="d-md-none" /> Benefits</div>
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <div className="d-flex flex-column align-items-center justify-content-center">
                                                <div className="header-icon px-lg-5">
                                                    <img src="https://s3.ap-south-1.amazonaws.com/media.univariety.dev/images/free.svg" className="position-relative img-fluid" width="90" alt="free icon" />
                                                </div>
                                                <div className="font-20 text-center font-600">Free<br className="d-md-none" /> of Cost</div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <ul className="list">
                                                <li>Network with Batchmates & Teachers</li>
                                                <li>Get Featured on the School’s Website & Social Media</li>
                                                <li>Attend Learning Events & School Reunions</li>
                                                <li>Avail Discounts on Upskilling Courses</li>
                                                <li>Mentor Juniors</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 bg-white">
                        <div className="container py-5 d-flex flex-column align-items-center justify-content-center h-100">
                            <div className="text-center mb-2"><img src="https://s3.ap-south-1.amazonaws.com/media.univariety.dev/images/univariety-dark-logo.svg" width="285" alt="Univariety logo" /></div>
                            <p className="font-20 font-600 text-dark-gray text-center mb-4">
                                HAS BEEN OFFICIALLY<br className="d-none d-md-block" />
                                appointed by the school<br className="d-none d-md-block" />
                                to build this community
                            </p>
                            <div className="principal-wrap">
                                <div className="arrow-img"><img src="https://s3.ap-south-1.amazonaws.com/media.univariety.dev/images/arrow.svg" width="35" alt="arrow" /></div>
                                <a
                                    href={fileUrl || '#'} // Use the file URL if available
                                    target="_blank" // Open in a new tab
                                    rel="noopener noreferrer" // Ensure security
                                    className="btn btn-green"
                                    onClick={() => {
                                        if (!fileUrl) {
                                            alert('File not available');
                                        }
                                    }}
                                >
                                    <i className="icon-file me-2">
                                        <img src="https://s3.ap-south-1.amazonaws.com/media.univariety.dev/images/file.svg" width="18" alt="file icon" />
                                    </i>
                                    <span className="font-18 font-600">View Principal's Letter</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="navigation_footer_wrap homePageLink">
                <div className="navigation_footer">
                    <a href="signup" className="left-link btn-back"><i></i><span>Back</span></a>
                    <a href="identfyuser" className="right-link btn-next"><span>Next</span><i></i></a>
                </div>
            </div>

            {/* Modal */}
            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered w-860 mx-auto px-3">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="d-flex justify-content-end">
                                <button type="button" className="btn-close position-absol" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <img src="https://s3.ap-south-1.amazonaws.com/media.univariety.dev/images/letter.png" width="860" className="img-fluid" alt="letter" />
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default ParentForm;
