import { useContext, useEffect, useRef, useState } from 'react';
// import darkLogo from '../../../styles/images/dark-logo.svg';
import LoginCarouselControl from './LoginCarouselControl';
// import './styles.css';
import { useLocation, useParams } from 'react-router-dom';
import { SmartContext } from '../../../library/Core/SmartContext';
import { initNextBtnClickOnEnterEvent } from '../../../library/Core/SmartFunctions';
import ForgotPassword from './ForgotPassword';
// import './form_css.css';

const darkLogo = `${process.env.REACT_APP_IMAGE_BASEPATH}/dark-logo.svg`;

declare global {
    interface Window {
        YT: any; // Add this declaration for the YT property
    }
}

const LoginLayout = (props: any) => {
    const { schoolDomain } = useParams();
    const location = useLocation();
    const schoolSettings = localStorage.getItem('school_settings');
    const parsedSchoolSettings = schoolSettings ? JSON.parse(schoolSettings) : null;
    const schoolVideoId = parsedSchoolSettings?.youtubeId ?? '';

    const pageName = location.pathname.includes('signup') ? 'signup' : 'login';
    const { state, dispatch } = useContext(SmartContext);
    let schoolLogo = JSON.parse(localStorage.getItem('school_settings') as any);
    const [forgotPasswordLayout, setForgotPasswordLayout] = useState(false);
    const [showForgotPassEmailWindow, setShowForgotPassEmailWindow] = useState(true);
    let schoolImage = sessionStorage.getItem('school-image') as any;
    const [isVideoModalOpen, setVideoModalOpen] = useState(false);
    let player: any = null;
    const playerRef = useRef<any>(null);

    const onYouTubeIframeAPIReady = () => {
        if (playerRef.current === null) {
            playerRef.current = new window.YT.Player('youtube-player', {
                videoId: `${schoolVideoId}`,
                events: {
                    onReady: onPlayerReady,
                },
            });
        }
    };
    const backtoLogin = () => {
        setForgotPasswordLayout(false);
        setShowForgotPassEmailWindow(false);
    };
    const onPlayerReady = (event: any) => {
        event.target.playVideo();
    };

    const openVideoModal = () => {
        setVideoModalOpen(true);
        if (window.YT && typeof window.YT.Player !== 'undefined') {
            if (player === null) {
                onYouTubeIframeAPIReady();
            } else {
                player.playVideo();
            }
        }
    };

    const closeVideoModal = () => {
        setVideoModalOpen(false);
        if (playerRef.current && playerRef.current.destroy) {
            playerRef.current.destroy();
            playerRef.current = null;
        }
    };

    useEffect(() => {
        initNextBtnClickOnEnterEvent(); // @AGS-586 @dt 19th.Sep,2K23 @by RG;

        const script = document.createElement('script');
        script.src = 'https://www.youtube.com/iframe_api';
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const handleForgotPasswordClick = () => {
        setForgotPasswordLayout(true);
        setShowForgotPassEmailWindow(true);
    };

    const handleExistingUserClick = () => {
        setForgotPasswordLayout(false);
        setShowForgotPassEmailWindow(true);
    };

    const handleNewUserClick = () => {
        setForgotPasswordLayout(false);
        setShowForgotPassEmailWindow(false);
    };
    
    return (
        <div className="container-fluid bg_side_blue landing_login_page d-flex flex-1">
            <div className="container max-1300 flex-1">
                <div className="row h-100 position-relative z1">
                    <div className="col-md-6 d-none d-md-flex flex-column">
                        <div className="custom_logo text-center">
                            <img src={schoolImage} width="200" className="img-fluid" />
                        </div>
                        <div className="leftPaneMain">
                            <LoginCarouselControl />
                            {schoolVideoId != '' && (
                                <button className="btn-View-appeal mt-3 video-btn" onClick={openVideoModal}>
                                    <i></i>
                                    <span>View appeal from the Principal</span>
                                </button>
                            )}
                        </div>
                        <div className="leftPaneFooter">
                            <div className="row g-4 align-items-center">
                                <div className="col-md">
                                    <div className="copyRight">
                                        International Educational Gateway Pvt. Ltd. © 2023
                                        <span>All Rights Reserved . Univariety</span>
                                    </div>
                                </div>
                                <div className="col-md-auto">
                                    <div className="powerby">
                                        <span>Powered By</span>
                                        <img src={darkLogo} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="signup h-100" aria-autocomplete="none">
                            <div className="custom_logo text-center d-block d-md-none">
                                <img src={schoolImage} width="200" className="img-fluid" />
                            </div>

                            <div className="login_social_media" >
                                       
                                        <label>Log in with social network</label>
                                        <ul className='flex-column'>
                                            {/* <li>
                                                <a href="#"><img src="https://s3.ap-south-1.amazonaws.com/media.univariety.dev/images/facebook2.svg"/></a>
                                            </li> */}
                                            <li className='mb-3'>
                                                <a className='google' href={`https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_APP_BASE_URL}/handle-google-login&response_type=code&scope=openid%20email%20profile&access_type=offline`}>
                                                <img className='me-2' width={26} src="https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/images/google-plus.svg"/>
                                                <span>Sign in With Google</span>
                                                </a>
                                            </li>
                                             <li>
                                                <a className='linkedin'
                                                href={`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_APP_BASE_URL}/handle-linkedin-login&state=uuid_123&scope=r_liteprofile%20r_emailaddress`}>
                                                <img className='me-2' width={22} src="https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/images/linkedin.svg" />
                                                <span>Sign in With LinkedIn</span>
                                            </a>
                                            </li> 
                                        </ul>
                                        {pageName == 'signup' && <p className='mb-4'>Already have an account?  <a href={`/user/${schoolDomain}/login`} >Log In</a></p>}
                                        {pageName == 'login' && <p className='mb-4'>Don’t have an account?  <a href={`/user/${schoolDomain}/signup`} >Sign Up</a></p>}

                                        <span className="or mb-0"><em>or</em></span>
                                    </div>
                            
                            {/* {forgotPasswordLayout == false && (
                                <ul className="col-12 nav nav-tabs px-0 px-sm-3 px-md-5" id="myloginTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className={`nav-link ${props.mode === 'login' ? 'active' : ''}`}
                                            id="existing-user-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#existing-user"
                                            type="button"
                                            role="tab"
                                            aria-controls="existing-user"
                                            aria-selected="false"
                                            onClick={props.handleExistingUser}>
                                            Existing User
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className={`nav-link ${props.mode === 'signUp' ? 'active' : ''}`}
                                            id="new-user-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#new-user"
                                            type="button"
                                            role="tab"
                                            aria-controls="new-user"
                                            aria-selected="true"
                                            onClick={props.handleNewUser}>
                                            New User
                                        </button>
                                    </li>
                                </ul>
                            )} */}

                            

                            <div className="col-12 tab-content px-0 px-sm-3 px-md-5" id="myloginTabContent">
                                {state?.businessValidationErrors?.length > 0 ? (
                                    <div
                                        className={`bg ${state?.businessValidationErrors[0].success ? 'success-message' : 'fail-message'}`}>
                                        {state?.businessValidationErrors?.map((item: any) => (
                                            <p key={item.message}>{item.message != '0' && item.message}</p>
                                        ))}
                                    </div>
                                ) : null}
                                {!forgotPasswordLayout ? (
                                    props.children
                                ) : (
                                    <ForgotPassword showForgotPassEmailWindow={showForgotPassEmailWindow} />
                                )}

                                {/* <p>
                                    Don't have an account? <Link to={`/alumni/${schoolDomain}/signup`}>Sign Up</Link>
                                    <button onClick={handleForgotPasswordClick}>Sign Up</button>
                                </p> */}
                                <div className="action_btns">
                                    {forgotPasswordLayout == false && (
                                        <>
                                            <label className="btn-forgotpw">
                                                Forgot Password?{' '}
                                                <a href="#" role="button" onClick={handleForgotPasswordClick}>
                                                    click here
                                                </a>
                                            </label>
                                        </>
                                    )}

                                    {forgotPasswordLayout == true && (
                                        <>
                                            <a className="btn-relogin" href="" onClick={backtoLogin}>
                                                Return to login?
                                            </a>
                                        </>
                                    )}
                                </div>
                                
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                id="video_pop"
                className={`modal fade show ${isVideoModalOpen ? 'show' : ''}`}
                tabIndex={-1}
                role="dialog"
                style={{ display: isVideoModalOpen ? 'block' : 'none' }}>
                <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body p-0">
                            <button type="button" className="btn-close" onClick={closeVideoModal}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div className="ratio ratio-16x9">
                                <div id="youtube-player"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginLayout;