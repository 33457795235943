import React, { useContext, useState, useEffect } from 'react';
import { SmartContext } from '../../library/Core/SmartContext';
import useMessages from './useMessages';
import { isEmpty } from '../../library/Core/SmartFunctions';
import MessagesService from './MessageApiService';
import axios from 'axios';

interface Conversation {
    id: string;
    topicName?: string;
    typeCode?: string;
    memberInfoJson: string[];
    message: string;
    sentUserId: string;
    sentDate: string;
    isSeen: number;
    isStarred: number;
    isDeleted: number;
    userTypeCode: string;
}

interface MemberProfile {
    id: string;
    firstName: string;
    lastName: string;
    userTypeCode: string;
    designation?: string;
    salutationCode?: string;
    programId?: number;
    programName?: string;
    schoolName?: string;
    passOutYear?: number;
    companyName?: string;
    collegeIconFilePath?: string;
    schoolId: number;
    profilePictureFilePath: string;
}

const OneToOneChatBar = ({
    newMessage,
    setNewMessage,
    loggedInUser,
}: {
    newMessage: string;
    setNewMessage: React.Dispatch<React.SetStateAction<string>>;
    loggedInUser: any;
}) => {
    const { dispatch, state } = useContext(SmartContext);
    const [enableTextForStudent, setEnableTextForStudent] = useState('none');
    const [messages, setMessages] = useState([]);
    const [conversations, setConversations] = useState<Conversation[]>([]);
    const [memberProfiles, setMemberProfiles] = useState<MemberProfile[]>([]);
    const { sendMessage, startFreshConversion, getById } = useMessages();

    useEffect(() => {
        setMessages(state?.data?.conversationWithUser ?? []);
    }, [state?.data?.conversationWithUser]);

    const handleTypedMessageChange = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSendMessage();
        }
    };

    useEffect(() => {
        const { userTypeCode } = loggedInUser || {};
        const { messageMode } = state.data || {};

        if (userTypeCode !== 'STUDENT' || (userTypeCode === 'STUDENT' && (messageMode === 'SHOW_CONVERSATION_WITH_USER' || (messageMode === 'NEW_MESSAGE' && newMessage.length > 0)))) {
            setEnableTextForStudent('block');
        } else {
            setEnableTextForStudent('none');
        }
    }, [loggedInUser, state.data?.messageMode, newMessage]);

    const handleSendMessage = async() => {
        if (newMessage.trim() === '') return;
        const selectedConversationId = state?.data?.selectedConversationId;

        // It will trigger once alumni login
        if (loggedInUser.userTypeCode === 'ALUMNI') {
            let skip = 0;
            let studentId = 0;
            let promptText = '';
            let answerText = '';

            state?.data?.selectedConversation?.forEach((selectedConversation:any) => {
                if (selectedConversation.sentUserId === loggedInUser.studentId) {
                    skip = 1;
                    return; // Use 'return' to exit the forEach loop
                }
                studentId = selectedConversation.sentUserId;
                promptText = selectedConversation.message;
                
            });
        
            // Api hit
            if (skip === 0) {
                // Moengage trigger
                loggedInUser.senderstudentId = studentId;
                loggedInUser.selectedConversationId = selectedConversationId;
                loggedInUser.promptText = promptText;
                loggedInUser.answerText = newMessage.trim();
            
                try {
                    // Moengage API call
                    const moengageResponse = await axios.post(
                        `${process.env.REACT_APP_MOENGAGE_API}/moengage/moengage-alumni-student-answer`, 
                        { userInfo: loggedInUser }
                    );
            
                    // Email API call
                    const emailResponse = await axios.post(
                        `${process.env.REACT_APP_MOENGAGE_API}/moengage/moengage-alumni-student-answer-send-email`, 
                        { userInfo: loggedInUser }
                    );
            
                    console.log('Moengage response:', moengageResponse.data);
                    console.log('Email response:', emailResponse.data);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }            

        }

        if (isEmpty(selectedConversationId)) {
            startFreshConversion(newMessage);
        } else {
            sendMessage(newMessage);
        }

        setNewMessage('');

        const memberIdUuid = state?.data?.memberIds?.[0]?.uuid;
        if (memberIdUuid) {
            handleConversationCall(memberIdUuid);
        } else {
            console.log('No valid memberId UUID available for handleConversationCall.');
        }
    };

    const handleConversationCall = async (conversationId: string) => {
        try {
            
            const response = await MessagesService.get(loggedInUser?.userTypeCode || '');
            const data = response.data;
            const conversations: Conversation[] = data.conversations || [];
            const memberProfiles: MemberProfile[] = data.memberProfiles || [];

            const targetUuid = conversationId;

            const processedConversations = data.conversations
                .filter((conversation: Conversation) => conversation.memberInfoJson.includes(targetUuid))
                .map((conversation: Conversation) => {
                    const sentUserProfile = data.memberProfiles.find((profile: MemberProfile) => profile.id === conversation.sentUserId);
                    const memberProfileUuid = conversation.memberInfoJson[0];
                    const memberProfile = data.memberProfiles.find((profile: MemberProfile) => profile.id === memberProfileUuid);

                    return {
                        ...conversation,
                        sentUserProfile: sentUserProfile ? {
                            id: sentUserProfile.id,
                            firstName: sentUserProfile.firstName,
                            lastName: sentUserProfile.lastName,
                            userTypeCode: sentUserProfile.userTypeCode,
                            designation: sentUserProfile.designation,
                            programName: sentUserProfile.programName,
                            schoolName: sentUserProfile.schoolName,
                            passOutYear: sentUserProfile.passOutYear,
                            profilePictureFilePath: sentUserProfile.profilePictureFilePath
                        } : null,
                        memberProfile: memberProfile ? {
                            id: memberProfile.id,
                            firstName: memberProfile.firstName,
                            lastName: memberProfile.lastName,
                            userTypeCode: memberProfile.userTypeCode,
                            designation: memberProfile.designation,
                            programName: memberProfile.programName,
                            schoolName: memberProfile.schoolName,
                            passOutYear: memberProfile.passOutYear,
                            profilePictureFilePath: memberProfile.profilePictureFilePath
                        } : null
                    };
                });

            if (processedConversations.length > 0 && processedConversations[0].memberProfile) {
                const { memberProfile } = processedConversations[0];
                const { firstName, lastName, profilePictureFilePath, userTypeCode } = memberProfile;
                const isStarred = processedConversations[0]?.isStarred;
                const result = await getById(processedConversations[0]?.id);

                dispatch({
                    type: 'CONTROL_VALUE_CHANGE',
                    payload: { dataKey: 'memberIds', value: [] },
                });

                dispatch({
                    type: 'CONTROL_VALUE_CHANGE',
                    payload: {
                        dataKey: 'selectedUserDetails',
                        value: {
                            userName: `${firstName} ${lastName}`,
                            userImage: profilePictureFilePath,
                            userType: userTypeCode
                        }
                    },
                });

                dispatch({
                    type: 'CONTROL_VALUE_CHANGE',
                    payload: { dataKey: 'conversationWithUser', value: result?.data },
                });

                dispatch({
                    type: 'CONTROL_VALUE_CHANGE',
                    payload: { dataKey: 'messageMode', value: 'SHOW_CONVERSATION_WITH_USER' },
                });

                dispatch({
                    type: 'CONTROL_VALUE_CHANGE',
                    payload: { dataKey: 'starred_marked', value: isStarred },
                });

                dispatch({
                    type: 'CONTROL_VALUE_CHANGE',
                    payload: { dataKey: 'conversationIdPass', value: conversationId },
                });
            } else {
                console.error('No valid conversation or member profile found.');
            }
        } catch (error) {
            console.error('Error fetching conversations and profiles:', error);
        }
    };

    return (
        <div className="alumni_chat_conversation_send" style={{ display: enableTextForStudent }}>
            <div className="row g-2">
                <div className="col">
                    <input
                        type="text"
                        className="form-control flex-1"
                        id="msgsend"
                        onChange={(e) => setNewMessage(e.target.value)}
                        onKeyUp={handleTypedMessageChange}
                        placeholder="Write a message"
                        value={newMessage}
                    />
                </div>
                <div className="col-auto">
                    <button type="button" className="btn btn-green position-relative px-3" onClick={handleSendMessage}>
                        <i className="icon-alumni-send position-relative mx-1"></i>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default OneToOneChatBar;
