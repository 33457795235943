import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
// import darkLogo from '../../../styles/images/dark-logo.svg';
import LoginCarouselControl from './LoginCarouselControl';
// import './form_css.css';
import './styles.css';
import { SmartContext } from '../../../library/Core/SmartContext';
import ForgotPassword from './ForgotPassword';

const darkLogo = `${process.env.REACT_APP_IMAGE_BASEPATH}/dark-logo.svg`;

const SignupLayout = (props: any) => {
    const { schoolDomain } = useParams();
    const location = useLocation();
    const schoolSettings = localStorage.getItem('school_settings');
    const parsedSchoolSettings = schoolSettings ? JSON.parse(schoolSettings) : null;
    const schoolVideoId = parsedSchoolSettings?.youtubeId ?? '';

    const pageName = location.pathname.includes('signup') ? 'signup' : 'login';
    const { state, dispatch } = useContext(SmartContext);
    let schoolLogo = JSON.parse(localStorage.getItem('school_settings') as any);
    const [forgotPasswordLayout, setForgotPasswordLayout] = useState(false);
    const [showForgotPassEmailWindow, setShowForgotPassEmailWindow] = useState(true);
    let schoolImage = sessionStorage.getItem('school-image') as any;
    const [isVideoModalOpen, setVideoModalOpen] = useState(false);
    let player: any = null;
    const playerRef = useRef<any>(null);

    const backtoLogin = () => {
        setForgotPasswordLayout(false);
        setShowForgotPassEmailWindow(false);
    };

    const handleForgotPasswordClick = () => {
        setForgotPasswordLayout(true);
        setShowForgotPassEmailWindow(true);
    };

    const handleExistingUserClick = () => {
        setForgotPasswordLayout(false);
        setShowForgotPassEmailWindow(true);
    };

    const handleNewUserClick = () => {
        setForgotPasswordLayout(false);
        setShowForgotPassEmailWindow(false);
    };
    return (
        <div className="container-fluid bg_side_blue landing_login_page d-flex flex-1">
            <div className="container max-1300 flex-1">
                <div className="row h-100 position-relative z1">
                    <div className="col-md-6 d-none d-md-flex flex-column">
                        <div className="custom_logo text-center">
                            <img src={schoolImage} width="200" className="img-fluid" />
                        </div>
                        <div className="leftPaneMain">
                            <LoginCarouselControl />
                        </div>
                        <div className="leftPaneFooter">
                            <div className="row g-4 align-items-center">
                                <div className="col-md">
                                    <div className="copyRight">
                                        International Educational Gateway Pvt. Ltd. © 2023
                                        <span>All Rights Reserved . Univariety</span>
                                    </div>
                                </div>
                                <div className="col-md-auto">
                                    <div className="powerby">
                                        <span>Powered By</span>
                                        <img src={darkLogo} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="signup h-100" aria-autocomplete="none">
                            <div className="custom_logo text-center d-block d-md-none">
                                <img src={schoolImage} width="200" className="img-fluid" />
                            </div>

                            <div className="col-12 tab-content px-0 px-sm-3 px-md-5" id="myloginTabContent">
                                {state?.businessValidationErrors?.length && (
                                    <div
                                        className={`bg ${state?.businessValidationErrors[0].success ? 'success-message' : 'fail-message'}`}>
                                        {state?.businessValidationErrors?.map((item: any) => (
                                            <p key={item.message}>{item.message}</p>
                                        ))}
                                    </div>
                                )}
                                {!forgotPasswordLayout ? (
                                    props.children
                                ) : (
                                    <ForgotPassword showForgotPassEmailWindow={showForgotPassEmailWindow} />
                                )}

                                <div className="action_btns">
                                    {forgotPasswordLayout == false && (
                                        <>
                                            <label className="btn-forgotpw">
                                                Forgot Password?{' '}
                                                <a href="#" role="button" onClick={handleForgotPasswordClick}>
                                                    click here
                                                </a>
                                            </label>
                                        </>
                                    )}

                                    {forgotPasswordLayout == true && (
                                        <>
                                            <a className="btn-relogin" href="" onClick={backtoLogin}>
                                                Return to login?
                                            </a>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignupLayout;
