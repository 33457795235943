// import imgcloudcomputing from '../../../styles/images/cloud-computing.svg';
import { useEffect, useState } from 'react';
import { Outlet, useLoaderData, useLocation, useNavigate, useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { OptionsOrGroups, GroupBase } from 'react-select';
import { components } from 'react-select';
import { getStudentImage } from '../../../common/styles/Images';
import { axiosClient } from '../../../library/Service/axiosClient';
import { isEmpty } from '../../../library/Core/SmartFunctions';

const imgcloudcomputing = `${process.env.REACT_APP_IMAGE_BASEPATH}/cloud-computing.svg`;

interface CustomOption {
    value: string;
    label: string;
    name: string;
    firstName: string;
    lastName: string;
    profilePictureFilePath?: string;
    schoolPictureFilePath?: string;
    schoolName?: string;
    passOutYear?: number;
    programName?: string;
    nation: string;
    achievement : string;
    selectugCollege: number;
    ugCollege: string;
    ugDepartment: string;
    selectpgCollege: number;
    pgCollege: string;
    pgDepartment: string;
    selectjobCompany: number;
    jobCompany: string;
    jobDesignation: string; 
    registeredEmailId: string;
    selectLocation: number;
    selectAchievement: number;
}

// Define the type for the achievement items
interface AchievementItem {
  typeCount: number;
  achievementValues: string;
}

interface Errors {
    user: string;
    dataSet: string;
    image: string;
    headline: string;
    instimage: string;
}

const AddSpotlightProfile = () => {
    const { id, code, eid } = useParams();
    const [selectedUser, setSelectedUser] = useState<CustomOption | null>(null);
    const [selectedDataSet, setSelectedDataSet] = useState<string>(''); // State to track the selected dataset
    const [isLoading, setIsLoading] = useState(false);
    const [uploadedImage, setUploadedImage] = useState<string | null>(null); // State to hold the uploaded image URL
    const [schoolImage, setSchoolImage] = useState<string | null>(null); // State to hold the uploaded image URL
    const [editData, setEditData] = useState<any>({}); // State to hold the edit data if in edit mode
    const [errors, setErrors] = useState({
        user: '',
        dataSet: '',
        image: '',
        headline: '',
        instimage: ''
    });

    // State to manage checkboxes
    const [showCurrentLocation, setShowCurrentLocation] = useState(false);
    const [showAchievement, setShowAchievement] = useState(false);     
    // State to track the selected option
    const [selectedOption, setSelectedOption] = useState<string>('');
    const [headlineOption, setHeadlineOption] = useState<string>('');

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedDataSet(event.target.value);
    };
    let editdata: any = {};

    useEffect(() => {
      setSelectedOption('yes');
    }, []);

    const isEditing = !!eid;
    useEffect(() => {
        if (isEditing && eid) {  // Ensure eid exists and editing mode is active
            const fetchEditData = async () => {
                try {
                    setIsLoading(true);
                    const widgetResp = await axiosClient().get(`${process.env.REACT_APP_WIDGET_API_SERVER}/widgetInfo/getwidgetalumni/${eid}`);
                    const users = widgetResp?.data?.widgetprofileData || [];
    
                    if (users.length > 0) {
                        const user = users[0];  // Assuming only one user is fetched, otherwise loop through
    
                        console.log(user, 'editData')
                        // Set the data into editData state
                        const formattedUser: CustomOption = {
                            value: user.user_id, // Ensure you are correctly using the UUID here
                            label: `${user.user_name}`,
                            name: `${user.user_name}`,
                            firstName: user.user_name.split(' ')[0], // Assuming the first part is the first name
                            lastName: user.user_name.split(' ').slice(1).join(' ') || '', // Assuming the rest is the last name
                            profilePictureFilePath: user.profile_pic || '', // Handle undefined profilePictureFilePath
                            schoolPictureFilePath: user.profile_pic || '',
                            schoolName: user.school_name || '', // Handle undefined schoolName
                            passOutYear: user.user_year || null, // Handle undefined passOutYear
                            programName: user.user_program || '', // Handle undefined program name
                            nation: user.nationality || '', // Handle undefined nation
                            achievement : user.data_achievement || '', // Handle undefined nation
                            ugCollege: user.joined_ug_college || '', // Handle undefined ugCollege
                            ugDepartment: user.joined_ug_course || '', // Handle undefined UG department
                            pgCollege: user.joined_pg_college || '', // Handle undefined PG college
                            pgDepartment: user.joined_pg_course || '', // Handle undefined PG department
                            jobCompany: user.current_company || '', // Handle undefined jobCompany
                            jobDesignation: user.current_designation || '', // Handle undefined jobDesignation
                            registeredEmailId: '', // Handle undefined registeredEmailId
                            selectugCollege: user.select_ug_college || '', // Add default or appropriate value
                            selectpgCollege: user.select_pg_college || '', // Add default or appropriate value
                            selectjobCompany: user.select_pg_company || '', // Add default or appropriate value                            
                            selectLocation: user.select_current_location || '',
                            selectAchievement: user.select_achievement || ''
                        };
                        
                        setHeadlineOption(user.headline_spotlight || '');
                        setEditData(formattedUser);
                        setSelectedUser(formattedUser); // Optionally set this if you want to pre-select the user
                        setUploadedImage(user.profile_pic || null);
                        setSchoolImage(user.inst_profile_pic || null);
                        if(user.data_type === 2 ){
                            setSelectedOption('no');
                        } else {
                            setSelectedOption('yes');
                        }
                        
                        

                        
                    }
                } catch (error) {
                    console.error("Failed to fetch widget data", error);
                } finally {
                    setIsLoading(false);
                }
            };
            fetchEditData();
        }
    }, [eid, isEditing]);

    const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const apiUrl = `${process.env.REACT_APP_COMMON_API}/upload/image`;
        const formData = new FormData();
    
        // Make sure there's at least one file
        if (event.target.files && event.target.files.length > 0) {
            formData.append('image', event.target.files[0]); // Append the first file
    
            try {
                const response = await axiosClient().post(apiUrl, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
    
                // Assuming the response returns the new image URL
                const newImageUrl = response.data.link;
    
                // Update the uploaded image state and the selected user's profile picture
                setUploadedImage(newImageUrl);
                setSelectedUser((prevUser) => prevUser ? { ...prevUser, profilePictureFilePath: newImageUrl } : prevUser);
    
            } catch (error) {
                console.error('Error uploading image:', error);
            }
        } else {
            console.log('No file selected');
        }
    };

    const handleImageInstUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const apiUrl = `${process.env.REACT_APP_COMMON_API}/upload/image`;
        const formData = new FormData();
    
        // Make sure there's at least one file
        if (event.target.files && event.target.files.length > 0) {
            formData.append('image', event.target.files[0]); // Append the first file
    
            try {
                const response = await axiosClient().post(apiUrl, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
    
                // Assuming the response returns the new image URL
                const newImageUrl = response.data.link;
    
                // Update the uploaded image state and the selected user's profile picture
                setSchoolImage(newImageUrl);
                setSelectedUser((prevUser) => prevUser ? { ...prevUser, schoolPictureFilePath: newImageUrl } : prevUser);
    
            } catch (error) {
                console.error('Error uploading image:', error);
            }
        } else {
            console.log('No file selected');
        }
    };
    

    

// Ensure the function returns a promise with the correct type
const loadOptions = async (
    inputValue: string,
    callback: (options: OptionsOrGroups<CustomOption, GroupBase<CustomOption>>) => void
): Promise<OptionsOrGroups<CustomOption, GroupBase<CustomOption>>> => {
    setIsLoading(true);
    try {
        if (isEmpty(inputValue) || inputValue.length < 3) {
            setIsLoading(false);
            callback([]);
            return [];
        }
        const res = await axiosClient().get(`${process.env.REACT_APP_WIDGET_API_SERVER}/alumniInfo/get-alumni-guiding?term=${inputValue}`);
        const users = res.data[0] || [];

        console.log(users, 'users')

        const formattedOptions: OptionsOrGroups<CustomOption, GroupBase<CustomOption>> = users.map((user: any) => ({
            value: user.uuid, // Ensure you are correctly using the UUID here
            label: `${user.firstName} ${user.lastName}`,
            name: `${user.firstName} ${user.lastName}`,
            firstName: user.firstName,
            lastName: user.lastName,
            profilePictureFilePath: user.profilePictureFilePath || '', // Handle undefined profilePictureFilePath
            schoolPictureFilePath: user.instituteLogo || '', // Handle undefined schoolPictureFilePath
            schoolName: user.schoolName || '', // Handle undefined schoolName
            passOutYear: user.passOutYear || null, // Handle undefined passOutYear
            programName: user.programName || '', // Handle undefined program name
            nation: user.nation || '', // Handle undefined nation
            achievement : '', // Handle undefined nation
            ugCollege: user.ugCollege || '', // Handle undefined ugCollege
            ugDepartment: user.ugDepartment || '', // Handle undefined UG department
            pgCollege: user.pgCollege || '', // Handle undefined PG college
            pgDepartment: user.pgDepartment || '', // Handle undefined PG department
            jobCompany: user.jobCompany || '', // Handle undefined jobCompany
            jobDesignation: user.jobDesignation || '', // Handle undefined jobDesignation
            registeredEmailId: user.registeredEmailId || '', // Handle undefined registeredEmailId
            selectugCollege: user.selectugCollege || '0', // Assuming you get this from the user object
            selectpgCollege: user.selectpgCollege || '0', // Assuming you get this from the user object
            selectjobCompany: user.selectjobCompany || '0', // Assuming you get this from the user object
        }));
        
        
        setIsLoading(false);
        callback(formattedOptions);
        return formattedOptions;
    } catch (error) {
        console.error('Error loading user names:', error);
        setIsLoading(false);
        const emptyOptions: OptionsOrGroups<CustomOption, GroupBase<CustomOption>> = [];
        callback(emptyOptions);
        return emptyOptions;
    }
};


const handleAlumniNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newAlumniName = event.target.value;

    if (selectedOption === 'no') {
        setSelectedUser((prevUser) => ({
            ...prevUser,
            firstName: newAlumniName.split(' ')[0] || '',
            lastName: newAlumniName.split(' ').slice(1).join(' ') || '',
            label: newAlumniName,
            name: newAlumniName,
            value: prevUser?.value || '',
            nation: prevUser?.nation || '',
            profilePictureFilePath: prevUser?.profilePictureFilePath || '',
            schoolPictureFilePath: prevUser?.schoolPictureFilePath || '',
            schoolName: prevUser?.schoolName || '',
            achievement: prevUser?.achievement || '',
            selectugCollege: prevUser?.selectugCollege ?? 0,
            ugCollege: prevUser?.ugCollege || '',
            ugDepartment: prevUser?.ugDepartment || '',
            selectpgCollege: prevUser?.selectpgCollege ?? 0,
            pgCollege: prevUser?.pgCollege || '',
            pgDepartment: prevUser?.pgDepartment || '',
            selectjobCompany: prevUser?.selectjobCompany ?? 0,
            jobCompany: prevUser?.jobCompany || '',
            jobDesignation: prevUser?.jobDesignation || '',
            selectLocation: prevUser?.selectLocation ?? 0,
            selectAchievement: prevUser?.selectAchievement ?? 0,
            registeredEmailId: prevUser?.registeredEmailId || '', // Default to an empty string
        }));
    }
};







    const handleOnChange = (user: CustomOption | null) => {
        if (!user) {
            setSelectedUser(null);
            setUploadedImage(null);
            setSchoolImage(null);
            return;
        }
        
        setSelectedUser(user);
        setUploadedImage(user.profilePictureFilePath || null);
        setSchoolImage(user.schoolPictureFilePath || null);
                
        // Perform any further actions with the selected user
    };

    const handleSave = async () => {
        // if (!selectedUser) {
        //     console.error('No user selected');
        //     return;
        // }
        // Clear previous errors
   
        let dataType: any;
        if(selectedOption === 'yes'){
            dataType = 1;
        } else if(selectedOption === 'no') {
            dataType = 2;

        } else {
            dataType = 1;
        }

        // Example validation checks
        let validationErrors: any = {};
        // Example validation checks
        if (!selectedUser) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                user: 'Please select a user.'
            }));
            return;
        }

        
        if (!uploadedImage) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                image: 'Please upload a display image.'
            }));
            return;
        }

        if (!schoolImage) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                instimage: 'Please upload a display image.'
            }));
            return;
        }
        
        if (!headlineOption) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                headline: 'Please insert a headline.'
            }));
            return;
        } else if (headlineOption.length > 30) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                headline: 'The headline must be max 30 characters long.'
            }));
            return;
        }

        const dataToSave = {
            widgetCode: code,
            widgetInstituteListId: id,
            userId: selectedUser.value, // Use `value` as it contains the UUID
            userName: `${selectedUser.firstName} ${selectedUser.lastName}`,
            email: '',
            passoutYear: '',
            programName: '',
            schoolName: '',
            profilePicture: uploadedImage || selectedUser.profilePictureFilePath,
            schoolPicture: schoolImage || selectedUser.schoolPictureFilePath,
            nation: '',
            ugCollege: '',
            ugDepartment: '',
            pgCollege: '',
            pgDepartment: '',
            jobCompany: '',
            jobDesignation: '',
            achievement: '',
            selectedDataSet: null,
            selectedLocation: 0, // Convert boolean to 1 or 0
            selectedAchievement: 0, // Convert boolean to 1 or 0
            headlineOption: headlineOption || '',
            dataType: dataType || '',
            eid: eid || '',
        };

        console.log(dataToSave, 'dataToSave array data')

        try {
            const response = await axiosClient().post(`${process.env.REACT_APP_WIDGET_API_SERVER}/action/savewidgetlist`, dataToSave);
            if (response.status === 200) {
                console.log('Data saved successfully');

                // After successful save/update
                alert('Data has been saved/updated successfully.');

                // Redirect to the desired URL
                window.location.href = `${process.env.REACT_APP_APP_BASE_URL}/widget/configurelist/${code}/${id}`;
            } else {
                console.error('Failed to save data');
            }
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };
    

    const CustomOption = (props: any) => {
        const { data } = props;
        
        console.log('Data:', data.profilePictureFilePath)
        return (
            <components.Option {...props}>
                <p
                    key={data.value}
                    className="d-flex flex-wrap align-items-center font-16 line-height-18 font-500 text-dark-blue mb-0 pb-3 pb-sm-2">
                    <span className="me-3">
                        <img
                            src={getStudentImage({ profilePictureFilePath: data.profilePictureFilePath, genderCode: '' })}
                            alt=""
                            className="rounded-circle"
                            style={{ height: '50px', borderRadius: '50%', width: '50px' }}
                        />
                    </span>
                    <span className="flex-1">
                        {`${data.firstName} ${data.lastName}`},{' '}
                        <span className="fst-italic">
                            {data.schoolName}
                            {data.passOutYear ? `, ${data.passOutYear}` : ''}
                        </span>
                    </span>
                </p>
            </components.Option>
        );
    };

    // const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     setFuzzySearchText(e.target.value);
    // };
    


    // Handler for when a radio button is selected
    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOption(event.target.value);
    
        if (event.target.value === 'no') {
            setSelectedUser({
                firstName: '',
                lastName: '',
                label: '',
                name: '',
                value: '',
                nation: '',
                profilePictureFilePath: '',
                schoolPictureFilePath: '',
                schoolName: '',
                achievement: '',
                selectugCollege: 0, // Default value for number
                ugCollege: '',
                ugDepartment: '',
                selectpgCollege: 0, // Default value for number
                pgCollege: '',
                pgDepartment: '',
                selectjobCompany: 0, // Default value for number
                jobCompany: '',
                jobDesignation: '',
                selectLocation: 0, // Default value for number
                selectAchievement: 0, // Default value for number
                registeredEmailId: '', // Default value to prevent undefined
            });
        } else {
            setSelectedUser(null); // Clear any custom external data when switching to system data
        }
    };
    
    

    // Handler for when a Headline will change
    const handleHeadlineChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setHeadlineOption(event.target.value);
    };
    
    return (
        <>
            <div className='bg-gray-F2F4F7 border-radius-6 p-4'>
                            
            <div className="row g-4">
            <div className="col-md-5">
                        <div className="w-100 h-100 d-flex justify-content-center align-items-start">   

                            <div className='alumni-card-wrap p-0 w-100'>
                                <div className='alumni-card-slider'>
                                <div className="alumni-card">
                            <div className="alumni-detail">
                              <div className="alumni-img-wrap">
                                <div className="alumni-img">
                                    <img src={uploadedImage || selectedUser?.profilePictureFilePath || 'https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/images/dummy_male.svg'} width="80" alt=""/>
                                </div>
                              </div>
                              <h3>{selectedUser ? `${selectedUser.firstName} ${selectedUser.lastName}` : 'Alumni Name'} </h3>
                              <h4>{headlineOption || 'Headline Spotlight'} </h4>
                            
                            </div>
                            <div className="alumni-logo">
                              <a href="javascript:;" className="alumni-link">
                                <img src={schoolImage || selectedUser?.schoolPictureFilePath || 'https://s3.ap-south-1.amazonaws.com/media.univariety.com/userreactapp/style-images/college_icon.svg'} width="90" className="img-fluid" alt=""/>
                              </a>
                            </div>
                          </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7">                  
                    <div className="row g-4">
                        <div className="col-12">
                        <div className="bg-white border-radius-6 p-4">
                            <form className="p-2">
                            <div className="row g-4">
            
                         
                            <div className="col-md-12">
                            <div className="row g-3 align-items-center">
                            <div className="col-12">
                                        <label className="form-label m-0 mb-1 font-16 font-600">ADD ALUMNI PROFILE</label>
                                    </div>
                                    <div className="col-auto col-md-auto col-lg-6">
                                 
   
                                      <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                                          <input type="radio" name="ADDALUMNIPROFILE" id="pickDatafromSystem" value="yes"
                                            checked={selectedOption === 'yes'}
                                            onChange={handleOptionChange} className="form-check-input custom-border-84-20 m-0" />
                                          <label htmlFor="pickDatafromSystem" className="form-check-label font-16 font-500 ms-2 ">Pick Data from System</label>
                                      </div>
                                  </div>
                                  <div className="col-auto col-md-auto col-lg-6">
                                
    
                                    <div className="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center">
                                        <input type="radio" name="ADDALUMNIPROFILE" id="addExternalData" value="no"
                                            checked={selectedOption === 'no'}
                                            onChange={handleOptionChange} className="form-check-input custom-border-84-20 m-0"/>
                                        <label htmlFor="addExternalData" className="form-check-label font-16 font-500 ms-2 ">Add External Data</label>
                                    </div>
                                </div>
                                    
                                   
                                  </div>
                            </div>

                            {/* Conditionally render the div based on the selected option */}
                            {selectedOption === 'yes' && (
                                
                                <div className="row g-4">
                                    <div className="col-12 mt-0">
                                        <div className='containerinnercover singleOption'>
                                        <AsyncSelect
                                            loadOptions={loadOptions}
                                            onChange={handleOnChange}
                                            isSearchable={true}
                                            isClearable
                                            components={{
                                                DropdownIndicator: null,
                                                IndicatorSeparator: null,
                                                Option: CustomOption,
                                            }}
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                            value={selectedUser}
                                            placeholder="Search by name"  // Add this line for the placeholder
                                        />
                                        {errors.user && <div style={{ color: 'red', marginTop: '5px' }}>{errors.user}</div>}
                                        </div>                                                
                                    </div>
                                
                                    {/* <div className="col-md-12"><hr className="m-0"/></div> */}
                                    <div className="col-md-12">
                                        <label className="form-label m-0 mb-1 font-16 font-500 w-100 mb-2" htmlFor="cloud-computing">Upload Display Image</label>                   
                                        <div className="upload_section">
                                            <input type="file" id="cloud-computing" onChange={handleImageUpload} className=""/>
                                            <div className="row g-2 align-items-center justify-content-center">
                                                <div className="col-sm d-flex align-items-center justify-content-center">
                                                    <div className="cloud-wrap px-3 px-sm-5 mx-auto">
                                                        <div className="cloud-img"><img src={imgcloudcomputing} alt=""/></div>
                                                        <div className="cloud-text">Drag and drop file here or <a href="#" className="text-sky-blue">Browse</a> </div>
                                                    </div>                          
                                                </div>                         
                                            </div>
                                        </div>
                                        <span className="text-end font-14 d-block text-dark-blue opacity-7 mt-1">Only png, jpg, jpeg files. Best resolution 400px x 400px</span>
                                        {errors.image && <div style={{ color: 'red', marginTop: '5px' }}>{errors.image}</div>}
                                    </div>   
                                    <div className="col-md-12">
                                        <input type="text" className="form-control" onChange={handleHeadlineChange} value= {headlineOption} placeholder="Profile Headline mentioning reason tor spotlight" />
                                        {errors.headline && <div style={{ color: 'red', marginTop: '5px' }}>{errors.headline}</div>}
                                    </div>                    
                                    <div className="col-md-12">
                                        <label className="form-label m-0 mb-1 font-16 font-500 w-100 mb-2" htmlFor="UploadLogo">Upload Logo</label>                   
                                        <div className="upload_section">
                                            <input type="file" id="UploadLogo" onChange={handleImageInstUpload} className=""/>
                                            <div className="row g-2 align-items-center justify-content-center">
                                                <div className="col-sm d-flex align-items-center justify-content-center">
                                                    <div className="cloud-wrap px-3 px-sm-5 mx-auto">
                                                        <div className="cloud-img"><img src={imgcloudcomputing} alt=""/></div>
                                                        <div className="cloud-text">Drag and drop file here or <a href="#" className="text-sky-blue">Browse</a> </div>
                                                    </div>                          
                                                </div>                         
                                            </div>
                                        </div>
                                        <span className="text-end font-14 d-block text-dark-blue opacity-7 mt-1">Only png, jpg, jpeg files. Best resolution 400px x 400px</span>
                                        {errors.instimage && <div style={{ color: 'red', marginTop: '5px' }}>{errors.instimage}</div>}
                                    </div>
                                </div>
                            
                            )}

                            {selectedOption === 'no' && (
                                <div className="col-md-12">
                             

                                <div className="row g-3 align-items-center mb-2">
                         
                         
                                  <div className="col-12">
                                  <input 
                                        type="text" 
                                        onChange={handleAlumniNameChange} 
                                        value={selectedUser?.name || ''} // Ensure the value comes from selectedUser
                                        className="form-control" 
                                        placeholder="Enter Alumni Name"
                                    />
                                    
                                    </div>

                                    <div className="col-md-12">
                                        <label className="form-label m-0 mb-1 font-16 font-500 w-100 mb-2" htmlFor="cloud-computing">Upload Display Image</label>                   
                                        <div className="upload_section">
                                            <input type="file" id="cloud-computing" onChange={handleImageUpload} className=""/>
                                            <div className="row g-2 align-items-center justify-content-center">
                                                <div className="col-sm d-flex align-items-center justify-content-center">
                                                    <div className="cloud-wrap px-3 px-sm-5 mx-auto">
                                                        <div className="cloud-img"><img src={imgcloudcomputing} alt=""/></div>
                                                        <div className="cloud-text">Drag and drop file here or <a href="#" className="text-sky-blue">Browse</a> </div>
                                                    </div>                          
                                                </div>                         
                                            </div>
                                        </div>
                                        <span className="text-end font-14 d-block text-dark-blue opacity-7 mt-1">Only png, jpg, jpeg files. Best resolution 400px x 400px</span>
                                        {errors.image && <div style={{ color: 'red', marginTop: '5px' }}>{errors.image}</div>}
                                    </div>  

                                    <div className="col-md-12"><input type="text" className="form-control" onChange={handleHeadlineChange} value= {headlineOption} placeholder="Profile Headline mentioning reason tor spotlight" /></div>                    
                                    <div className="col-md-12">
                                        <label className="form-label m-0 mb-1 font-16 font-500 w-100 mb-2" htmlFor="UploadLogo">Upload Logo</label>                   
                                        <div className="upload_section">
                                            <input type="file" id="UploadLogo" onChange={handleImageInstUpload} className=""/>
                                            <div className="row g-2 align-items-center justify-content-center">
                                                <div className="col-sm d-flex align-items-center justify-content-center">
                                                    <div className="cloud-wrap px-3 px-sm-5 mx-auto">
                                                        <div className="cloud-img"><img src={imgcloudcomputing} alt=""/></div>
                                                        <div className="cloud-text">Drag and drop file here or <a href="#" className="text-sky-blue">Browse</a> </div>
                                                    </div>                          
                                                </div>                         
                                            </div>
                                        </div>
                                        <span className="text-end font-14 d-block text-dark-blue opacity-7 mt-1">Only png, jpg, jpeg files. Best resolution 400px x 400px</span>
                                        {errors.instimage && <div style={{ color: 'red', marginTop: '5px' }}>{errors.instimage}</div>}
                                    </div>  
                            
                                 
                                </div>
                                              
                                
                              </div>
                            )}
                                                       
                            </div>

                                
                            </form>
                        </div>
                        </div>
                    </div>

                    
                    </div>
                    
                </div>

                
                
                {/* <div className="row mt-4 justify-content-end">
                    <div className="col-md-7">
                        <div className="row g-3 align-items-center justify-content-around">
                            <div className="col-auto"><button className="btn-border-gray height-40 w-100 max-190"><i className="icon-left-arrow font-12 position-relative me-1"></i><span>Previous Profile</span></button></div>
                            <div className="col-auto"><button className="btn-border-gray height-40 w-100 max-190"><span>Next Profile</span> <i className="icon-right-arrow font-12 position-relative ms-1"></i></button></div>
                        </div>
                    </div>
                </div> */}
            </div>

            <div className="action_btns mt-4 max-100per">
                {/* <button type="button" className="nextbtn my-2 my-sm-0 mx-2 px-5" data-bs-toggle="modal" data-bs-target="#exampleModal">Preview</button>               */}
                <button type="button" className="btn btn-green my-2 my-sm-0 mx-2 height-45 px-5" onClick={handleSave}><span className="px-3">Save</span></button>
            </div>
        </>
        
    );
};

export default AddSpotlightProfile;