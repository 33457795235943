import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/css_pre_singup/style.css';
import { useParams, useLocation } from 'react-router-dom';
import { GlobalContext } from '../../library/Core/GlobalContext';
import SessionContext from '../../library/Core/SessionContext';

const PastStudent: React.FC = () => {
    const { globalState } = useContext(GlobalContext);
    const { sessionState, sessionDispatch } = useContext(SessionContext);
    const { schoolDomain } = useParams<{ schoolDomain: string }>();
    const [schoolImage, setSchoolImage] = useState<string | null>(null);
    const [childFirstName, setChildFirstName] = useState<string>('');
    const [childLastName, setChildLastName] = useState<string>('');
    const [contactNumber, setContactNumber] = useState<string>('');
    const [mobileCountryCode, setMobileCountryCode] = useState<string>('91');
    const [email, setEmail] = useState<string>('');
    const [instName, setInstName] = useState<string>('');
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const location = useLocation();
    const currentPathname = location.pathname;
    const pathParts = currentPathname.split('/');
    pathParts.pop();

    if (pathParts[1] === 'screening') {
        pathParts[1] = 'user';
    }

    const modifiedPathname = pathParts.join('/');
    const modifiedUrl = `${window.location.origin}${modifiedPathname}`;

    useEffect(() => {
        const fetchSchoolInfo = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_STUDENT_SERVER}/v1/auth/school-info/${schoolDomain}`);
                const logoUrl = response.data.institute.logo;
                const instName = response.data.institute.name;

                setSchoolImage(logoUrl);
                setInstName(instName);
                localStorage.setItem('school-image', logoUrl);
                sessionStorage.setItem('school-image', logoUrl);

            } catch (error) {
                console.error('Error fetching school info:', error);
            }
        };

        fetchSchoolInfo();
    }, [schoolDomain]);

    const handleSubmit = async () => {
        const newErrors: { [key: string]: string } = {};

        if (!childFirstName) {
            newErrors.childFirstName = 'First name is required';
        }
        if (!childLastName) {
            newErrors.childLastName = 'Last name is required';
        }
        if (!contactNumber) {
            newErrors.contactNumber = 'Contact number is required';
        } else {
            const isIndiaCode = mobileCountryCode === '91';
            const contactNumberRegex = isIndiaCode
                ? /^[6-9]\d{9}$/
                : /^[1-9]\d{5,}$/;
            if (!contactNumberRegex.test(contactNumber)) {
                newErrors.contactNumber = isIndiaCode
                    ? 'Indian contact number must be 10 digits and start with 6-9'
                    : 'Contact number must be more than 6 digits and start with 1-9';
            }
        }
        if (!email) {
            newErrors.email = 'Email is required';
        } else {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(email)) {
                newErrors.email = 'Invalid email address';
            }
        }

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BROADCAST_SERVER}/pre-signup/save-data`, {
                    first_name: childFirstName,
                    last_name: childLastName,
                    phone_number: contactNumber,
                    email: email,
                    user_type: 2, 
                    country_code: `+${mobileCountryCode}`,
                    inst_name: instName,
                    logo: schoolImage,
                    signupUrl: modifiedUrl
                });
                const insertId = response.data.result[0].insertId;
                console.log('Inserted Record ID:', insertId);

                window.location.href = 'thank-you';
            } catch (error) {
                console.error('Error submitting form:', error);
            }
        }
    };

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const { id, value } = e.target;
        if (id === 'childFirstName') {
            setChildFirstName(value);
        } else if (id === 'childLastName') {
            setChildLastName(value);
        } else if (id === 'contactNumber') {
            setContactNumber(value);
        } else if (id === 'mobileCountryCode') {
            setMobileCountryCode(value);
        } else if (id === 'email') {
            setEmail(value);
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const charCode = e.which ? e.which : e.keyCode;
        if (charCode < 48 || charCode > 57) {
            e.preventDefault();
        }
    };

    return (
        <main className="wrapper">
            <section className="dark-section student-img d-flex">
                <div className="container d-flex flex-column">
                    <div className="logo">
                        <a href="#" className="h-100"><img src={schoolImage || 'images/logo.svg'} className="img-fluid" alt="logo" /></a>
                    </div>
                    <div className="flex-1 py-5 d-flex flex-column justify-content-center align-items-start align-items-md-center text-white py-5 px-4">
                        <div className="w-1030">
                            <div className="form-title">Become a Member of this Elite Community at No Cost</div>
                            <div className="sep blue mx-0 mx-sm-auto"></div>
                            <div className="pb-5">
                                <form className="min-height-250" onSubmit={(e) => e.preventDefault()}>
                                    <div className="row g-4 align-items-end justify-content-center">
                                        <div className="col-12 col-md-6 form-group">
                                            <label htmlFor="childFirstName" className="form-label">First Name*</label>
                                            <input
                                                type="text"
                                                className={`form-control height-46 border-0 ${errors.childFirstName ? 'error' : ''}`}
                                                id="childFirstName"
                                                placeholder="The star who made us proud!"
                                                value={childFirstName}
                                                onChange={handleInputChange}
                                            />
                                            {errors.childFirstName && <div className="error-message">{errors.childFirstName}</div>}
                                        </div>
                                        <div className="col-12 col-md-6 form-group">
                                            <label htmlFor="childLastName" className="form-label">Last Name*</label>
                                            <input
                                                type="text"
                                                className={`form-control height-46 border-0 ${errors.childLastName ? 'error' : ''}`}
                                                id="childLastName"
                                                placeholder="The star who made us proud!"
                                                value={childLastName}
                                                onChange={handleInputChange}
                                            />
                                            {errors.childLastName && <div className="error-message">{errors.childLastName}</div>}
                                        </div>
                                        <div className="col-12 col-md-6 form-group">
                                            <label htmlFor="email" className="form-label">Email*</label>
                                            <input
                                                type="email"
                                                className={`form-control height-46 border-0 ${errors.email ? 'error' : ''}`}
                                                id="email"
                                                placeholder="Enter your email"
                                                value={email}
                                                onChange={handleInputChange}
                                            />
                                            {errors.email && <div className="error-message">{errors.email}</div>}
                                        </div>
                                        <div className="col-12 col-md-6 form-group">
                                            <label htmlFor="contactNumber" className="form-label">Contact Number*</label>
                                            <div className="row g-3">
                                                <div className="col-auto">
                                                    <select
                                                        id="mobileCountryCode"
                                                        name="user.mobileCountryCode"
                                                        className="form-select form-control country-code"
                                                        value={mobileCountryCode}
                                                        onChange={handleInputChange}
                                                    >
                                                          <optgroup label="Top Countries">
                                                            <option value="91">+91</option>
                                                            <option value="44">+44</option>
                                                            <option value="1">+1</option>
                                                            <option value="61">+61</option>
                                                            <option value="49">+49</option>
                                                            <option value="7">+7</option>
                                                            <option value="86">+86</option>
                                                            <option value="65">+65</option>
                                                            <option value="971">+971</option>
                                                            <option value="81">+81</option>
                                                        </optgroup>
                                                        <optgroup label="----------------------">
                                                            <option value="0000">+0000</option>
                                                            <option value="1242">+1242</option>
                                                            <option value="1246">+1246</option>
                                                            <option value="1264">+1264</option>
                                                            <option value="1268">+1268</option>
                                                            <option value="1345">+1345</option>
                                                            <option value="1441">+1441</option>
                                                            <option value="1473">+1473</option>
                                                            <option value="1649">+1649</option>
                                                            <option value="1664">+1664</option>
                                                            <option value="1670">+1670</option>
                                                            <option value="1671">+1671</option>
                                                            <option value="1758">+1758</option>
                                                            <option value="1767">+1767</option>
                                                            <option value="1784">+1784</option>
                                                            <option value="1809">+1809</option>
                                                            <option value="1868">+1868</option>
                                                            <option value="1876">+1876</option>
                                                            <option value="20">+20</option>
                                                            <option value="212">+212</option>
                                                            <option value="213">+213</option>
                                                            <option value="216">+216</option>
                                                            <option value="218">+218</option>
                                                            <option value="220">+220</option>
                                                            <option value="221">+221</option>
                                                            <option value="222">+222</option>
                                                            <option value="223">+223</option>
                                                            <option value="224">+224</option>
                                                            <option value="225">+225</option>
                                                            <option value="226">+226</option>
                                                            <option value="227">+227</option>
                                                            <option value="228">+228</option>
                                                            <option value="229">+229</option>
                                                            <option value="230">+230</option>
                                                            <option value="231">+231</option>
                                                            <option value="232">+232</option>
                                                            <option value="233">+233</option>
                                                            <option value="235">+235</option>
                                                            <option value="236">+236</option>
                                                            <option value="237">+237</option>
                                                            <option value="238">+238</option>
                                                            <option value="239">+239</option>
                                                            <option value="240">+240</option>
                                                            <option value="241">+241</option>
                                                            <option value="242">+242</option>
                                                            <option value="245">+245</option>
                                                            <option value="246">+246</option>
                                                            <option value="248">+248</option>
                                                            <option value="249">+249</option>
                                                            <option value="250">+250</option>
                                                            <option value="251">+251</option>
                                                            <option value="252">+252</option>
                                                            <option value="253">+253</option>
                                                            <option value="254">+254</option>
                                                            <option value="255">+255</option>
                                                            <option value="256">+256</option>
                                                            <option value="257">+257</option>
                                                            <option value="258">+258</option>
                                                            <option value="260">+260</option>
                                                            <option value="261">+261</option>
                                                            <option value="262">+262</option>
                                                            <option value="263">+263</option>
                                                            <option value="264">+264</option>
                                                            <option value="265">+265</option>
                                                            <option value="266">+266</option>
                                                            <option value="267">+267</option>
                                                            <option value="268">+268</option>
                                                            <option value="269">+269</option>
                                                            <option value="27">+27</option>
                                                            <option value="284">+284</option>
                                                            <option value="290">+290</option>
                                                            <option value="291">+291</option>
                                                            <option value="297">+297</option>
                                                            <option value="298">+298</option>
                                                            <option value="299">+299</option>
                                                            <option value="30">+30</option>
                                                            <option value="31">+31</option>
                                                            <option value="32">+32</option>
                                                            <option value="33">+33</option>
                                                            <option value="34">+34</option>
                                                            <option value="340">+340</option>
                                                            <option value="350">+350</option>
                                                            <option value="351">+351</option>
                                                            <option value="352">+352</option>
                                                            <option value="353">+353</option>
                                                            <option value="354">+354</option>
                                                            <option value="356">+356</option>
                                                            <option value="357">+357</option>
                                                            <option value="358">+358</option>
                                                            <option value="359">+359</option>
                                                            <option value="36">+36</option>
                                                            <option value="370">+370</option>
                                                            <option value="371">+371</option>
                                                            <option value="372">+372</option>
                                                            <option value="373">+373</option>
                                                            <option value="374">+374</option>
                                                            <option value="375">+375</option>
                                                            <option value="376">+376</option>
                                                            <option value="377">+377</option>
                                                            <option value="378">+378</option>
                                                            <option value="379">+379</option>
                                                            <option value="38">+38</option>
                                                            <option value="380">+380</option>
                                                            <option value="385">+385</option>
                                                            <option value="386">+386</option>
                                                            <option value="387">+387</option>
                                                            <option value="389">+389</option>
                                                            <option value="39">+39</option>
                                                            <option value="40">+40</option>
                                                            <option value="41">+41</option>
                                                            <option value="420">+420</option>
                                                            <option value="421">+421</option>
                                                            <option value="423">+423</option>
                                                            <option value="43">+43</option>
                                                            <option value="45">+45</option>
                                                            <option value="46">+46</option>
                                                            <option value="47">+47</option>
                                                            <option value="48">+48</option>
                                                            <option value="500">+500</option>
                                                            <option value="501">+501</option>
                                                            <option value="502">+502</option>
                                                            <option value="503">+503</option>
                                                            <option value="504">+504</option>
                                                            <option value="505">+505</option>
                                                            <option value="506">+506</option>
                                                            <option value="507">+507</option>
                                                            <option value="508">+508</option>
                                                            <option value="509">+509</option>
                                                            <option value="51">+51</option>
                                                            <option value="52">+52</option>
                                                            <option value="53">+53</option>
                                                            <option value="54">+54</option>
                                                            <option value="55">+55</option>
                                                            <option value="56">+56</option>
                                                            <option value="57">+57</option>
                                                            <option value="58">+58</option>
                                                            <option value="581">+581</option>
                                                            <option value="590">+590</option>
                                                            <option value="591">+591</option>
                                                            <option value="592">+592</option>
                                                            <option value="593">+593</option>
                                                            <option value="594">+594</option>
                                                            <option value="595">+595</option>
                                                            <option value="596">+596</option>
                                                            <option value="597">+597</option>
                                                            <option value="598">+598</option>
                                                            <option value="599">+599</option>
                                                            <option value="60">+60</option>
                                                            <option value="62">+62</option>
                                                            <option value="63">+63</option>
                                                            <option value="64">+64</option>
                                                            <option value="66">+66</option>
                                                            <option value="670">+670</option>
                                                            <option value="672">+672</option>
                                                            <option value="673">+673</option>
                                                            <option value="674">+674</option>
                                                            <option value="675">+675</option>
                                                            <option value="676">+676</option>
                                                            <option value="677">+677</option>
                                                            <option value="678">+678</option>
                                                            <option value="679">+679</option>
                                                            <option value="680">+680</option>
                                                            <option value="681">+681</option>
                                                            <option value="682">+682</option>
                                                            <option value="685">+685</option>
                                                            <option value="686">+686</option>
                                                            <option value="687">+687</option>
                                                            <option value="688">+688</option>
                                                            <option value="689">+689</option>
                                                            <option value="690">+690</option>
                                                            <option value="691">+691</option>
                                                            <option value="692">+692</option>
                                                            <option value="82">+82</option>
                                                            <option value="84">+84</option>
                                                            <option value="850">+850</option>
                                                            <option value="852">+852</option>
                                                            <option value="853">+853</option>
                                                            <option value="855">+855</option>
                                                            <option value="856">+856</option>
                                                            <option value="870">+870</option>
                                                            <option value="880">+880</option>
                                                            <option value="886">+886</option>
                                                            <option value="90">+90</option>
                                                            <option value="92">+92</option>
                                                            <option value="93">+93</option>
                                                            <option value="94">+94</option>
                                                            <option value="95">+95</option>
                                                            <option value="960">+960</option>
                                                            <option value="961">+961</option>
                                                            <option value="962">+962</option>
                                                            <option value="963">+963</option>
                                                            <option value="964">+964</option>
                                                            <option value="965">+965</option>
                                                            <option value="966">+966</option>
                                                            <option value="967">+967</option>
                                                            <option value="968">+968</option>
                                                            <option value="972">+972</option>
                                                            <option value="973">+973</option>
                                                            <option value="974">+974</option>
                                                            <option value="975">+975</option>
                                                            <option value="976">+976</option>
                                                            <option value="977">+977</option>
                                                            <option value="98">+98</option>
                                                            <option value="992">+992</option>
                                                            <option value="993">+993</option>
                                                            <option value="994">+994</option>
                                                            <option value="995">+995</option>
                                                            <option value="996">+996</option>
                                                            <option value="998">+998</option>
                                                        </optgroup>
                                                    </select>
                                                </div>
                                                <div className="col">
                                                    <input
                                                        type="text"
                                                        className={`form-control height-46 border-0 ${errors.contactNumber ? 'error' : ''}`}
                                                        id="contactNumber"
                                                        placeholder="Limited & Secure Calls/WhatsApps"
                                                        value={contactNumber}
                                                        onChange={handleInputChange}
                                                        onKeyPress={handleKeyPress}
                                                    />
                                                    {errors.contactNumber && <div className="error-message">{errors.contactNumber}</div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-center text-white pt-5 pb-3 d-none d-md-flex">
                        <div className="col-auto">Powered by</div>
                        <div className="col-auto"><img src="https://s3.ap-south-1.amazonaws.com/media.univariety.dev/images/univariety-white-logo.svg" width="167" alt="Univariety" /></div>
                    </div>
                </div>
            </section>
            <section className="white-section d-block d-md-none">
                <div className="container py-5">
                    <div className="text-center">Powered by</div>
                    <div className="text-center mb-2"><img src="https://s3.ap-south-1.amazonaws.com/media.univariety.dev/images/univariety-dark-logo.svg" width="167" alt="Univariety" /></div>
                </div>
            </section>
            <div className="navigation_footer_wrap">
                <div className="navigation_footer">
                    <a href="identfyuser" className="left-link btn-back"><i></i> <span>BacK</span></a>
                    <a href="#" className="right-link btn-next" onClick={handleSubmit}><span>Next</span><i></i></a>
                </div>
            </div>

            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered w-860 mx-auto px-3">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="d-flex justify-content-end">
                                <button type="button" className="btn-close position-absol" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <img src="images/letter.png" width="860" className="img-fluid" alt="letter" />
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default PastStudent;
