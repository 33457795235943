import { enableMapSet } from 'immer';
import { filter, getGridInitialState, getPageData, gridInSearchMode } from '../Service/GridService';
import { DispatchEvent, ObjectWithKeys, State } from './SmartTypes';
enableMapSet();

const smartReducer = (state: State, action: DispatchEvent) => {
    switch (action.type) {
        case 'FETCH_PAGE_DATA_BEGIN':
            state.flags.isDataLoading = true;
            break;

        case 'DATA_INIT':
            state.data = { ...state.data, ...action.payload.data };
            state.domain = action.payload.domain;
            state.formConfig = action.payload.formConfig;
            state.customControls = action.payload.customControls;
            state.internal = action.payload?.internal ?? {};
            state.pageDomain = action.payload?.pageDomain; // Don't set the default
            state.filters = action.payload?.filters ?? {};
            // state.actions = action.payload.actions;
            state.flags.isDataLoading = false;
            state.routeInfo = action.payload.routeInfo;
            break;

        case 'ADD_ACTIONS':
            state.actions = action.payload;
            break;

        case 'SET_DOMAIN':
            state.domain?.set(action.payload.key, action.payload.value);
            break;

        case 'ROUTE_INFO':
            state.routeInfo = action.payload;
            break;

        case 'FORM_CONFIG':
            state.formConfig = action.payload;
            break;

        case 'CONTROL_VALUE_CHANGE':
            {
                const nodes = action.payload.dataKey.split('.');
                let element: ObjectWithKeys = state.data;
                for (let i = 0; i < nodes.length; i++) {
                    if (i < nodes.length - 1) {
                        element = element[nodes[i]] as ObjectWithKeys;
                    } else {
                        element[nodes[i]] = action.payload.value;
                    }
                }

                state.formValidationErrors = Object.assign(state?.formValidationErrors, {
                    [action.payload.dataKey]: action.payload.errorMessages,
                });
            }

            break;
        case 'ADD_NEW_RECORD_IN_OBJECT':
            {
                const arrayNode = action.payload.dataKey.split('.');
                let element1: ObjectWithKeys = state.data;
                console.log(arrayNode[0]);
                console.log(state.data[element1 as any]);
                console.log(state.data['memories']?.length);
                if (state.data['albumPhoto']?.length > 0) {
                    console.log('ooo index');
                    state.data['albumPhoto'].push(action.payload.value);
                } else {
                    console.log('pish');
                    state.data['albumPhoto'] = action.payload.value;
                }
            }
            break;
        case 'ADD_NEW_RECORD_IN_ARRAY':
            {
                const arrayNode = action.payload.dataKey.split('.');
                let element1: ObjectWithKeys = state.data;
                for (let i = 0; i < arrayNode.length; i++) {
                    if (i < arrayNode.length - 1) {
                        element1 = element1[arrayNode[i]] as ObjectWithKeys;
                    } else {
                        if (!(element1[arrayNode[i]] as any[])?.includes(action.payload.value)) {
                            (element1[arrayNode[i]] as any[])?.push(action.payload.value);
                        }
                    }
                }
            }
            break;

        case 'DELETE_RECORD_IN_ARRAY':
            {
                const arrayNode = action.payload.dataKey.split('.');
                let element1: ObjectWithKeys = state.data;
                for (let i = 0; i < arrayNode.length; i++) {
                    if (i < arrayNode.length - 1) {
                        element1 = element1[arrayNode[i]] as ObjectWithKeys;
                    } else {
                        (element1[arrayNode[i]] as any[]) = [
                            ...(element1[arrayNode[i]] as any[]).slice(0, action.payload.index),
                            ...(element1[arrayNode[i]] as any[]).slice(action.payload.index + 1),
                        ];
                    }
                }
            }
            break;

        case 'UPDATE_RECORD_IN_ARRAY':
            {
                const arrayNode = action.payload.dataKey.split('.');
                console.log(arrayNode);
                let element1: ObjectWithKeys = state.data;
                console.log(action.payload);
                for (let i = 0; i < arrayNode.length; i++) {
                    if (i < arrayNode.length - 1) {
                        element1 = element1[arrayNode[i]] as ObjectWithKeys;
                    } else {
                    }
                }
            }
            break;
        case 'CHECKBOX_ADD_NEW_RECORD_IN_ARRAY':
            {
                const nodes = action.payload.dataKey.split('.');
                let element: ObjectWithKeys = state.data;
                for (let i = 0; i < nodes.length; i++) {
                    if (i < nodes.length - 1) {
                        element = element[nodes[i]] as ObjectWithKeys;
                    } else {
                        element[nodes[i]] = [...(element[nodes[i]] as any[]), action.payload.value];
                    }
                }

                state.formValidationErrors = Object.assign(state?.formValidationErrors, {
                    [action.payload.dataKey]: action.payload.errorMessages,
                });
            }
            break;
        case 'CHECKBOX_DELETE_RECORD_IN_ARRAY':
            {
                const nodes = action.payload.dataKey.split('.');
                let element: ObjectWithKeys = state.data;
                for (let i = 0; i < nodes.length; i++) {
                    if (i < nodes.length - 1) {
                        element = element[nodes[i]] as ObjectWithKeys;
                    } else {
                        element[nodes[i]] = (element[nodes[i]] as any[]).filter(
                            (item) => item[action.payload.name] !== action.payload.value[action.payload.name]
                        );
                    }
                }

                state.formValidationErrors = Object.assign(state?.formValidationErrors, {
                    [action.payload.dataKey]: action.payload.errorMessages,
                });
            }
            break;
        case 'CHECKBOX_DELETE_ALL_RECORD_IN_ARRAY':
            {
                const nodes = action.payload.dataKey.split('.');
                let element: ObjectWithKeys = state.data;
                for (let i = 0; i < nodes.length; i++) {
                    if (i < nodes.length - 1) {
                        element = element[nodes[i]] as ObjectWithKeys;
                    } else {
                        element[nodes[i]] = [];
                    }
                }

                state.formValidationErrors = Object.assign(state?.formValidationErrors, {
                    [action.payload.dataKey]: action.payload.errorMessages,
                });
            }
            break;
        case 'DELETE_RECORD_IN_SIMPLE_ARRAY':
            {
                const nodes = action.payload.dataKey.split('.');
                let element: ObjectWithKeys = state.data;
                for (let i = 0; i < nodes.length; i++) {
                    if (i < nodes.length - 1) {
                        element = element[nodes[i]] as ObjectWithKeys;
                    } else {
                        element[nodes[i]] = (element[nodes[i]] as any[]).filter((item) => item !== action.payload.value);
                    }
                }

                state.formValidationErrors = Object.assign(state?.formValidationErrors, {
                    [action.payload.dataKey]: action.payload.errorMessages,
                });
            }
            break;

        case 'GRID_INTERNAL_STATE_INIT':
            state.internal.gridState[action.payload.id] = getGridInitialState(
                action.payload.id,
                action.payload.data,
                10 // GRID_PAGE_SIZE
            );
            break;

        case 'TABLE_SEARCH_CRITERIA_VALUE_CHANGE':
            {
                // const gridIndex = state.internal?.gridState?.findIndex((grid: any) => grid.id === action.payload.control.id);
                const gridState = state.internal.gridState[action.payload.control.id];
                gridState.searchCriteria = { ...gridState?.searchCriteria, [action.payload.id]: action.payload.value };
                gridState.filteredData = gridInSearchMode(gridState.searchCriteria)
                    ? filter(action.payload.originalData, gridState.searchCriteria)
                    : action.payload.originalData;
                gridState.pageData = getPageData(1, 10, gridState.filteredData);
                state.internal.gridState[action.payload.control.id] = gridState;
            }
            break;

        case 'GRID_PAGE_CHANGE':
            {
                // const gridIndex = state.internal?.gridState?.findIndex((grid: any) => grid.id === action.payload.control.id);
                const gridState = state.internal.gridState[action.payload.control.id];
                gridState.pageData = getPageData(
                    action.payload.pageNumber,
                    10, // GRID_PAGE_SIZE
                    gridState.filteredData
                );
                state.internal.gridState[action.payload.control.id] = gridState;
            }
            break;

        case 'SHOW_ERRORS':
            state.flags.showFormErrors += 1;
            break;

        case 'HIDE_ERRORS':
            state.flags.showFormErrors = 0;
            break;

        case 'SET_FIELD_VALIDATION_ERRORS':
            state.formValidationErrors[action.payload.dataKey] = action.payload.errorMessages;
            break;

        case 'SET_ALL_FIELD_VALIDATION_ERRORS':
            state.formValidationErrors = action.payload;
            break;

        case 'SET_BUSINESS_VALIDATION_ERRORS':
            state.businessValidationErrors = action.payload;
            break;

        case 'RESET_VALIDATION_ERRORS':
            state.formValidationErrors = {};
            state.businessValidationErrors = [];
            state.flags.showFormErrors = 0;
            break;

        case 'REFRESH_DATA':
        case 'POST_SAVE_RESPONSE_DATA':
            state.data = action.payload.data;
            break;

        case 'SET_FILTERED_DATA':
            state.data.filteredData = action.payload;
            break;

        case 'SET_INTERNAL_STATE':
            state.internal[action.payload.key] = action.payload.value;
            break;

        case 'UPDATE_ALBUM_CONTENT_SET':
            // Assuming action.payload.dataKey is 'memories.albumContentSet' and
            // action.payload.value is the updated array of albumContentSet
            const { dataKey, value } = action.payload;

            // Deep clone state.data to avoid direct mutation
            const newData = JSON.parse(JSON.stringify(state.data));

            // Update the specific part of the state
            // This assumes dataKey is a string like 'memories.albumContentSet'
            const keys = dataKey.split('.');
            let current = newData;
            for (let i = 0; i < keys.length - 1; i++) {
                current = current[keys[i]];
            }
            current[keys[keys.length - 1]] = value;

            return {
                ...state,
                data: newData,
            };
        case 'SET_MODAL_FLAG':
            state.modalFlags[action.payload.key] = action.payload.value;
            break;
         case 'SET_MODAL_DATA':
                const newModalData = {
                    ...state.modalData,
                    title: action.payload.title,data: action.payload.data,parentDataKey : action.payload.parentDataKey 
                };
                return {
                    ...state,
                    modalData: newModalData
                };break;
        case 'TOGGLE_MODAL_VISIBILITY':
            const newModalFlags = { ...state.modalFlags };
            // Set the specified modal to the opposite of its current value
            newModalFlags[action.payload.modalName] = !newModalFlags[action.payload.modalName];
            // If the specified modal is set to true, set all other modals to false
            if (newModalFlags[action.payload.modalName]) {
                for (const modalName in newModalFlags) {
                    if (modalName !== action.payload.modalName) {
                        newModalFlags[modalName] = false;
                    }
                }
            }
            return {
                ...state,
                modalFlags: newModalFlags,
            };
            break;
        default:
            throw new Error();
    }
};

export default smartReducer;
