import { useContext, useEffect, useState } from 'react';
import { SmartContext } from '../../../library/Core/SmartContext';
import { getDomainValueForCode } from '../../../library/Core/SmartFunctions';
import { DomainElement } from '../../../library/Core/SmartTypes';
import AlumniDirectoryService from './AlumniDirectoryService';
import SessionContext from '../../../library/Core/SessionContext';
import FileControl from '../../../library/SimpleControls/FileController';
import { Tooltip } from 'bootstrap';

interface ExpandedControls {
    [key: string]: boolean;
}

const DirectorySearchControl = () => {
    const uniqueCodes = new Set();
    const { state, dispatch } = useContext(SmartContext);
    //const userTypeCode = sessionStorage.getItem('user-type-code');
    const { sessionState, sessionDispatch } = useContext(SessionContext);
    const userTypeCode = sessionState?.userTypeCode;
    const [performSearch, setPerformSearch] = useState(0);
    const [displayMode, setDisplayMode] = useState('CURRENT');
    const [showAdditionalFilters, setShowAdditionalFilters] = useState(false);
    const [searchTerms, setSearchTerms] = useState({} as any);
    const [showFilters, setShowFilters] = useState(true);
    const schoolTypes = ['SCHOOL', 'PRE_SCHOOL', 'K12_SCHOOL'];
    // const [isUGSelected, setIsUGSelected] = useState(sessionState?.instituteTypeCode === 'K12_SCHOOL' ? true : false); // if institute type will be K12 then by default UG will be selected else PG
    const [isUGSelected, setIsUGSelected] = useState(schoolTypes.includes(sessionState?.instituteTypeCode ?? '') ? true : false);

    //const searchFilterDomains = state?.data?.searchDomain;
    const [searchFilterDomains, setSearchFilterDomains] = useState(state?.data?.searchDomain);
    const ugUniversityId = state?.data?.searchDomain?.universityId;
    const pgUniversityId = state?.data?.searchDomain?.pgUniversityId;

    let displayClearAll = false;

    // Clone the filterConfig to modify it
    const modifiedFilterConfig = { ...AlumniDirectoryService.filterConfig };

    // alert(sessionState?.instituteTypeCode);
    // Update isAdditional property for entranceTestCode
    if (schoolTypes.includes(sessionState?.instituteTypeCode ?? '')) {
        if (modifiedFilterConfig['entranceTestCode']) {
            modifiedFilterConfig['entranceTestCode'].isAdditional = true;
        }

        if (modifiedFilterConfig['fieldOfStudyCode']) {
            modifiedFilterConfig['fieldOfStudyCode'].isVisible = true;
        }
    }

    const filters: any = Object.values(modifiedFilterConfig).filter(
        (filter: any) => filter.applicableFor === userTypeCode || filter.applicableFor === 'BOTH'
    );

    // const filters: any = Object.values(AlumniDirectoryService.filterConfig).filter(
    //     (filter: any) => filter.applicableFor === userTypeCode || filter.applicableFor === 'BOTH'
    // );

    useEffect(() => {
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('.defaultTooltip'));
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl);
        });

        if (!isUGSelected) {
            // Set search domains to PG university options
            setSearchFilterDomains((prevDomains: any) => ({
                ...prevDomains,
                universityId: pgUniversityId, // Load PG university options
            }));
        }
    }, []);

    const handleToggleChange = (event: any) => {
        const isChecked = event.target.checked;
        setIsUGSelected(isChecked); // Update the state based on toggle selection

        if (isChecked === false) {
            // Assuming false means PG is selected
            dispatch({
                type: 'CHECKBOX_DELETE_ALL_RECORD_IN_ARRAY',
                payload: {
                    dataKey: 'filters.universityId',
                    value: null, // Not needed for clearing
                },
            });
            // Optionally reset UG selections or update state as needed
            setSearchFilterDomains({
                ...searchFilterDomains,
                universityId: pgUniversityId,
            });
        } else {
            dispatch({
                type: 'CHECKBOX_DELETE_ALL_RECORD_IN_ARRAY',
                payload: {
                    dataKey: 'filters.pgUniversityId',
                    value: null, // Not needed for clearing
                },
            });
            setSearchFilterDomains({
                ...searchFilterDomains,
                universityId: ugUniversityId,
            });
        }

        // Trigger search with updated filters
        setPerformSearch((prev) => prev + 1);
    };

    useEffect(() => {
        const criteria =
            performSearch === 0
                ? { ...AlumniDirectoryService.getCriteriaForSelectedCard('totalJoinedAlumni', state), ...state.data.filters }
                : state.data.filters;
        AlumniDirectoryService.performSearchWithUserSelectedCriteria(criteria, state, dispatch);

        // Note: After the initial load, Juniors, Seniors, Batch mates run based on the selected criteria. So removing the searchBy.
        if (state.data.searchBy.length > 0) {
            dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'filters.context.searchBy', value: '' } });
            dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'searchBy', value: '' } });
        }
    }, [performSearch]);

    const updateFilters = (type: string, dataKey: string, value: any) => {
        dispatch({ type, payload: { dataKey, value, errorMessages: [] } });
        setPerformSearch((prev) => prev + 1);
    };

    const handleSearchListItemSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value, checked } = event.target;
        const type = checked ? 'CHECKBOX_ADD_NEW_RECORD_IN_ARRAY' : 'DELETE_RECORD_IN_SIMPLE_ARRAY';

        let updatedId = id; // Declare a new variable to hold the updated id for UG/PG heigher study paramater
        if (id === 'pgUniversityId' || id === 'universityId') {
            if (isUGSelected === false) {
                // updateFilters('CHECKBOX_ADD_NEW_RECORD_IN_ARRAY', `filters.pgUniversityId`, value);
                updateFilters('CHECKBOX_DELETE_ALL_RECORD_IN_ARRAY', `filters.universityId`, null);
                updateFilters(type, `filters.pgUniversityId`, value); // Use updatedId in the function call
                return;
                //dispatch({ type: 'DELETE_RECORD_IN_SIMPLE_ARRAY', payload: { dataKey: 'filters.universityId', value: [] } });

                // updateFilters(`CHECKBOX_ADD_NEW_RECORD_IN_ARRAY`, `filters.universityId`, []); // Use updatedId in the function call
                //updateFilters('CONTROL_VALUE_CHANGE', `filters.universityId`, event.target.checked ? value : null);
                //updatedId = 'pgUniversityId'; // Update the id if isUGSelected is false, if value is false then in param we will send pgUniversityId filter
            } else if (isUGSelected === true) {
                updateFilters('CHECKBOX_DELETE_ALL_RECORD_IN_ARRAY', `filters.pgUniversityId`, null);
                // updateFilters('DELETE_RECORD_IN_SIMPLE_ARRAY', `filters.universityId`, value);
                // updateFilters('CHECKBOX_ADD_NEW_RECORD_IN_ARRAY', `filters.universityId`, value);

                updateFilters(type, `filters.universityId`, value); // Use updatedId in the function call
                return;
            }
        } else {
            updateFilters(type, `filters.${updatedId}`, value); // Use updatedId in the function call
        }
    };

    const handlePrestigiousCollegeSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateFilters('CONTROL_VALUE_CHANGE', `filters.prestigiousCollege`, event.target.checked ? 1 : null);
    };

    const handleSimilarSkillsSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const skills = event.target.checked ? AlumniDirectoryService.getSkills() : [];
        updateFilters('CONTROL_VALUE_CHANGE', `filters.skills`, skills);
    };

    const handleSameNextLevelInterestsSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const skills = event.target.checked ? AlumniDirectoryService.getSkillToTarget() : [];
        updateFilters('CONTROL_VALUE_CHANGE', `filters.skillToTarget`, skills);
    };

    const handleShowAdditionalFilter = (event: any) => {
        event.preventDefault();
        setShowAdditionalFilters(!showAdditionalFilters);
    };

    const handleSearchChange = (controlId: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerms((prevSearchTerms: any) => ({ ...prevSearchTerms, [controlId]: event.target.value }));
    };

    const getDomainCategoryCodeForKey = (key: string) => {
        return (
            state.formConfig?.sectionRepository
                .find((section) => section.id === 'filters')
                ?.controlGroup?.find((control) => control.id === key)?.props.domainCategoryCode ?? ''
        );
    };

    const handleAppliedFilterClick = (event: any, key: string, value: string) => {
        event.preventDefault();

        // Remove the searchBy after the initial load. User can remove the years.
        if (state.data.searchBy.length > 0) updateFilters('CONTROL_VALUE_CHANGE', `filters.context.searchBy`, '');

        updateFilters('DELETE_RECORD_IN_SIMPLE_ARRAY', `filters.${key}`, value);
    };

    const handleClearAllFilterClick = (event: any) => {
        event.preventDefault();
        if (state.data.searchBy.length > 0) updateFilters('CONTROL_VALUE_CHANGE', `filters.context.searchBy`, '');

        const defaultSearchCriteria = AlumniDirectoryService.getDefaultSearchCriteria('');
        const criteriaForSelectedCard = AlumniDirectoryService.getCriteriaForSelectedCard('totalJoinedAlumni', state);
        const criteria = { ...defaultSearchCriteria, ...criteriaForSelectedCard };

        updateFilters('CONTROL_VALUE_CHANGE', `filters`, criteria);
    };

    const handleCardViewModeChange = () => {
        const cardViewMode = state.data?.cardViewMode === 'CURRENT' ? 'OUTLOOK' : 'CURRENT';
        setDisplayMode(cardViewMode);
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'cardViewMode', value: cardViewMode } });
    };

    const handleCURRENTlyWorking = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateFilters('CONTROL_VALUE_CHANGE', `filters.workingAlumni`, event.target.checked ? 1 : null);
    };

    const handleTopCompanies = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateFilters('CONTROL_VALUE_CHANGE', `filters.topCompanies`, event.target.checked ? 1 : null);
    };

    const handleNotable = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateFilters('CONTROL_VALUE_CHANGE', `filters.isNotable`, event.target.checked ? 1 : null);
    };

    const handleMentors = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateFilters('CONTROL_VALUE_CHANGE', `filters.isMentor`, event.target.checked ? 1 : null);
    };

    const handleHeigherStudySelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateFilters('CONTROL_VALUE_CHANGE', `filters.heigherStudy`, event.target.checked ? 1 : null);
    };

    const [isOpen, setOpen] = useState(false);

    const handleFilterToggle = () => {
        setOpen(!isOpen);
        setShowAdditionalFilters(!showAdditionalFilters);
    };

    const [expandedControls, setExpandedControls] = useState<ExpandedControls>({});

    // Function to toggle the expanded state of a control
    const toggleControl = (controlId: string) => {
        console.log('controlId', controlId);
        setExpandedControls((prevExpandedControls) => ({
            [controlId]: !prevExpandedControls[controlId],
        }));
    };

    console.log('expandedControls', expandedControls);

    return (
        <div>
            <section className="alumni_directory_filter container-fluid">
                <div className="max-1140 mx-auto">
                    <div className="JobInternshipListing">
                        <div className="ji_filtersec aos-init aos-animate">
                            <div className="mobileFilter d-flex justify-content-between align-items-center d-md-none mobileonly text-end">
                                <button
                                    className="btn-filter btn-unscroll font-18 font-500 w-100"
                                    type="button"
                                    onClick={handleFilterToggle}
                                    aria-expanded="true"
                                    aria-controls="filter_collapse">
                                    <i className="icon-filter"></i>
                                    <span>Filters</span>
                                    {/* <span> Selected Options (8)<i className="icon-right-arrow"></i></span> */}
                                </button>
                            </div>

                            <div className={`accordion collapse show directory_wrap ${isOpen ? 'open' : ''}`} id="filter_collapse">
                                <div className="filters-head">
                                    <h3
                                        className="font-18 font-500 mb-0"
                                        role="button"
                                        onClick={() => setShowFilters(!showFilters)}
                                        data-bs-toggle="collapse"
                                        data-bs-target="#accordionFilters"
                                        aria-controls="accordionFilters"
                                        aria-expanded={showFilters ? 'true' : 'false'}>
                                        <span className="icon-filter"></span>
                                        <span>Filters</span>
                                        <i className="btn-md-filter icon-right-arrow d-none d-md-block"></i>
                                    </h3>
                                    <a
                                        className="btn-clear btn-tab-clear d-block d-md-none"
                                        role="button"
                                        onClick={handleClearAllFilterClick}>
                                        Clear
                                    </a>
                                    <div className="filter-switch-wrap">
                                        <div className="form-check form-switch filter-switch d-flex align-items-center p-0">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                role="button"
                                                onClick={handleCardViewModeChange}
                                                id="flexSwitchView"
                                                checked={state?.data?.cardViewMode === 'CURRENT' ? true : false}
                                            />
                                            <label className="CURRENTView" htmlFor="flexSwitchView">
                                                CURRENT View
                                            </label>
                                            <label className="outcomeView" htmlFor="flexSwitchView">
                                                Outcome View
                                            </label>
                                        </div>
                                        <b
                                            className="icon-info ms-2 text-dark-blue defaultTooltip"
                                            data-bs-toggle="tooltip"
                                            title="The outcome view shows what path the alumni took up after the program completion, while the current view shows their latest status regarding education or work."></b>
                                    </div>
                                </div>

                                {showFilters && (
                                    <div
                                        className="accordion-collapse collapse show"
                                        id="accordionFilters"
                                        data-bs-parent="filter_collapse">
                                        <div className="filterGroup mt-3">
                                            {Object.values(filters)
                                                .filter((control: any) => !control.isAdditional && control.isVisible)
                                                .map((control: any, index: number) => (
                                                    <div key={control.id} className={control.class}>
                                                        <div className="filterHeader">
                                                            <div className="d-flex justify-content-between align-items-center position-relative">
                                                                <button
                                                                    className="btn-nostyle text-start flex-1 d-flex justify-content-between align-items-center"
                                                                    type="button"
                                                                    onClick={() => toggleControl(control.id)}
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target={`#collapse${control.id}`}
                                                                    aria-expanded={
                                                                        !isOpen
                                                                            ? 'true'
                                                                            : isOpen && (index === 0 || expandedControls[control.id])
                                                                            ? 'true'
                                                                            : 'false'
                                                                    }>
                                                                    <span className="font-18 font-600">{control.label}</span>
                                                                </button>
                                                                {control.id === 'universityId' ? (
                                                                    <div className="filter-switch-wrap d-none d-md-flex">
                                                                        <div className="form-check form-switch filter-switch d-flex align-items-center p-0">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                checked={isUGSelected}
                                                                                onChange={handleToggleChange}
                                                                                role="switch"
                                                                                id="flexSwitchHigherStudies"
                                                                            />
                                                                            <label
                                                                                className="currentView"
                                                                                htmlFor="flexSwitchHigherStudies">
                                                                                UG
                                                                            </label>
                                                                            <label
                                                                                className="outcomeView"
                                                                                htmlFor="flexSwitchHigherStudies">
                                                                                PG
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </div>
                                                        </div>
                                                        {/* <div className="collapse show" id={`collapse${control.id}`}> */}
                                                        <div
                                                            id={`collapse${control.id}`}
                                                            className={`accordion-collapse collapse ${
                                                                !isOpen
                                                                    ? 'show'
                                                                    : isOpen && (index === 0 || expandedControls[control.id])
                                                                    ? 'show'
                                                                    : ''
                                                            }`}
                                                            data-bs-parent="#accordionFilters">
                                                            {/* mobile view toogle UG/PG */}
                                                            {control.id === 'universityId' ? (
                                                                <div className="filter-switch-wrap d-flex justify-content-end d-md-none w-100 position-static mb-2">
                                                                    <div className="form-check form-switch filter-switch d-flex align-items-center p-0">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            checked={isUGSelected}
                                                                            onChange={handleToggleChange}
                                                                            role="switch"
                                                                            id="flexSwitchHigherStudies"
                                                                        />
                                                                        <label className="currentView" htmlFor="flexSwitchHigherStudies">
                                                                            UG
                                                                        </label>
                                                                        <label className="outcomeView" htmlFor="flexSwitchHigherStudies">
                                                                            PG
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                ''
                                                            )}

                                                            <div className="filterSearchblk">
                                                                <form className="d-flex align-items-center filterSearch position-relative">
                                                                    <input
                                                                        className="form-control flex-1 font-14 font-500 w-100"
                                                                        type="search"
                                                                        placeholder="Search"
                                                                        aria-label="Search"
                                                                        name={`${control.id}_Search`}
                                                                        id={`${control.id}_Search`}
                                                                        onChange={handleSearchChange(control.id)}
                                                                    />
                                                                    <button
                                                                        className="btn icon-search"
                                                                        type="button"
                                                                        id={`${control.id}_Btn`}></button>
                                                                </form>
                                                            </div>
                                                            <div className="card card-body">
                                                                <div className="overflow-hidden">
                                                                    <div className="scrollMe" id="PassoutYear_Searchdiv">
                                                                        {searchFilterDomains[control.id]
                                                                            ?.filter((domain: DomainElement) =>
                                                                                domain.value
                                                                                    .toLowerCase()
                                                                                    .includes((searchTerms[control.id] || '').toLowerCase())
                                                                            )
                                                                            .map((domain: DomainElement) => {
                                                                                // Check if the code is already rendered
                                                                                if (
                                                                                    control.id === 'programId' &&
                                                                                    uniqueCodes.has(domain.code)
                                                                                ) {
                                                                                    return null; // Skip rendering if the code is already rendered
                                                                                }
                                                                                // Add the code to the set
                                                                                if (control.id === 'programId') {
                                                                                    uniqueCodes.add(domain.code);
                                                                                }
                                                                                // Render the checkbox
                                                                                return (
                                                                                    <div key={domain.code} className="form-check">
                                                                                        <input
                                                                                            id={control.id}
                                                                                            className="form-check-input me-2 Passout_Class"
                                                                                            type="checkbox"
                                                                                            value={domain.code}
                                                                                            name="Passout_Name"
                                                                                            checked={
                                                                                                control.id === 'universityId'
                                                                                                    ? state?.data?.filters[
                                                                                                          isUGSelected
                                                                                                              ? 'universityId'
                                                                                                              : 'pgUniversityId'
                                                                                                      ]
                                                                                                          ?.map(String)
                                                                                                          .includes(String(domain.code))
                                                                                                    : state?.data?.filters[control.id]
                                                                                                          ?.map(String)
                                                                                                          .includes(String(domain.code))
                                                                                            }
                                                                                            onChange={handleSearchListItemSelect}
                                                                                        />
                                                                                        <label
                                                                                            className="form-check-label font-16 font-400 flex-1 ps-1 pt-1"
                                                                                            htmlFor="2022">
                                                                                            {domain.value}
                                                                                        </label>
                                                                                    </div>
                                                                                );
                                                                            })
                                                                            // Remove null values (skipped duplicates)
                                                                            .filter((checkbox: any) => checkbox !== null)}
                                                                        {/* {
                                                                            searchFilterDomains[control.id]
                                                                                ?.filter((domain: DomainElement) =>
                                                                                    domain.value
                                                                                        .toLowerCase()
                                                                                        .includes(
                                                                                            (searchTerms[control.id] || '').toLowerCase()
                                                                                        )
                                                                                )
                                                                                .map((domain: DomainElement) => (
                                                                                    
                                                                                    <div key={domain.code} className="form-check">
                                                                                        <input
                                                                                            id={control.id}
                                                                                            className="form-check-input me-2 Passout_Class"
                                                                                            type="checkbox"
                                                                                            value={domain.code}
                                                                                            name="Passout_Name"
                                                                                            checked={
                                                                                                control.id === 'universityId'
                                                                                                    ? state?.data?.filters[
                                                                                                          isUGSelected
                                                                                                              ? 'universityId'
                                                                                                              : 'pgUniversityId'
                                                                                                      ]
                                                                                                          ?.map(String)
                                                                                                          .includes(String(domain.code))
                                                                                                    : state?.data?.filters[control.id]
                                                                                                          ?.map(String)
                                                                                                          .includes(String(domain.code))
                                                                                            }
                                                                                            //     ?.map(String)
                                                                                            //     .includes(String(domain.code))}
                                                                                            // checked={state?.data?.filters[control.id]
                                                                                            //     ?.map(String)
                                                                                            //     .includes(String(domain.code))}
                                                                                            onChange={handleSearchListItemSelect}
                                                                                        />
                                                                                        <label
                                                                                            className="form-check-label font-16 font-400 flex-1 ps-1 pt-1"
                                                                                            htmlFor="2022">
                                                                                            {domain.value}
                                                                                        </label>
                                                                                    </div>
                                                                                )) // Remove duplicates based on code only when control.id is 'programId'
                                                                        } */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}

                                            <div className="showProfiles_wrap d-none d-md-flex">
                                                <div className="showProfiles_left">
                                                    <h3>Show Profiles</h3>
                                                    <div className="check_list" id="ProfileNames">
                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                className="form-check-input Profile_Class"
                                                                name="Profile_Name"
                                                                type="checkbox"
                                                                id="inlineCheckbox1"
                                                                value="From Prestigious Colleges"
                                                                onChange={handleHeigherStudySelect}
                                                            />
                                                            <label className="form-check-label" htmlFor="inlineCheckbox1">
                                                                Pursuing Higher studies
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                className="form-check-input Profile_Class"
                                                                name="Profile_Name"
                                                                type="checkbox"
                                                                id="inlineCheckbox2"
                                                                value="From Prestigious Colleges"
                                                                onChange={handlePrestigiousCollegeSelect}
                                                            />
                                                            <label className="form-check-label" htmlFor="inlineCheckbox2">
                                                                Prestigious Colleges
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                className="form-check-input Profile_Class"
                                                                name="Profile_Name"
                                                                type="checkbox"
                                                                id="inlineCheckbox3"
                                                                value="From Prestigious Colleges"
                                                                onChange={handleCURRENTlyWorking}
                                                            />
                                                            <label className="form-check-label" htmlFor="inlineCheckbox3">
                                                                Currently Working
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                className="form-check-input Profile_Class"
                                                                name="Profile_Name"
                                                                type="checkbox"
                                                                id="inlineCheckbox4"
                                                                value="From Prestigious Colleges"
                                                                onChange={handleTopCompanies}
                                                            />
                                                            <label className="form-check-label" htmlFor="inlineCheckbox4">
                                                                Top Companies
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                className="form-check-input Profile_Class"
                                                                name="Profile_Name"
                                                                type="checkbox"
                                                                id="inlineCheckbox4"
                                                                value="From Prestigious Colleges"
                                                                onChange={handleNotable}
                                                            />
                                                            <label className="form-check-label" htmlFor="inlineCheckbox4">
                                                                Marked as Notable
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                className="form-check-input Profile_Class"
                                                                name="Profile_Name"
                                                                type="checkbox"
                                                                id="inlineCheckbox4"
                                                                value="From Prestigious Colleges"
                                                                onChange={handleMentors}
                                                            />
                                                            <label className="form-check-label" htmlFor="inlineCheckbox4">
                                                                Mentors
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="additionalfilter_wrap last mb-20">
                                                <a
                                                    href="javascript:;"
                                                    className="btn-additionalfilter my-3 collapsed"
                                                    onClick={() => setShowAdditionalFilters(!showAdditionalFilters)}
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#additionalfilter"
                                                    aria-controls="additionalfilter"
                                                    aria-expanded={showAdditionalFilters ? 'true' : 'false'}>
                                                    <span>ADDITIONAL FILTER</span>
                                                    <i className="btn-md-filter icon-right-arrow d-none d-md-block"></i>
                                                </a>
                                                {showAdditionalFilters && (
                                                    <div className="collapse show" id="additionalfilter">
                                                        <div className="filterGroup">
                                                            {Object.values(filters)
                                                                .filter((control: any) => control.isAdditional)
                                                                .map((control: any, index: number) => (
                                                                    <div key={control.id} className={control.class}>
                                                                        <div className="filterHeader">
                                                                            <div className="d-flex justify-content-between align-items-center position-relative">
                                                                                {/* <button
                                                                                    className="btn-nostyle text-start flex-1 d-flex justify-content-between align-items-center"
                                                                                    type="button"
                                                                                    data-bs-toggle="collapse"
                                                                                    data-bs-target={`#collapse${control.id}`}
                                                                                    aria-expanded={!isOpen ? 'true' : 'false'}
                                                                                    aria-controls={`collapse${control.id}`}>
                                                                                    <span className="font-18 font-600">
                                                                                        {control.label}
                                                                                    </span>
                                                                                </button> */}
                                                                                <button
                                                                                    className="btn-nostyle text-start flex-1 d-flex justify-content-between align-items-center"
                                                                                    type="button"
                                                                                    onClick={() => toggleControl(control.id)}
                                                                                    data-bs-toggle="collapse"
                                                                                    data-bs-target={`#collapse${control.id}`}
                                                                                    aria-expanded={
                                                                                        !isOpen
                                                                                            ? 'true'
                                                                                            : isOpen && expandedControls[control.id]
                                                                                            ? 'true'
                                                                                            : 'false'
                                                                                    }
                                                                                    aria-controls={`collapse${control.id}`}>
                                                                                    <span className="font-18 font-600">
                                                                                        {control.label}
                                                                                    </span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                        {/* <div className="collapse show" id={`collapse${control.id}`}> */}
                                                                        <div
                                                                            id={`collapse${control.id}`}
                                                                            className={`accordion-collapse collapse ${
                                                                                !isOpen
                                                                                    ? 'show'
                                                                                    : expandedControls[control.id] && isOpen
                                                                                    ? 'show'
                                                                                    : ''
                                                                            }`}
                                                                            data-bs-parent="#accordionFilters">
                                                                            <div className="filterSearchblk">
                                                                                <form className="d-flex align-items-center filterSearch position-relative">
                                                                                    <input
                                                                                        className="form-control flex-1 font-14 font-500 w-100"
                                                                                        type="search"
                                                                                        placeholder="Search"
                                                                                        aria-label="Search"
                                                                                        name={`${control.id}_Search`}
                                                                                        id={`${control.id}_Search`}
                                                                                        onChange={handleSearchChange(control.id)}
                                                                                    />
                                                                                    <button
                                                                                        className="btn icon-search"
                                                                                        type="button"
                                                                                        id={`${control.id}_Btn`}></button>
                                                                                </form>
                                                                            </div>
                                                                            <div className="card card-body">
                                                                                <div className="overflow-hidden">
                                                                                    <div className="scrollMe" id="PassoutYear_Searchdiv">
                                                                                        {searchFilterDomains[control.id]
                                                                                            ?.filter((domain: DomainElement) =>
                                                                                                domain.value
                                                                                                    .toLowerCase()
                                                                                                    .includes(
                                                                                                        (
                                                                                                            searchTerms[control.id] || ''
                                                                                                        ).toLowerCase()
                                                                                                    )
                                                                                            )
                                                                                            .map((domain: DomainElement) => (
                                                                                                <div
                                                                                                    key={domain.code}
                                                                                                    className="form-check">
                                                                                                    <input
                                                                                                        id={control.id}
                                                                                                        className="form-check-input me-2 Passout_Class"
                                                                                                        type="checkbox"
                                                                                                        value={domain.code}
                                                                                                        name="Passout_Name"
                                                                                                        checked={state?.data?.filters[
                                                                                                            control.id
                                                                                                        ]
                                                                                                            ?.map(String)
                                                                                                            .includes(String(domain.code))}
                                                                                                        onChange={
                                                                                                            handleSearchListItemSelect
                                                                                                        }
                                                                                                    />
                                                                                                    <label
                                                                                                        className="form-check-label font-16 font-400 flex-1 ps-1 pt-1"
                                                                                                        htmlFor="2022">
                                                                                                        {domain.value}
                                                                                                    </label>
                                                                                                </div>
                                                                                            ))}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="d-flex d-md-none filter_close_apply">
                                    <a
                                        className="btn-filter-close collapsed"
                                        onClick={handleFilterToggle}
                                        href="javascript:;"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#filter_collapse"
                                        aria-expanded="false"
                                        aria-controls="filter_collapse">
                                        Close
                                    </a>
                                    {/* <a className="btn-filter-apply" href="javascript:;">Apply</a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="max-1140 mx-auto mb-3">
                    <div className="row">
                        <div className="filterresult border-0 mb-0">
                            <ul>
                                {Object.keys(state?.data?.filters).map(
                                    (key) =>
                                        Array.isArray(state?.data?.filters[key]) &&
                                        state?.data?.filters[key].map((item: any) => {
                                            const domainCategoryCode: string = getDomainCategoryCodeForKey(key) as string;
                                            if (!domainCategoryCode) return;
                                            const displayValue = getDomainValueForCode(item, domainCategoryCode, state);
                                            if (!displayValue) return;
                                            if (!displayClearAll) displayClearAll = true;
                                            return (
                                                <li>
                                                    <a href="#" key={`${key}-${item}`}>
                                                        {displayValue}
                                                        <i
                                                            className="icon-cross"
                                                            onClick={(event) => handleAppliedFilterClick(event, key, item)}></i>
                                                    </a>
                                                </li>
                                            );
                                        })
                                )}
                                {displayClearAll && (
                                    <li className="clear">
                                        <a className="btn-clear" role="button" onClick={handleClearAllFilterClick}>
                                            Clear All
                                        </a>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default DirectorySearchControl;
