import { useContext } from 'react';
import SessionContext from '../../../library/Core/SessionContext';
import PageFooterControl from '../../../library/SimpleControls/PageFooterControl';

const InstituteSelectionLayout = (data: any) => {
    const { sessionState } = useContext(SessionContext);
    const { handleSelection, institutesLength } = data;
    const profileCount =
        sessionState?.institutes.filter(
            (institute: any) => institute?.userStatusCode !== 'INACTIVE' && institute.userStatusCode !== 'RAW_PROFILE'
        ).length ?? 2;

    if (institutesLength <= 1) {
        return null;
    }

    return (
        <>
            <header>
                <div className="container max-1140">
                    <div className="row justify-content-between align-items-center custom-header">
                        <div className="univarsity_logo manager_profile"></div>

                        <div className="headerRightSide">
                            <ul>
                                <li>
                                    <label className="">
                                        <span className="font-16 font-500">
                                            Hey,
                                            <span>&nbsp;{`${sessionState?.firstName} ${sessionState?.lastName}`}</span>
                                        </span>
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
            <div className="main flex-1">
                <div className="bg-purple alumni-networks-header">
                    <div className="max-1140 mx-auto py-5">
                        <div className="pt-4 d-flex flex-column align-items-center text-center">
                            <h1>
                                Your account is associated
                                <br />
                                with {profileCount} alumni networks!
                            </h1>
                            <p>
                                Select the network for which you wish to access the information! You can switch views later to access the
                                other network{' '}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="alumni-networks-block-wrap pb-5">
                    <div className="max-1140 mx-auto position-relative">
                        <div className="row g-4">
                            {sessionState?.institutes
                                .filter(
                                    (institute: any) =>
                                        institute?.userStatusCode != 'INACTIVE' && institute?.userStatusCode != 'RAW_PROFILE'
                                )
                                .map((institute: any) => (
                                    <div
                                        key={institute.instituteId}
                                        className={`col-md-4 col-sm-6${profileCount === 1 ? ' center-block' : ''}`}>
                                        <div className="alumni-networks-block">
                                            <img src={institute.instituteLogo} width="200" className="img-fluid" alt="" />
                                            <h4>{institute.instituteName.charAt(0).toUpperCase() + institute.instituteName.slice(1)}</h4>
                                            <button className="btn btn-green" onClick={() => handleSelection(institute)}>
                                                <span>Enter</span>
                                            </button>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
            <PageFooterControl />
        </>
    );
};

export default InstituteSelectionLayout;
