import React, { useEffect, useState } from 'react';
import axios from 'axios';

const AddGoogleReview: React.FC = () => {
    let sesinstituteId = 0;
    const userDataString = sessionStorage.getItem('user');
    if (userDataString) {
        const userData = JSON.parse(userDataString);
        sesinstituteId = userData.primaryInstitute;
    }
    const schoolId = sesinstituteId;

    const urlParams = new URLSearchParams(window.location.search);
    const templateId = urlParams.get('template_id');    
    const [numberOfStudents, setNumberOfStudents] = useState<number>(0);
    const [successMessage, setSuccessMessage] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');

    useEffect(() => {
        const fetchDataAndSendNow = async () => {
            console.log("fetchDataAndSendNow function called");
            const selectedStudentsString = sessionStorage.getItem('student_data_for_email');
            if (selectedStudentsString) {
                const selectedStudents: string[] = JSON.parse(selectedStudentsString);
                const numberOfStudents = selectedStudents.length;
                setNumberOfStudents(numberOfStudents);

                console.log("Number of students selected:", numberOfStudents);

                try {
                    console.log("Attempting to update count...");
                    await axios.post(`${process.env.REACT_APP_BROADCAST_SERVER}/email/update-count`, {
                        audiencecount: numberOfStudents,
                        templateid: templateId,
                    });
                    await addLog(selectedStudents);
                    await sendNowData(); 
                    window.location.href = `/broadcast/sent-mail`;                  
                } catch (error: any) {
                    console.error('Error sending email:', error.response ? error.response.data : error.message);
                    setErrorMessage('Failed to send email.');
                }
            } else {
                console.log("No students data found in sessionStorage.");
            }
        };

        if (templateId) {
            fetchDataAndSendNow();
        } else {
            console.log("templateId is not available.");
        }
    }, [templateId]);

    const sendNowData = async () => {
        const currentDate = new Date();

        const dateOptions: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = currentDate.toLocaleDateString(undefined, dateOptions);

        const timeOptions: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true };
        const formattedTime = currentDate.toLocaleTimeString(undefined, timeOptions);

        const datetime = `${formattedDate} ${formattedTime}`;

        try {
            console.log("Attempting to send email...");
            await axios.post(`${process.env.REACT_APP_BROADCAST_SERVER}/email/send-now`, {
                templateid: templateId,
                sentDate: datetime,
            });
            console.log("Email sent successfully");
            setSuccessMessage('Email sent successfully');
        } catch (error: any) {
            console.error('Error sending email:', error.response ? error.response.data : error.message);
            setErrorMessage('Failed to send email.');
        }
    };

    const addLog = async (selectedStudents: string[]) => {
        try {
            console.log("Attempting to add email log...");
            await axios.post(`${process.env.REACT_APP_BROADCAST_SERVER}/email/add-email-log`, {
                student_id: selectedStudents,
                template_id: templateId,
                school_id: schoolId,
                temp_id: 4,
            });
         
        } catch (error: any) {
            console.error('Error logging email:', error.response ? error.response.data : error.message);
            setErrorMessage('Failed to log email.');
        }
    };

    return (
        <>
          
        </>
    );
};

export default AddGoogleReview;
