import axios from 'axios';
// import config from '../../../config/config';
import { isFormValid } from '../../../library/Core/SmartFunctions';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import logger from '../../../library/Core/logger';
import { axiosClient } from '../../../library/Service/axiosClient';

const isValidEmail = (email: string, emailRegex: any) => emailRegex.test(email);

// Helper function to validate password length
const isValidPasswordLength = (password: string, minLength: number, maxLength: number) => {
    const length = password ? password.length : 0;
    return length >= (minLength || 6) && length <= (maxLength || 50);
};
// Extracts regex pattern from state
const extractEmailRegex = (state: any) => {
    const pattern = state?.formConfig?.sectionRepository[0]?.controlGroup[0]?.props?.pattern;
    return pattern ? new RegExp(pattern) : /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
};

// Extracts password constraints from state
const extractPasswordConstraints = (state: any) => ({
    minLength: state?.formConfig?.sectionRepository[0]?.controlGroup[1]?.props?.minLength,
    maxLength: state?.formConfig?.sectionRepository[0]?.controlGroup[1]?.props?.maxLength,
});

const getinstituteTypeCode = async (instituteGroupId:any) => {
    const response = await axiosClient().post(`${process.env.REACT_APP_REPORTS_SERVER}/admin/get-institute-type`,{'groupId':instituteGroupId});
    if(response.status == 200){
        return response.data.instituteTypeCode;
    }
}   

export const onLogIn = async (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();
    isFormValid(state, dispatch) ? logger.log('Form is valid') : logger.log('Form is invalid. Please correct the errors.');

    if (!isFormValid(state, dispatch)) return alert('Please fill in the user name and password');

    const { registeredEmailId: email, password } = state?.data?.user || {};
    const emailRegex = extractEmailRegex(state);

    if (!isValidEmail(email, emailRegex)) {
        return alert('Please enter a valid email address');
    }

    const { minLength, maxLength } = extractPasswordConstraints(state);
    if (!isValidPasswordLength(password, minLength, maxLength)) {
        return alert('Please enter a valid password');
    }

    try {
        const response = await axios.post(`${process.env.REACT_APP_STUDENT_SERVER}/v1/auth/school-admin-login`, {
            registeredEmailId: email,
            password,
        });
        if (!response.data.token) {
            dispatch({ type: 'SET_BUSINESS_VALIDATION_ERRORS', payload: [response.data] });
            return;
        }
        console.log(response);
        const user = response.data as any;

        sessionStorage.setItem('user', JSON.stringify(response.data));
        sessionStorage.setItem('user-jwt-token', response.data.token);
        sessionStorage.setItem('user-logged-out', 'FALSE');

        localStorage.setItem('user', JSON.stringify(response.data));
        localStorage.setItem('user-jwt-token', response.data.token);
        localStorage.setItem('user-logged-out', 'FALSE');
        state.actions['loginClick'](true);

        let instituteTypeCode = '';
        if(response.data.userTypeCode == 'INSTITUTE_ADMIN')
        {
            instituteTypeCode = await getinstituteTypeCode(response.data.instituteGroupId);
        }
        sessionDispatch({
            type: 'SET',
            payload: {
                ...response.data,
                instituteLogo: localStorage.getItem('school-image'),
                instituteTypeCode: instituteTypeCode
            },
        });

        // navigateToLandingPage({
        //    user, state,
        // });
    } catch (error) {
        console.error('Login error', error);
    }
};

export const storeLoginInfoInSessionStorage = (user: any, schoolDomain: string) => {
    sessionStorage.setItem('user-id', user.id);
    sessionStorage.setItem('user-login-id', user.loginId ?? 448220);
    sessionStorage.setItem('user-jwt-token', user.token);
    sessionStorage.setItem('user-first-name', user.firstName);
    sessionStorage.setItem('user-last-name', user.lastName);
    sessionStorage.setItem('user-name', user.firstName + '' + user.lastName);
    sessionStorage.setItem('user-email-id', user.registeredEmailId);
    sessionStorage.setItem('user-school-id', user.schoolId);
    sessionStorage.setItem('user-profile-status-code', user.profileStatusCode);
    sessionStorage.setItem('user-status-code', user.userStatusCode);
    sessionStorage.setItem('user-school-group-id', user.schoolGroupId);
    sessionStorage.setItem('user-type-code', user.userTypeCode);
    sessionStorage.setItem('pass-out-year', user.passOutYear ?? 2010);
    sessionStorage.setItem('school-founding-year', user.schoolFoundingYear ?? 2000);
    sessionStorage.setItem('is-peer-reviewed', user.isPeerReviewed ?? false);
    sessionStorage.setItem('user-logged-out', 'FALSE');
    sessionStorage.setItem('user-profile-image', user.profileImage);

    localStorage.setItem('user-logged-out', 'FALSE');
    localStorage.setItem('user-type-code', user.userTypeCode);
    localStorage.setItem('univariety-user', JSON.stringify(user));
    localStorage.setItem('user-school-domain', schoolDomain);
    localStorage.setItem('user-jwt-token', user.token);

    if (user.userTypeCode == 'STUDENT') {
        //Student and school wise Product/test menu assign
        getProductsTestMenuStudent(user.schoolId, user.classCode);
    }
};

export const getProductsTestMenuStudent = async (school_id: any, class_code: any) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_COMMON_API}/test/get-test-menu-by-schoolid_campuscode`, {
            SchoolId: school_id,
            ClassCode: class_code,
        });
        if (response.data.success) {
            console.log(response.data.data);
            sessionStorage.setItem('product-menu-test', JSON.stringify(response.data.data));
        } else {
            console.error('Error:', response.data.message);
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};
