import React from 'react';

const MentionDropdown = ({ onSelect }: { onSelect: (value: string) => void }) => {
    const options = ["First_Name", "Last_Name", "Program_Name", "Program_Completion", "Program_Interested"];

    return (
        <div className="mention-dropdown">
            {options.map((option, index) => (
                <div key={index} onMouseDown={() => onSelect(option)} className="mention-option">
                    {option}
                </div>
            ))}
        </div>
    );
};

export default MentionDropdown;
