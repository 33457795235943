import { activateUserSession, isEmpty, isFormValid, setError } from '../../../../library/Core/SmartFunctions';
import { ControlOnChangeArguments, DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import logger from '../../../../library/Core/logger';
import { axiosClient } from '../../../../library/Service/axiosClient';
import { getDomainElementsForGivenYearRange } from '../../../../library/Service/domainService';
import ScreenNavigationService, { AlumniProfileEditScreens } from '../../../../services/screen-navigation.service';
import StudentService from '../../../../services/student.service';
import SessionContext from '../../../../library/Core/SessionContext';
import axios from 'axios';

export const onStartOrEndYearChange = (props: ControlOnChangeArguments, sessionState: any) => {
    const errorMessages = [] as any[];
    if (props.control.id === 'startYear') {
        props.dispatch({
            type: 'CONTROL_VALUE_CHANGE',
            payload: {
                dataKey: 'selectedInstitute.passOutYear',
                value: parseInt(props.value) + 1,
            },
        });

        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        let endYear = currentYear;
        let startYear = props.value;

        if (props.state.actions['sessionState'].programLevel === 'UG') {
            endYear = parseInt(props.value) + 5;
            startYear = parseInt(props.value) + 3;
        } else if (props.state.actions['sessionState'].programLevel === 'PG') {
            endYear = parseInt(props.value) + 3;
            startYear = parseInt(props.value) + 1;
        } else if (props.state.actions['sessionState'].programLevel === 'DOCTORAL') {
            endYear = parseInt(props.value) + 6;
            startYear = parseInt(props.value) + 3;
        } else {
            endYear = parseInt(props.value) + 1;
            startYear = parseInt(props.value) + 1;
        }

        if (props.state.actions['sessionState'].userTypeCode == 'ALUMNI' && endYear > currentYear) {
            endYear = currentYear;
        }

        props.dispatch({
            type: 'SET_DOMAIN',
            payload: {
                key: 'INSTITUTE_PASS_OUT_YEAR_CODE',
                value: getDomainElementsForGivenYearRange('INSTITUTE_PASS_OUT_YEAR_CODE', parseInt(startYear), endYear),
            },
        });
    }
};

export const handleNext = async (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();
    logger.log(state.data['profile']);
    const customValidationPassed = await isFormValidBasedOnCustomValidation(state, dispatch);
    // const errorKeysToIgnore = getErrorsToIgnore(state);
    // const isImpersonatedSession = sessionStorage.getItem('is-impersonated-session') === 'YES';

    // if (!isImpersonatedSession && (!isFormValid(state, dispatch /*, errorKeysToIgnore*/) || !customValidationPassed)) {
    if (!isFormValid(state, dispatch /*, errorKeysToIgnore*/) || !customValidationPassed) {
        dispatch({ type: 'SHOW_ERRORS' });
        alert('Please correct the form errors!');
        return;
    }
    console.log('sessionState');
    console.log(sessionState);
    let programLevel = sessionState.userTypeCode == 'SUPER_ADMIN' ? state.data.programLevel : sessionState.programLevel;
    let postProgramCompletionStatusCode = state?.data?.selectedInstitute?.postProgramCompletionStatusCode;
    if (postProgramCompletionStatusCode == 'WENT_FOR_HIGHER_STUDIES') {
        // postProgramCompletionStatusCode = state?.data?.selectedInstitute?.programId == "K12" ? "JOINED_UG" : "JOINED_PG";
        // postProgramCompletionStatusCode = sessionState.programLevel == "K12" ? "JOINED_UG" : "JOINED_PG";
        switch (programLevel) {
            // case '1':
            // case '2':
            // case '3':
            // case '4':
            // case '5':
            // case '6':
            // case '7':
            // case '8':
            // case '9':
            // case '10':
            // case '11':
            //     postProgramCompletionStatusCode = 'JOINED_SCHOOL';
            // break;
            case '12':
                postProgramCompletionStatusCode = 'JOINED_UG';
                break;

            case 'UG':
                postProgramCompletionStatusCode = 'JOINED_PG';
                break;

            case 'PG':
                postProgramCompletionStatusCode = 'JOINED_DOCTORAL';
                break;

            case 'Doctoral':
                postProgramCompletionStatusCode = 'JOINED_DOCTORAL';
                break;

            default:
                if (parseInt(programLevel) >= 1 && parseInt(programLevel) < 12) {
                    postProgramCompletionStatusCode = 'JOINED_SCHOOL';
                }
                break;
        }
    }

    let selectedInstitute = { ...state?.data?.selectedInstitute };
    selectedInstitute.postProgramCompletionStatusCode = postProgramCompletionStatusCode;

    // if outcomes is for ug/pg then set the start and end date
    console.log(selectedInstitute);
    let underGradCollegeJoined = { ...state?.data?.underGradCollegeJoined };
    let postGradCollegeJoined = { ...state?.data.postGradCollegeJoined };
    interface PostGradCollege {
        id: number;
        startYear: any;
        endYear: any;
        universityId: number;
        // Add other properties as needed
    }

    let postGradCollegeArray: PostGradCollege[] = [];

    for (const college of Object.values(postGradCollegeJoined)) {
        // Check if college is truthy and an object before spreading
        if (college && typeof college === 'object') {
            const mutableCollege: PostGradCollege = { ...(college as PostGradCollege) };
            postGradCollegeArray.push(mutableCollege);
        }
    }

    // console.log('typeof postGradCollegeArray');
    // console.log(typeof postGradCollegeArray); // Check the type of postGradCollegeArray
    // console.log(postGradCollegeArray); // Log the contents of postGradCollegeArray
    // console.log(postGradCollegeArray[0]?.startYear); // Log the value of startYear before modification

    // Now you can loop through the array and make necessary changes
    for (let i = 0; i < postGradCollegeArray.length; i++) {
        console.log(postGradCollegeArray[i]?.startYear);

        // Update startYear and endYear as needed
        if (postGradCollegeArray[i].universityId == sessionState.instituteId) {
            postGradCollegeArray[i].startYear = selectedInstitute.startYear;
            postGradCollegeArray[i].endYear = selectedInstitute.passOutYear;
        }
    }

    if (sessionState.programLevel === 'UG' && sessionState.instituteId == underGradCollegeJoined.universityId) {
        underGradCollegeJoined.startYear = selectedInstitute.startYear;
        underGradCollegeJoined.endYear = selectedInstitute.passOutYear;
    }

    const param: any = {
        id: state.routeInfo.id,
        selectedInstitute: selectedInstitute,
        userInstitutes: state?.data?.userInstitutes,
        profile: {
            ...state?.data?.profile,
            contactEmailId: state?.data?.profile.contactEmailId,
            contactMobileNumber: state?.data?.profile.contactMobileNumber,
            contactMobileCountryCode: state?.data?.profile.contactMobileCountryCode,
        },
        // alumniBreak: state?.data?.alumniBreak,
    };

    // Conditionally include underGradColleges parameter if underGradCollegeJoined is not null or empty
    if (!isEmpty(state?.data?.underGradCollegeJoined)) {
        param.underGradColleges = state?.data.underGradColleges;
        param.underGradCollegeJoined = underGradCollegeJoined;
        param.underGradCollegeApplied = state?.data.underGradCollegeApplied;
    }
    if (!isEmpty(state?.data?.postGradCollege)) {
        param.postGradCollege = state?.data.postGradCollege; // Assign postGradCollegeArray instead of postGradCollegeJoined
        param.postGradCollegeJoined = postGradCollegeJoined;
        param.postGradCollegeApplied = state?.data?.postGradCollegeApplied;
    }

    StudentService.pageSave(state.routeInfo.id, state.routeInfo.pageName, StudentService.toStudentEntity(param)).then((response) => {
        if (response?.status === 200) {
            // Send event to Moengage my basics
            sendMoEngageEvent(state?.data);

            let sessionStateData = { ...sessionState, pursuingEducationLevelCode: state?.data?.profile?.pursuingEducationLevelCode };
            let programLevel = sessionState?.programLevel;
            if (sessionState.userTypeCode == 'SUPER_ADMIN') {
                const selectedInstituteString = localStorage.getItem('selectedInstitute');
                const selectedInstituteData = JSON.parse('' + selectedInstituteString);
                programLevel = selectedInstituteData.programLevel;
            }
            sessionDispatch({ type: 'SET', payload: sessionStateData });
            const user_id = state.routeInfo.id;
            let redirectUrl = '';
            switch (state?.data?.selectedInstitute?.postProgramCompletionStatusCode) {
                case 'APPEARED_FOR_COMPETITIVE_EXAM':
                    redirectUrl = `/alumni/${user_id}/entrance-test`;
                    break;

                case 'ENTREPRENEURSHIP':
                    redirectUrl =
                        state?.data?.selectedInstitute?.userTypeCode == 'STUDENT'
                            ? `/alumni/${user_id}/job-preferences`
                            : `/alumni/${user_id}/entrepreneurship`;
                    break;

                case 'JOINED_COMPANY':
                    redirectUrl = `/alumni/${user_id}/job-outcome`;
                    break;

                case 'WENT_FOR_HIGHER_STUDIES':
                    // switch ( state?.data?.profile?.pursuingEducationLevelCode ) {
                    switch (programLevel) {
                        case '12':
                            redirectUrl = `/alumni/${user_id}/ug`;
                            break;
                        case 'UG':
                        case 'PG':
                        case 'Doctoral':
                            redirectUrl = `/alumni/${user_id}/pg`;
                            break;
                        default:
                            let schoolArr = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'];
                            if (schoolArr.includes(programLevel)) {
                                redirectUrl = `/alumni/${user_id}/student-school`;
                            }
                            break;
                    }
                    break;

                case 'TOOK_BREAK':
                case 'TAKE_BREAK':
                    redirectUrl = `/alumni/${user_id}/student-took-a-break`;
                    break;

                case 'JOB_HUNTING':
                    redirectUrl = `/alumni/${user_id}/job-preferences`;
                    break;

                case 'HIGHER_STUDIES':
                    switch (state?.data?.profile?.pursuingEducationLevelCode) {
                        case '12':
                            redirectUrl = `/alumni/${user_id}/ug-education-preference`;
                            break;
                        case 'UG':
                        case 'PG':
                            redirectUrl = `/alumni/${user_id}/pg-education-preference`;
                            break;
                        default:
                            if (parseInt(programLevel) >= 1 && parseInt(programLevel) < 12) {
                                redirectUrl = `/alumni/${user_id}/student-preference`;
                            }
                    }
                    break;

                case 'COMPETITIVE_EXAM_PREPARATION':
                    redirectUrl = `/alumni/${user_id}/entrance-exam`;
                    break;
            }

            state.actions['REDIRECT'](redirectUrl);
        }
    });
};

export const isFormValidBasedOnCustomValidation = async (state: State, dispatch: any) => {
    let isFormValid = true;
    let postProgramCompletionStatusCode = state?.data?.selectedInstitute?.postProgramCompletionStatusCode;
    setError(`selectedInstitute.postProgramCompletionStatusCode`, [], dispatch);
    if (isEmpty(postProgramCompletionStatusCode)) {
        isFormValid = false;
        setError('selectedInstitute.postProgramCompletionStatusCode', [`Please enter "Post Completion of the program"`], dispatch);
    }
    return isFormValid;
};
// const getErrorsToIgnore = (state: State) => {
//     if (state.data.profile.passOutYear > new Date().getFullYear() - 2 && state.data.profile.didYouTakeGapAfter12 === true)
//         return ['profile.educationOrEmploymentCurrentStatusCode'];
// };

const sendMoEngageEvent = async (user:any) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_MOENGAGE_API}/moengage/moengage-program-info`, {
            userInfo       : user
        });
        // console.log('Response:', response.data);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
    return false;
  };