import { activateUserSession, isEmpty, isFormValid, isValidBusinessObject, setError } from '../../../../library/Core/SmartFunctions';
import { DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import { axiosClient } from '../../../../library/Service/axiosClient';
import StudentService from '../../../../services/student.service';

export const handleSubmit = async (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();

    const formValidationPassed = isFormValid(state, dispatch, ['entranceTestForApplied']);
    const customValidationPassed = await isFormValidBasedOnCustomValidation(state, dispatch);

    if (!customValidationPassed) {
        dispatch({ type: 'SHOW_ERRORS' });
        return;
    }

    StudentService.pageSave(
        state.routeInfo.id,
        state.routeInfo.pageName,
        StudentService.toStudentEntity({
            id: state.routeInfo.id,
            entranceTests: state?.data['entranceTests'],
            // entranceTestForJoined: state?.data['entranceTestForJoined'],
            // entranceTestForApplied:
            //     isEmpty(state?.data['entranceTestForApplied']) && isEmpty(state?.data['entranceTestForJoined'])
            //         ? state?.data['entranceTests']
            //         : state?.data['entranceTestForApplied'],
        })
    ).then((response) => {
        if (response?.status === 200) {
            // Call the API here to update the status as ACTIVE & COMPLETE.
            activateUserSession(sessionState, sessionDispatch).then(() => {
                state.actions['REDIRECT'](`/alumni/${state.routeInfo.id}/profile/entrance-exams`);
            });
        }
    });
};

export const isFormValidBasedOnCustomValidation = async (state: State, dispatch: any) => {
    let isFormValid = true;
    const entranceSet = new Set();

    // Clear specific keys related to entrance tests
    const keysToClear = Object.keys(state['formValidationErrors']).filter((key) => key.startsWith('entranceTests'));

    for (const key of keysToClear) {
        await setError(key, [], dispatch);
    }

    setError(`entranceTests`, [], dispatch);
    let hasSelection = false;

    const validationPromises = state?.data.entranceTests.map(async (row: any, index: number) => {
        setError(`entranceTests.${index}.entranceTestCode`, [], dispatch);

        const key = row.entranceTestCode || row.otherExam;

        if (entranceSet.has(key)) {
            isFormValid = false;

            setError(`entranceTests.${index}.entranceTestCode`, [`Please remove duplicate exam`], dispatch);
        }

        if (!isEmpty(key) && isEmpty(row?.preparationStatusCode)) {
            isFormValid = false;

            setError(`entranceTests.${index}.entranceTestCode`, [`Please select status for this exam`], dispatch);
        }
        if (!isEmpty(key)) {
            hasSelection = true;
            entranceSet.add(key);
        }
        // if (!hasSelection) {
        //     isFormValid = false;
        //     setError('entranceTestForApplied', [`At least one record is required`], dispatch);
        // }
    });

    if (!hasSelection) {
        isFormValid = false;
        // Add an error message indicating that at least one record should be selected
        setError('entranceTests', [`At least one record is required`], dispatch);
    }

    await Promise.all(validationPromises);

    // if (entranceSet.size === 0) {
    //     setError(`entranceTestForApplied`, [`Please enter exam`], dispatch);
    //     isFormValid = false;
    // }

    return isFormValid;
};
